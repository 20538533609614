.conditionBasedTestroot{
    overflow-x: hidden;
}

.conditionBasedTest{
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
}

.conditionBasedTestcontainerparent{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.conditionBasedTestcontainer{
    border: 2px solid #039147;
    background-color: #FFFFFF;
    display: grid;
    box-shadow: 4px 4px 4px 0px #00914747;
    width: 335px;
    border-radius: 6px;
    height: 380px;
    align-items: center;
}

.conditionBasedTestimg{
    object-fit: cover;
    width: 20vw;
    height: 205px;
    border-radius: 6px;
    border: 2px solid #039147;
}

.conditionBasedTestitem{
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 600;
}

.conditionBasedTestitem1{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    background-color: #F2F4EA;
    border-radius: 100px;
    display: grid;
    place-items: center;
    padding: 2px;
}

.conditionBasedTestitem2{
    display: flex;
    justify-content: space-around;
}

.conditionBasedTestbutton{
    border: 1px solid #039147;
    border-radius: 2px;
    padding: 5px 30px;
    background-color: white;
    color: #039147;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.conditionBasedTestbutton:hover{
    background-color: #039147;
    color: white;
}

.conditionBasedTestcontainer2{
    display: grid;
    place-items: center;
}

.conditionBasedTestcontainer2button{
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 600;
    background: #039147;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 150px;
    cursor: pointer;
}

.conditionBasedTestcontainer2button:hover{
    background-color: white;
    color: #039147;
    border: 2px solid #039147;
}