.mobilenavbarroot{
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 999;
    background-color: #f3f5ea;
}

.mobilenavbarcontainer1{
    width: 100%;
    height: 100%;
    background: #039147;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
}

.mobilenavbaritem1{
    color: white;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
}

.mobilenavbarimg{
    width: 16px;
    height: 17px;
}

.mobilenavbaritem1 a{
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

.mobilenavbarcontainer2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
}

.mobilesection2img{
    width: 48px;
    height: 48px;
}

.mobilesection2item1{
    color: #039147;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
}

.mobilesection2button2{
    padding: 7% 30px;
    background: #039147;
    border-radius: 100px;
    color: white;
    font-family: Poppins;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.mobilesection2item2{
    display: flex;
    align-items: center;
}

.mobilesection2container3{
    display: flex;
    justify-content: center;
}

.mobilesection2item3{
    width: 90%;
    height: 100%;
    padding: 2px 10px 2px 10px;
    background: white;
    border-radius: 6px;
    border: 2px solid #039147;
    justify-content: center;
    align-items: center;
    display: flex;
}

.mobilesection2button1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.mobilesection2input {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
}

.mobilesection2input::placeholder{
    color: #7E7E7E;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
}

.mobilesection2img1{
    width: 18px;
    height: 18px;
}

.mobilesection2img2{
    width: 16.33px;
    height: 22.17px;
}

@media screen and (max-width: 300px) {
    .mobilenavbarcontainer1{
        width: 100%;
        height: 100%;
        background: #039147;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;
        gap: 10px;
    }
}