@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");

.gridform1 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 60px;
  align-items: center;
}
.gridform1 .formcard {
  width: 380px;
  height: 535px;
  border-radius: 8px;
  padding: 10px 35px 10px 35px;
  overflow: auto;
}
.error {
  font-size: 12px;
}
.formheadings {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formheadings .headingdemo {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  font-family: Poppins;
}
.forminput1 .inputplaceholders {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /* color: #7E7E7E; */
  font-family: Poppins;
}
@media (max-width: 1200px) {
  .gridform1 .formcard {
    width: 320px;
    height: 542px;
    border-radius: 8px;
    padding: 0px 25px 10px 25px;
    overflow: auto;
  }
}
