@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');


:root {
    --switches-bg-color: #D9D9D9;
    --switches-label-color: #000000;
    --switch-bg-color: #039147;
    --switch-text-color: #FFFFFF;
}

.wellnessdiv {
    height: auto;
    width: auto;
    background-color: #f2f4ea;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.containerwellness {
    border: 1px solid #f2f4ea;
    height: 1724px;
    width: 1245px;
    background-color: #f2f4ea;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.switchcontainermain {
    width: 1187px;
    height: 1025px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    margin: 80px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.switchcontainer1 {
    width: 379px;
    height: 1025px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid #039147;
    padding-top: 20px;
}

.switchcontainer1 .starter-container {
    width: 379px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.switchcontainer1 .starter-containerpara {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer1 .starter-containerpara2 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer1 .starter-containerpara22 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer1 .starter-containerpara3 {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: auto;

}

.switchcontainer1 .starter-containerpara3h {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: -10px;
    justify-content: flex-start;
    margin-left: auto;

}

.switchcontainer1 .starter-containerpara3btn {
    width: 379px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.wellnessswitchbtn{
    font-size: 20px;
    font-weight: 500;
    line-height: 50px;
    font-family: Poppins;
}
.wellnessdiv .getstaartedbtn {
    width: 286px;
    height: 37px;
    background-color: #FFFFFF;
    color: #039147;
    border-radius: 4px;
    border: 1px solid #009147;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    padding: 5px 25px;
    text-transform: none;
    font-family: Poppins;
}

.starter-heading1 {
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
    font-family: Poppins;
}

.switchpageline {
    width: 337px;
    height: 0px;
    border-top: 1px solid #009147;
    margin: 10px 0;
}

.starter-prise1 {
    font-size: 52px;
    font-weight: 600;
    line-height: 29px;
    font-family: Poppins;
}

.starter-prise1span {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
}

.switchcontainer2 {
    width: 379px;
    height: 1025px;
    border-radius: 8px;
    color: #FFFFFF;
    background-color: #BA90FF;
    padding-top: 20px;
}

.switchcontainer2 .starter-container {
    width: 379px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.switchcontainer2 .starter-containerpara {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer2 .starter-containerpara2 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer2 .starter-containerpara22 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer2 .starter-containerpara3 {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: auto;
}

.switchcontainer2 .starter-containerpara3h {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: -10px;
    justify-content: flex-start;
    margin-left: auto;

}

.switchcontainer2 .starter-containerpara3btn {
    width: 379px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}

.switchcontainer2 .switchplanpara2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #ffffff;
}

.switchcontainer2 .switchplanpara3span {
    font-size: 15px;
    font-weight: 700;
    line-height: 29px;
    font-family: Poppins;
    color: #FFFFFF;
}

.switchcontainer2 .planpara3span {

    font-size: 20px;
    line-height: 29px;
    font-family: Poppins;
    color: #FFFFFF;
}

.switchcontainer2 .switchplanpara {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #5700C8;
}

.switchcontainer3 {
    width: 379px;
    height: 1025px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid #039147;
    padding-top: 20px;
}

.switchcontainer3 .starter-container {
    width: 379px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.switchcontainer3 .starter-containerpara {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer3 .starter-containerpara2 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer3 .starter-containerpara22 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.switchcontainer3 .starter-containerpara3 {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: auto;

}

.switchcontainer3 .starter-containerpara3h {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: -10px;
    justify-content: flex-start;
    margin-left: auto;

}

.switchcontainer3 .starter-containerpara3btn {
    width: 379px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.wellnessdiv .switchcontainer2 .getstaartedbtn {
    width: 286px;
    height: 37px;
    background-color: #BA90FF;
    color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    padding: 5px 25px;
    text-transform: none;
    font-family: Poppins;
  
}
.wellnessdiv .switchcontainer2 .getstaartedbtn:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.wellnessdiv .switchcontainer1 .getstaartedbtn:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.wellnessdiv .switchcontainer3 .getstaartedbtn:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}

.switchplanheading1 {
    font-size: 64px;
    font-weight: 500;
    line-height: 64px;
    font-family: Montserrat;
}

.switchplanheading2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    font-family: Montserrat;
}

.switchplanpara {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #7E7E7E;
}

.switchplanpara1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #7E7E7E;
}

.switchplanpara2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.switchplanpara3 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    /* color: #000000; */
}

.switchplanpara3span {
    font-size: 15px;
    font-weight: 700;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.starter-containerpara3 .switchplanpara3span1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.planpara3span {

    font-size: 20px;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.heading-container {
    width: 525px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;
}

.heading-containerpara {
    width: 895px;
    height: 63px;
    margin-top: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.heading-containerlogo {
    width: 655px;
    height: 80px;
    margin-top: 44px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.heading-containerlogo .corporateslogoimgcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}

.corporateslogoimg {
    width: 80px;
    height: 80px;
}

.switches-containerwellness {
    width: 325px;
    height: 53px;
    margin-top: 54px;
    position: relative;
    display: flex;
    padding: 0px 0px 0px 0px;
    background: var(--switches-bg-color);
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.switches-containerwellness input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.switches-containerwellness label {
    width: 50%;
    padding: 0px 0px 0px 0px;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: var(--switches-label-color);
}


.switch-wrapperwellness {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);

}


.switchwellnessplan {
    border-radius: 3rem;
    background: var(--switch-bg-color);
    height: 100%;
}

.switchwellnessplan div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color);
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}


.switches-containerwellness input:nth-of-type(1):checked~.switch-wrapperwellness {
    transform: translateX(0%);
}


.switches-containerwellness input:nth-of-type(2):checked~.switch-wrapperwellness {
    transform: translateX(100%);
}

.switches-containerwellness input:nth-of-type(1):checked~.switch-wrapperwellness .switchwellnessplan div:nth-of-type(1) {
    opacity: 1;
}

.switches-containerwellness input:nth-of-type(2):checked~.switch-wrapperwellness .switchwellnessplan div:nth-of-type(2) {
    opacity: 1;
}


@media (max-width: 1250px) {
    .containerwellness {
        border: 1px solid #f2f4ea;
        height: 1600px;
        width: 100vw;
        background-color: #f2f4ea;
        padding: 0px;
        margin-left: 1%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .switchcontainermain {
        width: 97vw;
        height: 1125px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 25px;
        justify-content: flex-start;
        margin: 80px 0px 0px 0px;
    }

    .heading-containerpara {
        width: 95%;
        height: 63px;
        margin-top: 54px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        word-wrap: break-word;
        justify-content: center;
        text-align: center;
    }

    .heading-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .heading-containerlogo {
        width: 655px;
        height: 80px;
        margin-top: 44px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 700px) {
    .containerwellness {
        border: 1px solid #f2f4ea;
        height: 1510px;
        width: 100vw;
        background-color: #f2f4ea;
        padding: 0px;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .heading-containerpara {
        width: 70%;
        height: 63px;
        margin-top: 0px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        word-wrap: break-word;
        justify-content: center;
        text-align: center;
    }
    .switches-containerwellness {
        width: 256px;
        height: 53px;
        margin-top: 54px;
        position: relative;
        display: flex;
        padding: 0px 0px 0px 0px;
        background: var(--switches-bg-color);
        line-height: 3rem;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
    .switchcontainer1 {
        width: 305px;
        height: 1025px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 2px solid #039147;
    
    }
    .switchcontainer1 .starter-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: auto;
        margin-right: auto;
    
    }
    .starter-heading1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        font-family: Poppins;
    }
    .switchcontainer1 .starter-containerpara {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .switchcontainer1 .starter-containerpara2 {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchpageline {
        width: 90%;
        height: 0px;
        border-top: 1px solid #009147;
        margin: 10px 0;
    }
    .switchcontainer1 .starter-containerpara3h {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: -10px;
        justify-content: flex-start;
        margin-left: 10%;
    
    }
    .switchcontainer1 .starter-containerpara3 {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        margin-left: 10%;
    }
    .switchcontainer1 .starter-containerpara22 {
        width: 309px;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchcontainer1 .starter-containerpara3btn {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 50px;
        justify-content: center;
    }
    
    .wellnessdiv .getstaartedbtn {
        width: 213px;
        height: 37px;
        background-color: #FFFFFF;
        color: #039147;
        border-radius: 4px;
        border: 1px solid #009147;
        font-size: 12px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }
    .wellnessdiv .switchcontainer2 .getstaartedbtn {
        width: 213px;
        height: 37px;
        background-color: #BA90FF;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }



    .switchcontainer2 {
        width: 305px;
        height: 1025px;
        border-radius: 8px;
        color: #FFFFFF;
        background-color: #BA90FF;
    
    }

    .switchcontainer2 .starter-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: auto;
        margin-right: auto;
    
    }
    .starter-heading1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        font-family: Poppins;
    }
    .switchcontainer2 .starter-containerpara {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchcontainer2 .starter-containerpara2 {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchpageline {
        width: 90%;
        height: 0px;
        border-top: 1px solid #009147;
        margin: 10px 0;
    }
    .switchcontainer2 .starter-containerpara3h {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: -10px;
        justify-content: flex-start;
        margin-left: 10%;
    
    }
    .switchcontainer2 .starter-containerpara3 {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        margin-left: 10%;
    }
    .switches-containerwellness {
        width: 290px;
        height: 53px;
        margin-top: 54px;
        position: relative;
        display: flex;
        padding: 0px 0px 0px 0px;
        background: var(--switches-bg-color);
        line-height: 3rem;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
    .switchcontainer2 .starter-containerpara22 {
        width: 309px;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchcontainer2 .starter-containerpara3btn {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 50px;
        justify-content: center;
    }



    .switchcontainer3 {
        width: 305px;
        height: 1025px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 2px solid #039147;
    
    }
    .switchcontainer3 .starter-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: auto;
        margin-right: auto;
    
    }
   
    .switchcontainer3 .starter-containerpara {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchcontainer3 .starter-containerpara22 {
        width: 309px;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchcontainer3 .starter-containerpara2 {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .switchpageline {
        width: 90%;
        height: 0px;
        border-top: 1px solid #009147;
        margin: 10px 0;
    }
    .switchcontainer3 .starter-containerpara3h {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: -10px;
        justify-content: flex-start;
        margin-left: 10%;
    
    }
    .switchcontainer3 .starter-containerpara3 {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        margin-left: 10%;
    }
    .switchcontainer3 .starter-containerpara3btn {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 50px;
        justify-content: center;
    }
    
    .switchplanheading1 {
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 40px;
        font-family: Montserrat;
    }

    .switchplanheading2 {
        font-size: 32px;
        margin-top: 10px;
        font-weight: 700;
        line-height: 32px;
        font-family: Montserrat;
    }

    .heading-containerlogo {
        width: 100%;
        height: 80px;
        margin-top: 14px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        overflow-x: hidden;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .switchplanpara1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        font-family: Poppins;
        color: #7E7E7E;
    }

    .corporateslogoimg {
        width: 66px;
        height: 65px;
    }

    .heading-containerlogo .corporateslogoimgcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
    }

}

@media (max-width: 300px) {
    .heading-containerlogo {
        width: 100%;
        height: 80px;
        margin-top: 24px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        overflow-x: hidden;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

}