
@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');

:root {
    --switches-bg-color1: #D9D9D9;
    --switches-label-color1: #000000;
    --switch-bg-color1: #BCD63A;
    --switch-bg-colorg: #009147;
    --switch-text-color1: #FFFFFF;
}

.providerwellnessdiv {
    height: auto;
    width: auto;
    background-color: #f2f4ea;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.providercontainerwellness {
    border: 1px solid #f2f4ea;
    /* height: 1724px; */
    width: 1245px;
    background-color: #f2f4ea;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.providerswitchcontainermain {
    width: 1187px;
    height: 1025px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: center;
    margin: 80px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;
}


.providerswitchcontainer1 {
    width: 379px;
    height: 1025px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid #BCD63A;
    padding-top: 20px;
}

.providerswitchcontainer1 .providerstarter-container {
    width: 379px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.providerswitchcontainer1 .providerstarter-containerpara {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer1 .providerstarter-containerpara2 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer1 .providerstarter-containerpara22 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer1 .providerstarter-containerpara3 {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: auto;

}

.providerswitchcontainer1 .providerstarter-containerpara3h {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: -10px;
    justify-content: flex-start;
    margin-left: auto;

}

.providerswitchcontainer1 .providerstarter-containerpara3btn {
    width: 379px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.providerwellnessswitchbtn{
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
    font-family: Poppins;
}
.providerwellnessdiv .providergetstaartedbtn {
    width: 286px;
    height: 37px;
    background-color: #FFFFFF;
    color: #BCD63A;
    border-radius: 4px;
    border: 1px solid #009147;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    padding: 5px 25px;
    text-transform: none;
    font-family: Poppins;
}

.providerstarter-heading1 {
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
    font-family: Poppins;
}

.providerswitchpageline {
    width: 337px;
    height: 0px;
    border-top: 1px solid  #009147;
    margin: 10px 0;
}

.providerstarter-prise1 {
    font-size: 52px;
    font-weight: 600;
    line-height: 29px;
    font-family: Poppins;
}

.providerstarter-prise1span {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
}

.providerswitchcontainer2 {
    width: 379px;
    height: 1025px;
    border-radius: 8px;
    color: #FFFFFF;
    background-color: #BCD63A;
    padding-top: 20px;
}

.providerswitchcontainer2 .providerstarter-container {
    width: 379px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;

}


.providerswitchcontainer2 .providerstarter-containerpara {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer2 .providerstarter-containerpara2 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer2 .providerstarter-containerpara22 {
    width: 379px;
    height: auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.providerswitchcontainer2 .providerstarter-containerpara3 {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: auto;
}

.providerswitchcontainer2 .providerstarter-containerpara3h {
    width: 325px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: -10px;
    justify-content: flex-start;
    margin-left: auto;

}

.providerswitchcontainer2 .providerstarter-containerpara3btn {
    width: 379px;
    height: 29px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}

.providerswitchcontainer2 .providerswitchplanpara2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #ffffff;
}

.providerswitchcontainer2 .providerswitchplanpara3span {
    font-size: 15px;
    font-weight: 700;
    line-height: 29px;
    font-family: Poppins;
    color: #FFFFFF;
}

.providerswitchcontainer2 .providerplanpara3span {

    font-size: 20px;
    line-height: 29px;
    font-family: Poppins;
    color: #FFFFFF;
}

.providerswitchcontainer2 .providerswitchplanpara {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}










.providerwellnessdiv .providerswitchcontainer2 .providergetstaartedbtn {
    width: 286px;
    height: 37px;
    background-color: #BCD63A;
    color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    padding: 5px 25px;
    text-transform: none;
    font-family: Poppins;
  
}
.providerwellnessdiv .providerswitchcontainer2 .providergetstaartedbtn:hover{
    color: white;
    border: 1px solid #BCD63A;
    background: #BCD63A;
}
.providerwellnessdiv .providerswitchcontainer1 .providergetstaartedbtn:hover{
    color: white;
    border: 1px solid #BCD63A;
    background: #BCD63A;
}


.providerswitchplanheading1 {
    font-size: 64px;
    font-weight: 500;
    line-height: 64px;
    font-family: Montserrat;
}

.providerswitchplanheading2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    font-family: Montserrat;
}

.providerswitchplanpara {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #7E7E7E;
}

.providerswitchplanpara1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #7E7E7E;
}

.providerswitchplanpara2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.providerswitchplanpara3 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    font-family: Poppins;
    /* color: #000000; */
}

.providerswitchplanpara3span {
    font-size: 15px;
    font-weight: 700;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.providerstarter-containerpara3 .providerswitchplanpara3span1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.providerplanpara3span {

    font-size: 20px;
    line-height: 29px;
    font-family: Poppins;
    color: #000000;
}

.providerheading-container {
    width: 325px;
    height: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    
    margin: 0px 0px 0px 0px;
    margin-left: auto;
    margin-right: auto;
}

.providerheading-containerpara {
    width: 695px;
    height: 63px;
    margin-top: 64px;
    position: relative;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.providerheading-containerlogo {
  display: none;
}

.providerheading-containerlogo .providercorporateslogoimgcontainer {
 display: none;
}

.providercorporateslogoimg {
    width: 80px;
    height: 80px;
}

.providerswitches-containerwellness {
    width: 325px;
    height: 53px;
    margin-top: 54px;
    position: relative;
    display: flex;
    padding: 0px 0px 0px 0px;
    background: var(--switches-bg-color1);
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.providerswitches-containerwellness input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.providerswitches-containerwellness label {
    width: 50%;
    padding: 0px 0px 0px 0px;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: var(--switches-label-color1);
}


.providerswitch-wrapperwellness {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    padding: 0.provider15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);

}


.providerswitchwellnessplan {
    border-radius: 3rem;
    background: var(--switch-bg-color1);
    height: 100%;
}

.providerswitchwellnessplan div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: #FFFFFF;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}


.providerswitches-containerwellness input:nth-of-type(1):checked~.providerswitch-wrapperwellness {
    transform: translateX(0%);
}


.providerswitches-containerwellness input:nth-of-type(2):checked~.providerswitch-wrapperwellness {
    transform: translateX(100%);
}

.providerswitches-containerwellness input:nth-of-type(1):checked~.providerswitch-wrapperwellness .providerswitchwellnessplan div:nth-of-type(1) {
    opacity: 1;
}

.providerswitches-containerwellness input:nth-of-type(2):checked~.providerswitch-wrapperwellness .providerswitchwellnessplan div:nth-of-type(2) {
    opacity: 1;
}


@media (max-width: 1250px) {
    .providercontainerwellness {
        border: 1px solid #f2f4ea;
        height: 1600px;
        width: 100vw;
        background-color: #f2f4ea;
        padding: 0px;
        margin-left: 1%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .providerswitchcontainermain {
        width: 97vw;
        height: 1125px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 25px;
        justify-content: flex-start;
        margin: 80px 0px 0px 0px;
    }

    .providerheading-containerpara {
        width: 95%;
        height: 63px;
        margin-top: 54px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        word-wrap: break-word;
        justify-content: center;
        text-align: center;
    }

    .providerheading-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .providerheading-containerlogo {
        width: 655px;
        height: 80px;
        margin-top: 44px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 700px) {
    .providerswitchwellnessplan {
        border-radius: 3rem;
        background: var(--switch-bg-colorg);
        height: 100%;
    }
    .providerswitches-containerwellness {
        width: 325px;
        height: 53px;
        margin-top: 54px;
        position: relative;
        display: flex;
        padding: 0px 0px 0px 0px;
        background: #039147;
        line-height: 3rem;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
    .providerwellnessdiv .providerswitchcontainer2 .providergetstaartedbtn {
        width: 286px;
        height: 37px;
        background-color: #039147;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
      
    }
    .providerwellnessdiv .providerswitchcontainer2 .providergetstaartedbtn:hover{
        color: white;
        border: 1px solid #039147;
        background: #039147;
    }
    .providerwellnessdiv .providerswitchcontainer1 .providergetstaartedbtn:hover{
        color: white;
        border: 1px solid #039147;
        background: #039147;
    }
  
    .providercontainerwellness {
        border: 1px solid #f2f4ea;
        height: 1510px;
        width: 100vw;
        background-color: #f2f4ea;
        padding: 0px;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .providerheading-containerpara {
        width: 70%;
        height: 63px;
        margin-top: 0px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        word-wrap: break-word;
        justify-content: center;
        text-align: center;
    }
    .providerswitches-containerwellness {
        width: 256px;
        height: 53px;
        margin-top: 54px;
        position: relative;
        display: flex;
        padding: 0px 0px 0px 0px;
        background: var(--switches-bg-color1);
        line-height: 3rem;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
    .providerswitchcontainer1 {
        width: 305px;
        height: 1025px;
        border-radius: 8px;
        background-color: #FFFFFF;
        border: 2px solid #039147;
    
    }
    .providerswitchcontainer1 .providerstarter-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerstarter-heading1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        font-family: Poppins;
    }
    .providerswitchcontainer1 .providerstarter-containerpara {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .providerswitchcontainer1 .providerstarter-containerpara2 {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerswitchpageline {
        width: 90%;
        height: 0px;
        border-top: 1px solid #009147;
        margin: 10px 0;
    }
    .providerswitchcontainer1 .providerstarter-containerpara3h {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: -10px;
        justify-content: flex-start;
        margin-left: 10%;
    
    }
    .providerswitchcontainer1 .providerstarter-containerpara3 {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        margin-left: 10%;
    }
    .providerswitchcontainer1 .providerstarter-containerpara22 {
        width: 309px;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerswitchcontainer1 .providerstarter-containerpara3btn {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 50px;
        justify-content: center;
    }
    
    .providerwellnessdiv .providergetstaartedbtn {
        width: 213px;
        height: 37px;
        background-color: #FFFFFF;
        color: #039147;
        border-radius: 4px;
        border: 1px solid #009147;
        font-size: 12px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }
    .providerwellnessdiv .providerswitchcontainer2 .providergetstaartedbtn {
        width: 213px;
        height: 37px;
        background-color: #BCD63A;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }



    .providerswitchcontainer2 {
        width: 305px;
        height: 1025px;
        border-radius: 8px;
        color: #FFFFFF;
        background-color: #BCD63A;
    
    }

    .providerswitchcontainer2 .providerstarter-container {
        width: 100%;
        height: 64px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerstarter-heading1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        font-family: Poppins;
    }
    .providerswitchcontainer2 .providerstarter-containerpara {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerswitchcontainer2 .providerstarter-containerpara2 {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }

    .providerswitchcontainer2 .providerstarter-containerpara3h {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: -10px;
        justify-content: flex-start;
        margin-left: 10%;
    
    }
    .providerswitchcontainer2 .providerstarter-containerpara3 {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        margin-left: 10%;
    }
    .providerswitches-containerwellness {
        width: 290px;
        height: 53px;
        margin-top: 54px;
        position: relative;
        display: flex;
        padding: 0px 0px 0px 0px;
        background: var(--switches-bg-color1);
        line-height: 3rem;
        border-radius: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
    .providerswitchcontainer2 .providerstarter-containerpara22 {
        width: 309px;
        height: auto;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    
    }
    .providerswitchcontainer2 .providerstarter-containerpara3btn {
        width: 100%;
        height: 29px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        margin-top: 50px;
        justify-content: center;
    }



   
 
    .providerswitchpageline {
        width: 90%;
        height: 0px;
        border-top: 1px solid #009147;
        margin: 10px 0;
    }


  
    
    .providerswitchplanheading1 {
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 40px;
        font-family: Montserrat;
    }

    .providerswitchplanheading2 {
        font-size: 32px;
        margin-top: 10px;
        font-weight: 700;
        line-height: 32px;
        font-family: Montserrat;
    }

    .providerheading-containerlogo {
        width: 100%;
        height: 80px;
        margin-top: 14px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        overflow-x: hidden;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .providerswitchplanpara1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        font-family: Poppins;
        color: #7E7E7E;
    }

    .providercorporateslogoimg {
        width: 66px;
        height: 65px;
    }

    .providerheading-containerlogo .providercorporateslogoimgcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
    }

}

@media (max-width: 300px) {
    .providerheading-containerlogo {
        width: 100%;
        height: 80px;
        margin-top: 24px;
        position: relative;
        padding: 0px 0px 0px 0px;
        display: flex;
        overflow-x: hidden;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

}