@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');



.medharwa-singup-lap-a {
    display:flex;
    justify-content:center;
}
.medharwa-singup-lap-b {
    width:518px;
    min-height:840px;
     background-color:#FFFFFF; 
     /* border: 2px solid #039147; */
}


.label-registraion {
    width: 84%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 8%;
  
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
.input-text-area {
    /* width: 433px; */
    width: 84%;
    margin-left: 8%;
    padding-left: 20px;
    height: 67px;
   background-color: #D9D9D9;
   border: none;
   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
}
.singup-aply-btn {
    padding: 10px 30px 10px 30px;
    color: white;
    background-color: #3FC08A;
   margin-top: 10%;
    cursor: pointer;
    font-size: 24px;
    text-align: Center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 36px;
    border: none;
    border-radius: 8px;
}
.singup-aply-btn:hover {
    color: white;
    background-color: #039147;
}

.error-message {
    color: red;
   display: flex;
   justify-content: flex-start;
   margin-left: 38px;
}