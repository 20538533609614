@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');

.card-container {
  width: 100%;
  height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    padding: 30px 80px;
    border-radius: 8px;
    background-color: #f2f4ea;

   
  }
  .container{
    width: 90%;
  height: auto;
    border: 2px solid #039147;
    padding: 30px 30px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items:flex-start;
  }
  .page-heading {
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    line-height: 41px;
    font-weight: 600;
    margin: 0px;


  }
  
  .card {
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: 33px;
    /* border: 1px solid #039147; */
    border-radius: 6px;
    /* background-color: aqua; */

    background-color: white;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .image-container {
    height: 88px;
  
    width: 88px;
    display: flex;
    border-radius: 50%;
    background-color: #039147;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    max-width: 42px;
    max-height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .description {
    text-align: center;
    margin-top: 20px;
  
    font-family: Poppins;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
   
  }
  .cardimage{
    height: 41px;
    width: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  @media (max-width: 600px) {
    .card-container {
      width: 100%;
      height: auto;
      
        
        padding: 0px 0px;
        border-radius: 8px;
        background-color: #f2f4ea;
    
       
      }
    .container{
      width: 100vw;
    height: auto;
      /* border: 2px solid #039147; */
      padding: 10px 10px;
      border-radius: 8px;
      background-color: #FFFFFF;
    }
    .card-container {
      width: 100vw;
      margin: 0 ;
    }
    
    .card {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
     justify-content: center;
      /* gap: 20px; */
     
      border-radius: 6px;
     
  
      background-color: white;
    }
    
    .heading {
      text-align: center;
      margin-bottom: 10px;
    }
    
    .image-container {
      width: 69px;
      height: 69px;
    position: relative;

      justify-content: center;
      align-items: center;
    }
    
    .image {
      height: 33px;
      width: 34px;
      object-fit:cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .description {
      display: flex;
      justify-content: center;
      text-align:center;
      margin-top: 0px;
     margin-left: 20px;
     word-wrap: break-word;
    }
    .page-heading {
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      line-height: 24px;
      font-weight: 600;
      margin: 0px;
  
  
    }
   
  }
  