.uploadPrescriptionroot{
    overflow-x: hidden;
}

.uploadPrescriptiontitle{
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 500;
}

.uploadPrescriptioncontainer{
    background-color: white;
    padding: 40px 10px 10px 40px;
    border-radius: 6px;
}

.uploadPrescriptioncontainer2{
    display: grid;
}

.uploadPrescriptionitem1{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 900;
    color: #7E7E7E;
}

.uploadPrescriptionitem2{
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 600;
}

.uploadPrescriptionitem3{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 900;
}

.uploadPrescriptionitem4{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #039147;
    display: flex;
    justify-content: start;
    align-items: center;
}

.uploadPrescriptionitemtext{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.uploadPrescriptionitemimg{
    width: 22vw;
    height: 13vw;
}

.uploadcontainerparent{
    display: flex;
    justify-content: space-evenly;
}

.uploadcontainer{
    display: grid;
    place-items: center;
    color: #4E9FCC;
    background-color: white;
    border: 2px solid #4E9FCC;
    padding: 50px 180px 50px 180px;
    border-radius: 6px;
    cursor: pointer;
}

.uploadicon{
    transform: scale(2);
}

.uploadtext{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    white-space: nowrap;
}

.uploadtext2{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
}

.uploaditem{
    text-align: center;
}

.uploadtext3{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
}

.uploadtext4{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
}

.uploadtext5{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
}

.uploadcontainer2{
    display: grid;
    place-items: center;
    background-color: #4E9FCC;
    color: white;
    border: none;
    padding: 70px 160px 70px 160px;
    border-radius: 6px;
    cursor: pointer;
}

.uploadcontainer2img{
    width: 100px;
    height: 100px;
    filter: brightness(0) invert(1);
}

.uploadtext6{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
}

.navupload{
    display: flex;
    justify-content: center;
}

.truckimgarea{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 700px) {
    .uploadPrescriptioncontainer{
        border: 2px solid #039147;
    }

    .uploadtext6{
        font-size: 16px;
    }

    .uploadPrescriptionitemtext{
        font-size: 11px;
    }
    
    .uploadPrescriptionitemimg{
        width: 20vw;
        height: 15vw;
    }

    .uploadPrescriptiontitle{
        font-size: 26px;
        display: grid;
        place-items: center;
    }

    .uploadPrescriptionitem1{
        font-size: 14px;
    }
    
    .uploadPrescriptionitem2{
        font-size: 16px;
    }

    .uploadicon{
        transform: scale(1.5);
    }

    .uploadcontainer{
        padding: 50px 22vw 50px 22vw;
    }

    .uploadcontainer2{
        padding: 80px 27vw 80px 27vw;
    }

    .uploadcontainer2img{
        width: 70px;
        height: 70px;
    }

    .uploadtext{
        font-size: 24px;
    }

    .uploadtext3{
        font-size: 12px;
    }
    
    .uploadtext4{
        font-size: 12px;
    }
    
    .uploadtext5{
        font-size: 12px;
    }
}