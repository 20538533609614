.whyconsultonlinecontainer{
    display: grid;  
    place-items: center;
}

.whyconsultonlineitem{
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 600;
}

.whyconsultonlineimg{
    width: 75px;
    height: 75px;
    background: #039147;
    border-radius: 9999px;
    padding: 15px;
}

.whyconsultonlineitem2{
    display: flex;
    /* align-items: center; */
}

.whyconsultonlineheading{
    font-size: 42px;
    font-family: Poppins;
    font-weight: 600;
}

.whyconsultonlinetext{
    font-size: 18px;
    font-family: Poppins;
    font-weight: 300;
}