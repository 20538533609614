.spacebutton{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
 
.previous{
    padding: .3rem 8vw;
    background: white;
    border-radius: 100px;
    border: 2px #039147 solid;
    place-items: center;
    display: grid;
    color: #039147;
    text-transform: none;
    font-family: inherit;
    font-size: 22px;
    cursor: pointer;
}
 
.previous:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
 
.next{
    padding: .3rem 8.5vw;
    background: white;
    border-radius: 100px;
    border: 2px #039147 solid;
    place-items: center;
    display: grid;
    color: #039147;
    text-transform: none;
    font-family: inherit;
    font-size: 22px;
    cursor: pointer;
}
 
.next:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}