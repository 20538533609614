@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.pricingdetailscontainer{
    background: white;
    border-radius: 10px;
    width: 453px;
    height: 376px;
    display: grid;
}

.pricingdetailsitem{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    color: #7E7E7E;
}

.pricingdetailsitem2{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0px;
}

.pricingdetailsitem3{
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.pricingdetailsitem4{
    font-size: 15px;
    font-family: Poppins;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}