.demomaincontainer {
  width: 100vw;
  height: auto;
}

.demomaincontainer .demoimage1 {
  width: 100vw;
  height: 592px;
  background-image: url("../../findDoctor/assets/images/ezgif.com-loop-count\ \(1\).gif");
  background-size: 100dvw;
  object-fit: cover;
  display: flex;
  justify-content: flex-end;
  padding-right: 160px;
  z-index: 0;
  align-items: center;
  position: relative;
}
.slick-dots li.slick-active button:before {
  color: #039147;
  width: 12px;
  height: 12px;
}
.slick-dots li button:before {
  font-size: 1rem !important;
}
.demomaincontainer .demoimage4 {
  width: 100vw;
  height: 200px;
  /* background-image: url('../../assets/images/ezgif.com-loop-count\ \(1\).gif'); */
  background-size: cover;
  object-fit: contain;
  display: flex;

  z-index: 1;
  align-items: center;
  position: relative;
}
.demo-card-B::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #008e45, #008e45);
  z-index: 1;
  opacity: 0.1;
}
.demo-card-imge {
  /* max-width: 100vw; */
  width: 100vw;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
}

.demo-card-a {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  text-align: right;
  z-index: 2;
  margin-left: 0%;
  margin-bottom: 8%;
}

.demomaincontainer .schduleform {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 3;
  padding: 10%;
}
.demomaincontainer .schduleform1 {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
.demomaincontainer .besttreatmmenth2 {
  display: flex;
  justify-content: center;
  z-index: 1;
}

.demomaincontainer .besttreatment {
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins;
  color: #ffffff;
  margin: 0px 0px 0px 0px;
}
.demomaincontainer .scheduledemobtn {
  background-color: #039147;
  color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  padding: 5px 20px;
  text-transform: none;
  font-family: Poppins;
}

@media (min-width: 600px) {
  .demomaincontainer .besttreatment {
    font-size: 40px;
    font-weight: 500;
    font-family: Poppins;
    color: #ffffff;
    margin: 0px 0px 0px 0px;
  }
  .demomaincontainer .scheduledemobtn {
    background-color: #039147;
    color: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 5px 20px;
    text-transform: none;
    font-family: Poppins;
  }
}
@media (max-width: 1200px) {
  .demomaincontainer .schduleform1 {
    display: flex;
    justify-content: flex-end;

    z-index: 1;
  }
  .demomaincontainer .demoimage1 {
    width: 100vw;
    height: 592px;
    background-image: url("../../findDoctor/assets/images/ezgif.com-loop-count\ \(1\).gif");
    background-size: cover;
    object-fit: cover;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    z-index: 0;
    align-items: center;
    position: relative;
  }
}
