@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.rootmedharvaplusitem{
    display: grid;
    place-items: center;
    color: #000;
}

.medharvaplusitemtext {
    font-size: 55px;
    font-weight: 600;
    font-family: Montserrat;
}

.plus {
    background: linear-gradient(-90deg, #009147, #bdd63b);
    -webkit-background-clip: text;
    color: transparent;
}

.medharvaplusitemtext2 {
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins;
    text-align: center;
}

.dedicatedServicesSpecialContainer {
    font-size: 36px;
    font-weight: 500;
    font-family: Poppins;
}

.medharvaplusitem1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    background: white;
    border: 2px solid #039147;
    border-radius: 10px;
    padding: 50px 40px 50px 100px;
}

.dedicatedServicesitemlist{
    font-size: 36px;
    font-weight: 500;
    font-family: Poppins;
}

.plan1 {
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #039147;
    display: grid;
    place-items: center;
    padding: 28px 20px;
    text-align: center;
    font-size: 20px;
    font-family: Poppins;
}

.monthly {
    font-weight: 600;
    font-family: Poppins;
}

.rupeemonthly {
    font-size: 48px;
    font-weight: 500;
    font-family: Poppins;
}

.rupeeyearly{
    font-size: 40px;
    font-weight: 500;
    font-family: Poppins;
}

.flatOffContainer {
    font-size: 32px;
    font-weight: 500;
    font-family: Poppins;
}

.getStarted {
    border-radius: 4px;
    background-color: #039147;
    font-size: 15px;
    padding: 5px 40px;
    color: #fff;
    font-weight: 600;
    border: none;
    text-decoration: none;
    font-family: Poppins;
    margin-top: 10px;
    cursor: pointer;
}

.plan2 {
    border-radius: 8px;
    background-color: #039147;
    border: 2px solid #039147;
    display: grid;
    place-items: center;
    padding: 34px 20px;
    text-align: center;
    font-size: 20px;
    font-family: Poppins;
    color: white;
}

.getStarted2 {
    border-radius: 4px;
    background-color: #fff;
    font-size: 15px;
    padding: 5px 40px;
    color: #039147;
    font-weight: 600;
    border: none;
    text-decoration: none;
    font-family: Poppins;
    margin-top: 10px;
    cursor: pointer;
}

.getStarted:hover{
    color: #039147;
    background: white;
    border: 2px solid #039147;
}

.getStarted2:hover{
    color: white;
    background: #039147;
    border: 2px solid white;
}

.liststyleimg{
    display: none;
}

@media (max-width: 1250px) {
    .dedicatedServicesSpecialContainer {
        display: grid;
        justify-content: center;
    }

    .brstyle{
        display: none;
    }

    .medharvaplusitem1{
        justify-content: center;
        gap: 10px;
        padding: 50px 40px 50px 40px;
    }
}

@media (max-width: 600px) {
    .rootmedharvaplusitem{
        display: grid;
        place-items: center;
        color: #000;
        background: white;
    }

    .medharvaplusitemtext {
        font-size: 24px;
        font-weight: 600;
        font-family: Montserrat;
    }

    .medharvaplusitemtext2 {
        font-size: 12px;
        font-weight: 600;
        font-family: Poppins;
        text-align: center;
    }

    .medharvaplusitem1{
        border: none;
        justify-content: center;
        gap: 10px;
        padding: 50px 0px 50px 0px;
    }

    .dedicatedServicesSpecialContainer {
        font-size: 16px;
        font-weight: 500;
        font-family: Poppins;
        display: grid;
        justify-content: center;
    }
    
    .liststyleimg{
        display: inline;
        margin-right: 10px;
    }

    .dedicatedServicesitem{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        font-family: Poppins;
    }

    .plan1 {
        border-radius: 8px;
        background-color: #fff;
        border: 2px solid #039147;
        display: grid;
        place-items: center;
        padding: 10px ;
        text-align: center;
        font-size: 20px;
        font-family: Poppins;
    }

    .monthly {
        font-size: 14px;
        font-weight: 600;
    }

    .plan2 {
        border-radius: 8px;
        background-color: #039147;
        border: 2px solid #039147;
        display: grid;
        place-items: center;
        padding: 16px;
        text-align: center;
        font-size: 20px;
        font-family: Poppins;
        color: white;
    }

    .rupeemonthly {
        font-size: 40px;
        font-weight: 500;
    }
    
    .rupeeyearly{
        font-size: 32px;
        font-weight: 500;
    }

    .flatOffContainer {
        font-size: 16px;
        font-weight: 500;
    }
    
    .brstyle{
        display: none;
    }

    .getStarted2 {
        border-radius: 4px;
        background-color: #fff;
        font-size: 12px;
        padding: 5px 20px;
        color: #039147;
        font-weight: 600;
        border: none;
        text-decoration: none;
        font-family: Poppins;
        margin-top: 10px;
        cursor: pointer;
    }

    .getStarted {
        border-radius: 4px;
        background-color: #039147;
        font-size: 12px;
        padding: 5px 25px;
        color: #fff;
        font-weight: 600;
        border: none;
        text-decoration: none;
        font-family: Poppins;
        margin-top: 10px;
        cursor: pointer;
    }
}