
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.main-container{
    width: 100%;
    height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      
      padding: 30px 30px;
      border-radius: 8px;
      background-color: #f2f4ea;

}
.container2{
    flex-direction: column;
    align-items: center;
    /* width: 325px;
    height: 330px; */
    display: flex;
    /* padding-left: 10px;
    padding-right: 10px; */
    /* background-color: aqua; */

}
.mainheading{
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    line-height: 41px;
    font-weight: 600;
    margin: 0px;
    color: #039147;



}
.card2 {
    width: 93%;
    
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
gap:"10px";
    justify-content: center;
    padding: 10px;
    margin-top: 33px;
    border: 2px solid #039147;
    border-radius: 6px;
    margin: 20px 0;
    /* background-color: aqua; */

    background-color: white;
  }
  .image-container1 {
    height: 181px;
  /* object-fit: cover; */
    width: 236px;
    display: flex;
    margin-top: 20px;
    padding: 5px;
    /* background-color: #039147; */
    justify-content: center;
    align-items: center;
    text-align: right;
  }
  .cardimage1{
    height: 181px;
    /* object-fit: cover; */
      width: 236px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .imageour {
    height: 181px;
    object-fit: cover;
      width: 236px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardheading{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-family: Poppins;
    font-size: 28px;
    /* line-height: 30px; */
    font-weight: 600;
    text-align: left;
    margin: 0px;
    margin-top: 30px;
    
  }
  .description1 {
    margin: 0px;
   text-align: left;
 
    font-weight: 600;
    font-family: Poppins;
    font-size: 15px;
    /* line-height: 22px; */
color: #A0A0A0;
margin-bottom: 60px;
   
  }
  .namegrid{
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 900px) {
    .container2 {
        flex-direction: column;
        align-items: center;
    }

  .textmaingrid{
    display: flex;
    justify-content: flex-start;
  }
    .mainheading{
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        line-height: 19.5px;
        font-weight: 600;
        margin: 0px;
        color: #000000;
    
    }
    .card3 {
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    gap:"0px";
        justify-content: center;
        padding: 5px;
        margin-top: 0px;
        border: 2px solid #039147;
        border-radius: 8px;
        margin: 0px 0;
        /* background-color: aqua; */
    
        background-color: white;
      }
      .image-container1 {
        height: 108px;
      object-fit: cover;
        width: 90%;
        display: flex;
        margin-top: 10px;
        padding: 5px;
        border: 2px solid #039147;
        border-radius: 8px;
        /* background-color: #039147; */
        justify-content: center;
        align-items: center;
      }
      .cardimage1{
        height: 108px;
        /* object-fit: cover; */
          width: 193px;
        display: flex;
        justify-content: center;
        align-items: center;
        
      }
      .imageour {
        height: 108px;
        object-fit: cover;
          width: 193px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cardheading4{
    
        font-family: Poppins;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0px;
        margin-top: 10px;
        
      }
      .settext1{
        display: flex;
        justify-content: flex-start;
      }
      .description10{
        font-weight: 600;
        font-family: Poppins;
        font-size: 10px;
        line-height: 15px;
    color: #A0A0A0;
   
       
      }
}
@media (max-width: 1230px) {
   
    .card2 {
        width: 93%;
        
        height: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
    gap:"10px";
        justify-content: center;
        padding: 10px;
        margin-top: 33px;
        border: 2px solid #039147;
        border-radius: 6px;
        margin: 20px 0;
        /* background-color: aqua; */
    
        background-color: white;
      }
}

/*  */