.avialabledoctorroot{
    background: white;
    padding: 22px 0px 22px 25px;
    border: 2px solid #039147;
    border-radius: 8px;
    display: grid;
    justify-content: space-between;
    width: 400px;
    min-height: 400px;
    margin-bottom: 20px;
    overflow: hidden;
}

.avialabledoctorrootcontainer{
    justify-content: center;
    display: flex;
    text-align: start;
}

.avialabledoctorscontainer2{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.avialabledoctorgridcontainer{
    display: grid;
    /* align-items: center; */
    /* justify-content: space-around; */
}

.avialabledoctorgriditem{
    display: flex;
    align-items: start; 
}

.avialabledoctorgriditem4{
    display: flex;
    align-items: end; 
    justify-content: space-evenly;
    position: relative;
}

.avialabledoctorgriditem2{
    display: flex;
    align-items: start;   
}

.avialabledoctorgriditem3{
    display: flex;
    align-items: start;  
    flex-wrap: wrap; 
}

.avialabledoctorimagesize{
    width: 83px;
    height: 83px;
    border-radius: 100px;
}

.avialabledoctorname{
    font-size: 18px;
    font-weight: 500;
    font-family: Poppins;
}

.avialabledoctorabout{
    color: #7E7E7E;
    font-size: 15px;
    font-weight: 600;
    font-family: Poppins;
}

.avialabledoctorspecialization{
    padding: 5px 10px 5px 10px;
    background: #EEEEEE;
    border-radius: 100px;
    border: 1px #EEEEEE solid;
    display: grid;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    margin-right: 10px;
    white-space: nowrap;
    font-family: Poppins;
}

.avialabledoctorimagesize2{
    width: 17px;
    height: 20px;
}

.avialabledoctorgridcontainer2{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.avialabledoctorgridcontainer3{
    display: grid;
    align-items: end;
    justify-content: space-between;
    gap: 25px;
}

.avialabledoctortextdetail{
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
}

.avialabledoctorbutton1{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    color: #039147;
    border: 1px solid #039147;
    background: #FFFFFF;
    padding: 4px 30px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
}

.avialabledoctorbutton1:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.avialabledoctorbutton2{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    border: 1px solid #039147;
    background: #039147;
    padding: 4px 30px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
}

.avialabledoctorbutton2:hover{
    color: #039147;
    border: 1px solid #039147;
    background: #FFFFFF;
}

@media screen and (max-width: 454px) {
    .avialabledoctorroot{
        height: auto;
    }
}

@media screen and (max-width: 300px) {
    .avialabledoctorroot{
        height: auto;
    }

    .avialabledoctorgriditem{
        display: grid;
        place-items: center;
        text-align: center;
    }

    .avialabledoctorroot{
        padding: 22px 0px;
    }

    .avialabledoctorgriditem3{
        display: grid;
        place-items: center;
        text-align: center;
    }
}