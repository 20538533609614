@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");
.tech-provider-1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.tech-provider-2 {
  width: 1188px;
  height: 527px;
  /* border:2px solid red; */
}
.tech-provider-3 {
  width: 651px;
  height: 38px;
  color: #000000;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 38px;
  text-align: right;
  /* border:2px solid red; */
  margin-left: 43%;
  margin-top: 5%;
}
.tech-provider-4 {
  display: flex;
  /* flex-direction: row-reverse; */
  position: relative;
}

.tech-provider-5 {
  margin-top: -8%;
  margin-left: 2%;
}
.tech-provider-6 {
  width: 454px;
  height: auto;
}
.tech-provider-7 {
  width: 651px;
  height: 267px;
  font-family: Poppins;
  font-weight: 500px;
  line-height: 38px;
  font-size: 24px;
  color: #000000;
  text-align: right;
  /* border:2px solid red; */
  margin-left: 2.5%;
  margin-top: 2%;
}
.tech-provider-8 {
  font-size: 16px;
  color: #000000;
  font-family: Poppins;
  font-weight: 500px;
}
.tech-provider-9 {
  width: 229px;
  height: 57px;
  background-color: #d9d9d9;
  margin-left: 58%;
  margin-top: -2%;
}
.tech-provider-10 {
  cursor: pointer;
  width: 229px;
  height: 57px;
  color: #0e0e0e;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
  border: 2px solid #000000;
  border-radius: 8px;
}

@media screen and (max-width: 1180px) {
  .tech-provider-1 {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .tech-provider-2 {
    width: auto;
    height: auto;
  }
  .tech-provider-3 {
    margin-left: 17%;
    margin-top: 5%;
  }
  .tech-provider-5 {
    margin-top: -8%;
    margin-left: -5%;
  }
  .tech-provider-6 {
    width: 350px;
    height: auto;
  }
  .tech-provider-7 {
    width: 451px;
    height: 250px;
    font-family: Poppins;
    font-weight: 500px;
    line-height: 38px;
    font-size: 20px;
    color: #000000;
    text-align: right;
    /* border:2px solid red; */
    margin-left: 3%;
    margin-top: 2%;
  }
  .tech-provider-8 {
    font-size: 16px;
    color: #000000;
    font-family: Poppins;
    font-weight: 500px;
  }
  .tech-provider-9 {
    margin-left: 60%;
    margin-top: -2%;
  }
  .tech-provider-10 {
    cursor: pointer;
    width: 229px;
    height: 57px;
    color: #0e0e0e;
    font-family: Poppins;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: none;
    border: 2px solid #000000;
    border-radius: 8px;
  }
}
@media screen and (max-width: 840px) {
  .tech-provider-1 {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .tech-provider-2 {
    width: auto;
    height: auto;
  }
  .tech-provider-3 {
    width: 400px;
    height: 38px;
    font-size: 34px;
    text-align: right;
    margin-left: 43%;
    margin-top: 5%;
  }
  .tech-provider-5 {
    margin-top: -8%;
    margin-left: -1%;
  }
  .tech-provider-6 {
    width: 300px;
    height: auto;
  }
  .tech-provider-7 {
    width: 451px;
    height: 250px;
    font-family: Poppins;
    font-weight: 500px;
    line-height: 38px;
    font-size: 20px;
    color: #000000;
    text-align: right;
    /* border:2px solid red; */
    margin-left: -2%;
    margin-top: 2%;
  }
  .tech-provider-8 {
    font-size: 16px;
    color: #000000;
    font-family: Poppins;
    font-weight: 500px;
  }
  .tech-provider-9 {
    margin-left: 60%;
    margin-top: -2%;
  }
  .tech-provider-10 {
    cursor: pointer;
    width: 229px;
    height: 57px;
    color: #0e0e0e;
    font-family: Poppins;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: none;
    border: 2px solid #000000;
    border-radius: 8px;
  }
}
@media screen and (max-width: 750px) {
  .tech-provider-1 {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .tech-provider-2 {
    width: 305px;
    height: 762px;
    /* border: 2px solid red; */
  }
  .tech-provider-3 {
    width: 270px;
    height: 76px;
    color: #000000;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600px;
    line-height: 38px;
    text-align: center;
    /* border:2px solid red; */
    margin-left: 5%;
    margin-top: 5%;
  }
  .tech-provider-4 {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .tech-provider-5 {
    margin-top: 1%;
    margin-left: 10%;
  }
  .tech-provider-6 {
    width: 241px;
    height: 241px;
  }
  .tech-provider-7 {
    width: 305px;
    height: 224px;
    font-family: Poppins;
    font-weight: 500px;
    line-height: 24px;
    font-size: 16px;
    color: #000000;
    text-align: center;
    /* border:2px solid red; */
    margin-left: -90%;
    margin-top: 85%;
  }
  .tech-provider-8 {
    font-size: 16px;
    color: #000000;
    font-family: Poppins;
    font-weight: 500px;
  }
  .tech-provider-9 {
    width: 188px;
    height: 45px;

    background-color: #d9d9d9;
    margin-left: 18%;
    margin-top: 8%;
  }
  .tech-provider-10 {
    cursor: pointer;
    width: 188px;
    height: 45px;
    color: #0e0e0e;
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    border: 2px solid #000000;
    border-radius: 8px;
  }
}
