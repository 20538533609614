.medicine-card-A{
    position: relative;
     display: flex;
      justify-content: center;
      align-items: center;
       padding: 0 16px;
    
  }
  .medicine-card{
    height: auto;
    width: 100%;
    object-fit: cover;
    
      border-radius: 6px;
       overflow: hidden ;
  }
  .medicine-card-C{
    position: absolute;
    top: 40%;
    left: 5%;
     right: 60%;
     transform: translateY(-50%);
      color: black;
     
  }
  .medicine-card-B {
    max-width: 1352px;
    max-height: 592px;
    position: relative;
  }
  .medicine-card-B::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 200%;
    border-radius: 6px;
    background: linear-gradient(90.38deg, rgba(255, 255, 255, 0.9) 42.49%, rgba(0, 0, 0, 0) 112.38%);

  }
  .medicine-card-imge{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
   
  }
  .medicine-car-D{
    font-size: 48px;
     font-weight: 600;
      word-wrap: break-word;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      margin-left: 25%;

      /* font-family: Montserrat; */
  }
  .medicine-car-E{
    font-size: 48px;
     font-weight: 600;
     word-wrap: break-word;
     white-space: nowrap;
     display: flex;
     justify-content: flex-start;
     margin-left: 25%;

     /* font-family: Montserrat; */
  }
  @media screen and (max-width: 650px) {
    .medicine-car-D{
      font-size: 16px;
       font-weight: 500;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        /* font-family: Montserrat; */
       margin-right: 18%;
    }
    .medicine-car-E{
      font-size: 16px;
       font-weight: 600;
       word-wrap: break-word;
       white-space: nowrap;
       display: block;
       /* font-family: Montserrat; */
    }
  }



  .medicine-button-container {
    background: #039147;
    border-radius: 6px;
    position: absolute;
    top: 73%;
    left: 13%;
    right: 60%;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    display: inline-flex;
    text-align: center;
    color: white;
    white-space: nowrap;
    font-size: 32px;
    font-weight: 500;
    text-transform: none;
    border: none;
    cursor: pointer;
  }

  .consult-car-AX{
    font-size: 32px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: nowrap;
    display: block;
    margin-right: 20%;
    margin-left: 8%;
    margin-top: 5%;
  }
  .consult-car-AL {
    display: flex;
     flex-direction: row;
      margin-left: 18%;
  }

  .consult-card-Az {
    color: white;
    font-size: 24px;
    width: 115px;
    height: 48px;
    background-color: #039147;
    border-radius: 100px;
    margin-top: 10%;
    display: grid;
    place-items: center;
  }
  
  /* Media query for screens less than 600px wide */
  @media (max-width: 650px) {
    .medicine-button-container {
      width: 110px;
      height: 25px;
      left: 10%;
      right: 0;
      display: flex;
      justify-content: center;
      margin-left: 6%;
    }
    .consult-car-AX{
      font-size: 10px;
      font-weight: 600;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      margin-right: 20%;
      margin-left: 2%;
      margin-top: 5%;
    }
    .consult-card-Az {
      color: white;
      font-size: 12px;
      width: 80px;
      height: 24px;
      background-color: #039147;
      border-radius: 100px;
      padding-top: 2%;
      margin-top: -8%;
    }
    .consult-car-AL {
      display: flex;
       flex-direction: row;
        margin-left: 22%;
        margin-top: 5%;
    }
    .medicine-button {
      /* Adjust padding for smaller screens */
      justify-content: center;
      align-items: center;
     
      display: inline-flex;
    }
  
    .medicine-button-text {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
    .medicine-button-container {
      font-size: 3vw;
    }
  }
  @media screen and (max-width: 450px) {
    .consult-car-AX{
      font-size: 10px;
      font-weight: 600;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      margin-right: 20%;
      margin-left: 2%;
      margin-top: 5%;
    }
    .consult-card-Az {
      color: white;
      font-size: 12px;
      width: 80px;
      height: 24px;
      background-color: #039147;
      border-radius: 100px;
      padding-top: 2%;
      margin-top: -8%;
    }
    .medicine-button-container {
      font-size: 3vw;
    }
  }