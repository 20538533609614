.footernavgridcontainer{
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
}

.footernavbarimg{
    width: 32px;
}

.footernavbutton{
    border: 1px solid white;
    background-color: white;
    cursor: pointer;
}

.footernavgridcontainer{
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
}

.footernavbarimg{
    width: 32px;
}

.footernavbutton{
    border: 1px solid white;
    background-color: white;
    cursor: pointer;
}

.medharvaFootercontainer{
    background: #044022;
    height: 400px;
    /* position: absolute; */
    /* bottom: 0px; */
    width: 100%;
    overflow-x: hidden;
}

.medharvaFooteritem{
    display: flex;
    justify-content: start;
    align-items: center;
}

.medharvaFooterimg{
    width: 62px;
    height: 62px;
}

.medharvaFootertitle{
    color: white;
    font-size: 28px;
    font-family: Poppins;
    font-weight: 500;
}

.medharvaFooteranchor{
    margin-right: 20px;
}

.medharvaFooteritemheading{
    color: white;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: 30px;
}

.medharvaFooteritem2 a{
    text-decoration: none;
}

.medharvaFooteranchor2{
    color: #D9D9D9;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
}

.medharvaFooteritem2{
    display: grid;
}