@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.rootemailSubscribe{
    display: grid;
    place-items: center;
    color: #000;
}

.emailSubscribeitem{
    display: flex;
    align-items: center;
    justify-content: start;
    background: #039147;
    border-radius: 6px;
    padding: 25px 30px 25px 20px;
}

.emailSubscribeimg{
    width: 40px;
}

.emailSubscribetext{
    text-align: center;
    color: white;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 500;
    white-space: nowrap;
}

.emailSubscribebutton{
    text-align: center;
    color: black;
    font-size: 16px;
    background: white;
    font-family: Montserrat;
    font-weight: 600;
    border-radius: 6px;
    padding: 12px 45px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.emailSubscribeTextFieldContainer {
    width: 420px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    background: #42BA7C;
    border-radius: 6px;
    border: 1px solid #42BA7C;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customInput {
    width: 100%;
    height: 100%;
    background: #42BA7C;
    color: white;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 18px;
}

.customInput::placeholder{
    color: white;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 400;
}

@media (max-width: 1300px) {
    .emailSubscribeitem{
        display: flex;
        justify-content: center;
    }

    .emailSubscribeimgitem{
        display: grid;
        place-content: center;
    }

    .emailSubscribeimg{
        width: 46px;
    }

    .emailSubscribeimg2{
        width: 26px;
    }

    .emailSubscribebutton{
        text-align: center;
        color: black;
        font-size: 0px;
        background: white;
        font-family: Montserrat;
        font-weight: 600;
        border-radius: 6px;
        padding: 0px 7px;
        border: none;
        cursor: pointer;
    }

    .emailSubscribeTextFieldContainer {
        width: 40vw;
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        background: #42BA7C;
        border-radius: 6px;
        border: 1px solid #42BA7C;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 400px) {
    .customInput::placeholder{
        color: white;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 400;
    }
}