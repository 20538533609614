.form-container {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-container label,
  .form-container select {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-container input,
  .form-container select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .form-container button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container button[type="submit"]:hover {
    background-color: #45a049;
  }
  