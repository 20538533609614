.whymedharvacarecontainer{
    display: grid;
    place-items: center;
}

.whymedharvacareitem{
    display: grid;
    place-items: center;
    background-color: #039147;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: 36px;
    font-family: Montserrat;
    font-weight: 700;
    z-index: 99;
    text-wrap: nowrap;
}

.whymedharvacareitem2{
    display: flex;
    justify-content: start;
}

.whymedharvacarecontainer2{
    display: flex;
    justify-content: start;
    align-items: center;
}

.whymedharvacarecontainer3{
    display: grid;
    place-items: center;
}

.whymedharvacarecontainer4{
    display: grid;
    place-items: end;
    text-align: end;
}

.whymedharvacareitem2heading{
    color: black;
    font-size: 40px;
    font-family: Poppins;
    font-weight: 600;
}

.whymedharvacareitem2button{
    padding: 5px 30px;
    background: #D9D9D9;
    border-radius: 8px;
    border: 2px black solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    text-align: center;
    color: #0E0E0E;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    cursor: pointer;
}

.whymedharvacareitem2text{
    color: black;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
}

.whymedharvaimagesize{
    width: 80%;
    height: 300px;
}

.whymedharvacarecontainerroot{
    background: #E6E6E6;
    border-radius: 23px;
}

@media screen and (max-width: 1000px) {
    .whymedharvaimagesize {
      width: 100%;
      height: auto;
    }

    .whymedharvacareitem{
        display: grid;
        place-items: center;
        background-color: #039147;
        border-radius: 10px;
        padding: 10px;
        color: white;
        font-size: 30px;
        font-family: Montserrat;
        font-weight: 700;
        z-index: 99;
        text-wrap: nowrap;
    }

    .whymedharvacareitem2heading{
        color: black;
        font-size: 30px;
        font-family: Poppins;
        font-weight: 600;
    }

    .whymedharvacareitem2button{
        padding: 5px 30px;
        background: #D9D9D9;
        border-radius: 8px;
        border: 2px black solid;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        text-align: center;
        color: #0E0E0E;
        font-size: 18px;
        font-family: Poppins;
        font-weight: 500;
        cursor: pointer;
    }

    .whymedharvacareitem2text{
        color: black;
        font-size: 18px;
        font-family: Poppins;
        font-weight: 500;
    }
  }