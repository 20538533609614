@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.callback {
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4ea;
}
.callbackmain {
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4ea;
}
.selectheading{
    display:none;
   
}

.callh2 {
    height: auto;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    justify-content: left;
    align-items: center;
    text-align: left;
    text-wrap: nowrap;
}
.callh2 span {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #878787;
}
.callh22 {
    display: none;
}
.callh23 {
    display: none;
}
.column2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.column3 {
    display: flex;
    justify-content: left;
    align-items:first center    ;
    text-align: center;
}
.column3 {
    display: flex;
    justify-content: center;
    align-items:first center    ;
    text-align: center;
}
.column99 {
    display: flex;
    justify-content: center;
    align-items:center;
    text-align: center;
}
.column22 {
    display: none;
    width: 60vw;
    border-top: 3px solid #039147;
    margin: 10px 0;
}
.centered-placeholder::placeholder {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: center;
}
.column21 {
    display: flex;
    
    font-size: 32px;
    font-weight: 700;
    /* font-family: Montserrat; */
}
.page1 {
    height: 55px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
    display: flex;
    margin-right: 20px;
    width: 55px;
    background-color: #039147;
}
.error{
    color: red;
}
.page {
    height: 42px;
    width: 42px;
    display: flex;
    object-fit: cover;
    background-color: #039147;
}
.h2call {
    width: 350px;
    display: inline-flex;
    font-family: Poppins;
    font-size: 40px;
    justify-content: left;
    align-items: center;
    text-align: left;
    font-weight: 600;
    line-height: 60px;
    text-wrap: nowrap;
}


.inputselectspan{
    color: #131313;
    font-size:small;
    font-family: Poppins;
    font-weight: bolder;
    position:relative ;
    top: 2px;
}
.selecttextfield{
    font-size: 10px;
    background-color: #5653;
    color: #767676;
    border-radius: 8px;
}
.buttongrid{
    display: flex;
justify-content: left;
align-items:flex-start;
}
@media (max-width: 899.5px) {
    .selectheading{
        display:none;
       
    }
    .centered-placeholder::placeholder {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;
    }
    .page1 {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        padding: 10px 10px 10px 10px;
        display: flex;
        margin-right: 20px;
        background-color: #039147;
    }
   
    .column2 {
       
        display: flex;
        height: 100px;
        justify-content: center;
        align-items:center;
        text-align: center;
    }
    .column3 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .column21 {
        display: none;
    }
    .column22 {
        display: flex;
        width: 60vw;
        border-top: 3px solid #039147;
        margin: 10px 0;
    }
    .page {
        height: 33px;
        width: 33px;
        display: flex;
        object-fit: cover;
    }
    .h2call {
        
        width: 200px;
        font-family: Poppins;
        font-size: 30px;
        align-items: center;
        text-align: center;
    }
    .buttongrid{
        display: flex;
    justify-content: center;
    align-items: center;
    }
}
@media (max-width: 600px) {
    .selectheading{
        display:flex;
        justify-content:center;
        text-align:center;
    }
    .callback {
        height: 100vh auto;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items:flex-start;
    }
    .centered-placeholder::placeholder {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
    }
    .callh2 {
        display:grid;
        height: 50px;
        font-family: Poppins;
        margin: 0px;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        word-wrap: break-word;
    }
    .callh2 span {
        display:grid;
        font-family: Montserrat;
        font-size: 12px;
        margin: 0px;
        font-weight: 600;
        line-height: 14px;
        color: #878787;
        text-align: center;
    }
    .page1 {
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-right: 20px;
        align-items: center;
        padding: 10px 10px 10px 10px;
        display: flex;
        width: 20px;
        background-color: #039147;
    }
 
    .page {
        height: 18px;
        width: 18px;
        display: flex;
        object-fit: cover;
    }
    .column2 {
        display: flex;
        height: 50px;
        justify-content: center;
        align-items:flex-end;
        text-align: center;
    }
    .column3 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .column21 {
        display: none;
        
    }
    .column22 {
        display: flex;
        
        width: 60vw;
        border-top: 3px solid #039147;
        margin: 0px;
    }
    .h2call {
        width: auto;
        font-family: Montserrat;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .buttongrid{
        display: flex;
    justify-content: center;
    align-items: center;
    }
}

@media (max-width: 280px) {
 
    .callh2 {
        display:grid;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
    }
    .callh2 span {
        display:grid;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        color: #878787;
        text-align: center;
    }
  
   
  
}