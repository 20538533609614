.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 45px;
    z-index: 20;
  }
  .icon-style{
    background-color: #2591eb;
    border: 2px solid #2591eb;
    border-radius: 1px;
    transform: scale(2.5);
    color: #fff;
    cursor: pointer;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    background: black;
    color: white;
    border: 2px solid black;
  }
  /* @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  } */