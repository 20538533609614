
.popover__wrapper {
  position: relative;
  margin-top: 1rem;
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 75px; /* Adjust the top position as needed */
  right: -90px;
  transform: translate(0, 10px);
  /* background-color: transparent; */
  background-color: #bfbfbf ;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 250px;
}



.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #bfbfbf transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}


.modal-area:hover, .modal-area:hover a {
  color: #fff;
  background: #039147;
  width: 220px;
}

.modal-area {
  padding: 5px;
  font-weight: bold;
  display: flex; /* Use flexbox to align the items in a row */
  align-items: center;
}



.modal-area a {
  color: black; /* Initial color is blue */
  text-decoration: none; /* Remove the underline on the link */
}




/* name css========== */


























