.consult-card-A{
    position: relative;
     display: flex;
      justify-content: center;
      align-items: center;
       padding: 0 16px;
       margin-top: 30px;
   
  }
  .consult-card{
    height: auto;
    width: 100%;
   
      border-radius: 6px;
       overflow: hidden ;
  }
  .consult-card-C{
    position: absolute;
    top: 35%;
    left: 20%;
     right: 55%;
     transform: translateY(-50%);
      color: black;
     
  }
  .consult-card-imge{
    height: 592px;
     width: 1352px;
     object-fit: cover;
      border-radius: 6px ;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.4;
     
  }
  .consult-car-D{
    font-size: 48px;
     font-weight: 600;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      margin-right: 24%;
      /* font-family: Montserrat; */
  }
  .consult-car-E{
    font-size: 48px;
     font-weight: 600;
     word-wrap: break-word;
     white-space: nowrap;
     display: block;
     /* font-family: Montserrat; */
  }
  .consult-car-X{
    font-size: 32px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: nowrap;
    display: block;
    margin-right: 20%;
    margin-left: 8%;
    margin-top: 5%;
  }
  /* .consult-card-y{
    width:115px;
     height:48px;
      background-color:#039147;
      border-radius:100px;
       margin-top:5%;
  } */
  .consult-card-z {
    color: white;
    font-size: 24px;
    width: 115px;
    height: 48px;
    background-color: #039147;
    border-radius: 100px;
    margin-top: 10%;
    padding-top: 2%;
 
  }
  @media screen and (max-width: 650px) {
    .consult-car-D{
      font-size: 16px;
       font-weight: 500;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        /* font-family: Montserrat; */
       
    }
    .consult-car-E{
      font-size: 16px;
       font-weight: 600;
       word-wrap: break-word;
       white-space: nowrap;
       display: block;
       /* font-family: Montserrat; */
    }
    .consult-car-X{
        font-size: 10px;
        font-weight: 600;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        margin-right: 20%;
        margin-left: 2%;
        margin-top: 5%;
      }
      .consult-card-imge{
        height: 192px;
         width: 352px;
         object-fit: cover;
          border-radius: 6px ;
          background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
          opacity: 0.6;
         
      }
      .consult-card-z {
        color: white;
        font-size: 12px;
        width: 80px;
        height: 24px;
        background-color: #039147;
        border-radius: 100px;
        padding-top: 2%;
        margin-top: -8%;
      }
  }
.consult-button-container {
    width: 458px;
    height: 74px;
    background: #039147;
    border-radius: 6px;
    position: absolute;
    top: 70%;
    left: 19%;
    right: 60%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
 
  .consult-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #039147;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
 
  .consult-button-text {
    text-align: center;
    color: white;
    font-size: 32px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    width: 100%;
  }
 
  /* Media query for screens less than 600px wide */
  @media (max-width: 650px) {
    .consult-button-container {
      width: 130px;
      height: 25px;
      left: 10%;
      right: 0;
      display: flex;
      justify-content: center;
    }
 
    .consult-button {
      /* Adjust padding for smaller screens */
      justify-content: center;
      align-items: center;
     
      display: inline-flex;
    }
 
    .consult-button-text {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  }