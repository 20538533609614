.medicinebuttonroot{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.medicinebuttoncontainer{   
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
}

.medicinebutton1icon{
    transform: scale(2);
}

.medicinebutton1text{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
}

.medicinebutton{
    background-color: white;
    border: 2px solid #039147;
    border-radius: 6px;
    padding: 15px 7vw;
    color: black;
    cursor: pointer;
    margin-bottom: 10px;
}

.medicinebutton2{
    background-color: white;
    border: 2px solid #039147;
    border-radius: 6px;
    padding: 15px 10vw;
    color: black;
    cursor: pointer;
    margin-bottom: 10px;
}

.medicinebutton:hover {
    background-color: #039147;
    color: white;
}

.medicinebutton2:hover {
    background-color: #039147;
    color: white;
}

.medicinebutton2icon{
    width: 35px;
    height: 35px;
}

.medicinebutton2:hover .medicinebutton2icon {
    filter: brightness(100%) invert(100%);
}

@media screen and (max-width: 850px) {
    .medicinebutton{
        width: 250px;
        margin-left: 10px;
    }

    .medicinebutton2{
        padding: 15px 11vw;
        width: 250px;
        margin-left: 10px;
    }

    .medicinebutton1text{
        font-size: 15px;
        word-wrap: break-word;
    }
}