.medicine-card-A{
  position: relative;
   display: flex;
    justify-content: center;
    align-items: center;
     padding: 0 16px;
  
}
.medicine-card{
  height: auto;
  width: 100%;
  object-fit: cover;
  
    border-radius: 6px;
     overflow: hidden ;
}
.medicine-card-C{
  position: absolute;
  top: 35%;
  left: 10%;
   right: 60%;
   transform: translateY(-50%);
    color: black;
   
}
.medicine-card-B {
  max-width: 1352px;
  max-height: 592px;
  position: relative;
}
.medicine-card-B::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: 80%; /* Adjust the width as needed */
  height: 200%;
  background: linear-gradient(to right, rgba(252, 249, 249, 0.87), rgba(255, 255, 255, 0));
}
.medicine-card-imge{
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
 
}
.medicine-car-D{
  font-size: 48px;
   font-weight: 600;
    word-wrap: break-word;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 25%;

    /* font-family: Montserrat; */
}
.medicine-car-E{
  font-size: 48px;
   font-weight: 600;
   word-wrap: break-word;
   white-space: nowrap;
   display: flex;
   justify-content: flex-start;
   margin-left: 25%;

   /* font-family: Montserrat; */
}
@media screen and (max-width: 650px) {
  .medicine-car-D{
    font-size: 16px;
     font-weight: 500;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      /* font-family: Montserrat; */
     
  }
  .medicine-car-E{
    font-size: 16px;
     font-weight: 600;
     word-wrap: break-word;
     white-space: nowrap;
     display: block;
     /* font-family: Montserrat; */
  }
}



.medicine-button-container {
  width: 458px;
  height: 74px;
  background: #039147;
  border-radius: 6px;
  position: absolute;
  top: 70%;
  left: 19%;
  right: 60%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.medicine-button {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #039147;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.medicine-button-text {
  text-align: center;
  color: white;
  font-size: 32px;
  font-weight: 500;
  word-wrap: break-word;
  text-transform: none;
  width: 458px;
  height: auto;
}

/* Media query for screens less than 600px wide */
@media (max-width: 650px) {
  .medicine-button-container {
    width: 130px;
    height: 25px;
    left: 16%;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .medicine-button {
    /* Adjust padding for smaller screens */
    justify-content: center;
    align-items: center;
   
    display: inline-flex;
  }

  .medicine-button-text {
    font-size: 12px; /* Adjust font size for smaller screens */
    width: 120px;
  }
}
