.testimonialroot{
    display: grid;
    place-items: center;
}

.testimonialscontainer{
    display: grid;
    justify-content: start;
    margin-left: 18vw;
}

.testimonialsheading{
    text-align: center;
    color: #039147;
    font-size: 48px;
    font-family: Montserrat;
    font-weight: 600;
}

.testimonialimg{
    width: 175px;
    height: 169px;
}

.testimonialtext{
    color: #8D8D8D;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
}

.testimonialname{
    color: black;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 600;
}

.testimonialscontainer2{
    display: grid;
    place-items: center;
    background: white; 
    border-radius: 11px; 
    border: 2.50px #039147 solid;
    padding: 10px 40px 30px 40px;
    text-align: center;
}

.testimonialscontainer1{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.testimonialimg2{
    width: 85px;
    height: 85px;
    margin-right: -9vw;
    z-index: 1;
    cursor: pointer;
}

.testimonialimg3{
    width: 85px;
    height: 85px;
    margin-left: -9vw;
    z-index: 1;
    cursor: pointer;
}
