@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");
.select-address-a {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.select-address-b {
  width: 1503px;
  height: auto;
  /* border:2px solid red; */
  border-radius: 50px;
  background-color: #f2f2f2;
}
.select-address-c {
  display: flex;
  justify-content: flex-start;
  margin-top: 5%;
  margin-left: 8%;
}
.select-address-d {
  font-size: 48px;
  font-weight: 500px;
  font-family: Montserrat;
}
.select-address-c-2 {
  display: flex;
  justify-content: flex-start;
  margin-top: 5%;
  margin-left: 8%;
}
.select-address-d-2 {
  font-size: 48px;
  font-weight: 500px;
}
.select-address-f {
  max-width: 1311px;
  height: 202px;
  display: flex;
  justify-content: center;
  margin-left: 6%;
  margin-top: 5%;
}
.select-address-g {
  width: 950px;
  height: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 76px;
}
.select-address-h {
  /* width: 933px; */
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: -2%;
  align-items: center;
  /* padding: 0 16px; */
  margin-bottom: 10%;
}
.select-address-i {
  font-size: 20px;
  font-weight: 500px;
  line-height: 30px;
  color: #000000;
  font-family: Poppins;
}
.select-address-ii {
  font-size: 16px;
  font-weight: 300px;
  line-height: 30px;
  color: #000000;
  font-family: Poppins;
}
.select-address-j {
  margin-top: -9%;
  width: 590px;
  height: 87px;
}
.select-address-k {
  font-size: 16px;
  margin-right: 22%;
  font-weight: 400px;
  color: #7e7e7e;
}
.select-address-l {
  font-size: 16px;
  margin-right: 25%;
  font-weight: 400px;
  color: #7e7e7e;
}
.select-address-m {
  width: 1237px;
  height: 76px;
  margin-left: 8%;
  margin-top: 16%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select-address-n {
  width: 393px;
  height: 56px;
  background-color: white;

  border-radius: 100px;
  display: flex;
  justify-content: center;
}
.select-address-p {
  width: 394px;
  height: 56px;
  background-color: #039147;
  border: 2px solid #039147;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1365px) {
  .select-address-m {
    width: 1000px;
    height: 66px;
    margin-left: 15%;
    margin-top: 16%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .select-address-n {
    width: 293px;
    height: 46px;
    background-color: white;

    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
  .select-address-p {
    width: 294px;
    height: 46px;
    background-color: #039147;
    border: 2px solid #039147;
    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
}

/* @media (max-width: 950px) {
    .select-address-m {
        width: 650px;
        height: 66px;
    margin-left: 15%;
          margin-top: 10%;
          display: flex;
           flex-direction: row;
            justify-content: space-between;
    }
    .select-address-n {
        width: 240px;
         height: 46px; 
         background-color: white;
        
           border-radius: 100px;
           display: flex;
            justify-content: center;
    }
    .select-address-p {
        width: 240px;
        height: 46px;
        background-color: #039147;
         border: 2px solid #039147;
          border-radius: 100px;
          display: flex;
           justify-content: center;
    }
  }
  @media (max-width: 820px) {
    .select-address-m {
        width: 650px;
        height: 66px;
    margin-left: 8%;
          margin-top: 12%;
          display: flex;
           flex-direction: row;
            justify-content: space-around;
    }
    .select-address-n {
        width: 240px;
         height: 46px; 
         background-color: white;
        
           border-radius: 100px;
           display: flex;
            justify-content: center;
    }
    .select-address-p {
        width: 240px;
        height: 46px;
        background-color: #039147;
         border: 2px solid #039147;
          border-radius: 100px;
          display: flex;
           justify-content: center;
    }
  } */

@media (max-width: 712px) {
  .select-address-a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .select-address-b {
    width: 305px;
    height: auto;
  }

  .select-address-c {
    justify-content: center;
    margin: 5% auto;
    text-align: center;
  }
  .select-address-c-2 {
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }
  .select-address-d-2 {
    font-size: 24px;
    font-weight: 600px;
    color: #000000;
    line-height: 36px;
  }
  .select-address-g {
    width: 320px;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    border-radius: 8px;
  }
  .select-address-h {
    /* width: 368px; */
    height: 84px;
    display: flex;

    margin-top: -20%;
    align-items: center;
    /* padding: 0 16px; */
    margin-bottom: 10%;
    margin-left: -15%;
  }
  .select-address-i {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
  }
  .select-address-ii {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: #000000;
  }
  .select-address-j {
    /* margin-top: -9%; */
    width: 420px;
    height: 84px;
    margin-left: -10%;
  }
  .select-address-k {
    font-size: 12px;
    white-space: none;
    font-weight: 400px;
    color: #7e7e7e;
  }
  .select-address-l {
    font-size: 12px;
    text-align: left;
    font-weight: 400px;
    color: #7e7e7e;
  }
}

@media (max-width: 477px) {
  .select-address-a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .select-address-b {
    width: 250px;
    height: auto;
  }

  .select-address-c {
    justify-content: center;
    margin: 5% auto;
    text-align: center;
    display: flex;
  }
  .select-address-c-2 {
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }
  .select-address-d-2 {
    font-size: 24px;
    font-weight: 500px;
    color: #000000;
    margin-right: 12%;
  }
  .select-address-g {
    width: 250px;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    margin-right: 12%;
    flex-direction: column;
    border-radius: 8px;
    /* border:2px solid red; */
  }
  .select-address-h {
    /* width: 280px; */
    height: 50px;
    display: flex;

    margin-top: -40%;
    align-items: center;
    /* padding: 0 16px; */
    margin-bottom: 10%;
    margin-left: -45%;
  }
  .select-address-i {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
  }
  .select-address-ii {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: #000000;
  }
  .select-address-j {
    /* margin-top: -9%; */
    width: 275px;
    height: 84px;
    margin-left: -32%;
  }
  .select-address-k {
    font-size: 10px;
    white-space: none;
    font-weight: 400px;
    color: #7e7e7e;
    text-align: left;
    display: flex;
    justify-content: center;
  }
  .select-address-l {
    font-size: 10px;
    display: flex;
    justify-content: center;
    font-weight: 400px;
    color: #7e7e7e;
  }
}

@media (min-width: 651px) and (max-width: 1200px) {
  .select-address-b {
    width: 100%;
    max-width: 1200px;
    border-radius: 50px;
    background-color: #f2f2f2;
  }
  .select-address-a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .select-address-c {
    display: flex;
    justify-content: flex-start;
    margin-top: 5%;
    margin-left: 20%;
  }
  .select-address-d {
    font-size: 40px;
    font-weight: 500px;
  }
  .select-address-c-2 {
    display: flex;
    justify-content: flex-start;
    margin-top: 5%;
    margin-left: 8%;
  }
  .select-address-d-2 {
    font-size: 40px;
    font-weight: 500px;
  }
  .select-address-f {
    max-width: 1100px;
    height: 150px;
    display: flex;
    justify-content: center;
    margin-left: 6%;
    margin-top: 5%;
  }
  .select-address-g {
    width: 520px;
    height: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 70px;
  }
  .select-address-h {
    /* width: 500px; */
    height: 15px;
    display: flex;
    justify-content: space-between;
    margin-top: -2%;
    align-items: center;
    /* padding: 0 16px; */
    margin-bottom: 10%;
    margin-left: -3%;
  }
  .select-address-i {
    font-size: 20px;
    font-weight: 500px;
    line-height: 30px;
    color: #000000;
  }
  .select-address-ii {
    font-size: 16px;
    font-weight: 300px;
    line-height: 20px;
    color: #000000;
  }
  .select-address-j {
    margin-top: -9%;
    width: 590px;
    height: 87px;
  }
  .select-address-k {
    font-size: 16px;
    margin-right: 22%;
    font-weight: 400px;
    color: #7e7e7e;
    text-align: left;
  }
  .select-address-l {
    font-size: 16px;
    margin-right: 25%;
    font-weight: 400px;
    color: #7e7e7e;
    text-align: left;
  }

  .select-address-m {
    width: 650px;
    height: 66px;
    margin-left: 22%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .select-address-n {
    width: 240px;
    height: 46px;
    background-color: white;

    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
  .select-address-p {
    width: 240px;
    height: 46px;
    background-color: #039147;
    border: 2px solid #039147;
    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1050px) {
  .select-address-m {
    width: 750px;
    height: 66px;
    margin-left: 10%;
    margin-top: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .select-address-n {
    width: 250px;
    height: 46px;
    background-color: white;

    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
  .select-address-p {
    width: 250px;
    height: 46px;
    background-color: #039147;
    border: 2px solid #039147;
    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .select-address-m {
    width: 700px;
    height: 66px;
    margin-left: 8%;
    margin-top: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .select-address-n {
    width: 230px;
    height: 40px;
    background-color: white;

    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
  .select-address-p {
    width: 230px;
    height: 40px;
    background-color: #039147;
    border: 2px solid #039147;
    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 820px) {
  .select-address-m {
    width: 650px;
    height: 66px;
    margin-left: 10%;
    margin-top: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .select-address-n {
    width: 220px;
    height: 40px;
    background-color: white;

    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
  .select-address-p {
    width: 220px;
    height: 40px;
    background-color: #039147;
    border: 2px solid #039147;
    border-radius: 100px;
    display: flex;
    justify-content: center;
  }
}
/* @media (max-width: 700px) {
    .select-address-m {
      display: none;
    }
  } */

/* next previous */

.spacebutton {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 8%;
}

.previous {
  padding: 0.3rem 8vw;
  background: white;
  border-radius: 100px;
  border: 2px #039147 solid;
  place-items: center;
  display: grid;
  color: #039147;
  text-transform: none;
  font-family: inherit;
  font-size: 22px;
  cursor: pointer;
}

.previous:hover {
  color: white;
  border: 1px solid #039147;
  background: #039147;
}

.next {
  padding: 0.3rem 8.5vw;
  background: white;
  border-radius: 100px;
  border: 2px #039147 solid;
  place-items: center;
  display: grid;
  color: #039147;
  text-transform: none;
  font-family: inherit;
  font-size: 22px;
  cursor: pointer;
}

.next:hover {
  color: white;
  border: 1px solid #039147;
  background: #039147;
}
/* @media (max-width: 700px) {
  .spacebutton {
    display: none;
  }
} */
