@import url("https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");

.rectangle {
  height: 138px;
  object-fit: cover;
  position: relative;
  width: 437px;
}

.frame-5 {
  align-items: center;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #039147;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  min-height: 168px;
  max-height: 523px;
  padding: 5px;
  height: auto;
  /* left: 80px; */
  /* top: 72px; */
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  width: 260px;
}

.frame-5 .filters {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 19px 0px;
  position: relative;
  width: 267px;
}

.frame-5 .text-wrapper {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  display: flex;
  position: relative;
  text-align: center;
}

.frame-5 .text-wrapper22 {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 25px;
  line-height: normal;
  display: flex;
  position: relative;
  text-align: center;
  width: 120px;
}

.frame-5 .text-wrapper23 {
  color: #039147;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 25px;
  line-height: normal;
  display: flex;
  position: relative;
  text-align: center;
  width: 120px;
}

.frame-5 .page {
  height: 10px;
  left: 209px;

  position: absolute;
  top: 25px;
  width: 20px;
}
.centerrangebutton12{
  height: 25px;
  width: 75px;
  font-size: 8px;
  font-family: Poppins;
  font-weight: 400;
  text-transform: none;
  border-radius: 100px;
  background-color: #039147;
  color: #ffffff;
  border: 1px solid #039147;
}
.centerrangebutton12:hover{

  background-color: #FFFFFF;
  color: #039147;
  border: 1px solid #039147;
}
.centerrangebutton1{
  height: 25px;
  width: 75px;
  font-size: 8px;
  font-family: Poppins;
  font-weight: 400;
  text-transform: none;
  border-radius: 100px;

  background-color: #FFFFFF;
  color: #039147;
  border: 1px solid #039147;
}
.centerrangebutton1:hover{
  background-color: #039147;
  color: #ffffff;
  border: 1px solid #039147;

}
.slotpricepar{
  font-size: medium;
}
.frame-5 .page22 {
  height: 10px;
  left: 210px;

  position: absolute;
  top: 25px;
  width: 20px;
}

.frame-5 .line {
  height: 2px;
  position: relative;
  width: 214px;
}

.frame-5 .div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 16px;
  left: 20px;
  justify-content: center;
  padding: 19px 22px;
  position: relative;
  width: 257px;
}

.category11 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  width: 257px;
  margin-top: 25px;
  height: 523px;
  flex-direction: column;
  gap: 9px;
  height: 303px;
  position: relative;
}

.category11 .filters11 {
  align-items: center;
  display: inline-flex;
  gap: 102px;
  height: 45px;
  padding: 15px 22px;
  position: relative;
}

.category11 .text-wrapper11 {
  color: #039147;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -5px;
  margin-top: -7px;
  position: relative;
  text-align: center;
  width: 100%;
}

.category11 .page11 {
  height: 10.46px;
  position: relative;
  width: 20.02px;
}

.category11 .frame11 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 62px;
  justify-content: center;
  padding: 4px 0px 20px;
  position: relative;
}

.category11 .rectangle11 {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #000000;
  border-radius: 100px;
  height: 34px;
  position: relative;
  width: 205px;
}

.category11 .div11 {
  color: #7e7e7e;

  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;

  height: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;

  width: 206px;
}

.category11 .frame-211 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100px;
  border: 2px solid;
  border-color: #039147;
  height: 34px;
  overflow: hidden;
  position: relative;
  width: 205px;
}

.category11 .text-wrapper-211 {
  color: #039147;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  left: 74px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 70px;
}

.category11 .img11 {
  height: 17px;
  left: 31px;
  position: absolute;
  top: 9px;
  width: 17px;
}

.category11 .frame-311 {
  background-position: 50% 50%;
  background-size: cover;
  border: 2px solid;
  border-color: #039147;
  border-radius: 100px;

  height: 34px;
  overflow: hidden;
  position: relative;
  width: 205px;
}

.category11 .text-wrapper-311 {
  color: #039147;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  left: 68px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 84px;
}

.pricedropdownmaindiv {
  width: 275px;
  height: auto;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  /* border: 2px solid #039147; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  gap: 4px;
  display: inline-flex;
}

.pricedropdownheading {
  font-size: 12px;
  display: inline-flex;
  align-items: flex-start;
  margin: 10px 0px 0px 30px;
  align-self: flex-start;
  justify-content: flex-start;
  font-family: Poppins;
  font-weight: 500;
}
.pricedropdownheading1 {
  font-size: 12px;
  display: inline-flex;
  align-items: flex-start;
  margin: 0px 0px 0px 30px;
  align-self: flex-start;
  justify-content: flex-start;
  font-family: Poppins;
  font-weight: 500;
}
.pricedropdown-span {
  font-size: 8px;
  display: inline-flex;
  align-items: flex-start;
  margin: 0px 0px 0px 30px;
  align-self: flex-start;
  justify-content: flex-start;
  font-family: Poppins;
  font-weight: 500;
}

.pricedropdownchart-container {
  /* height: 51px; */
  padding: 44px 8px 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.pricedropdownslider-container {
  width: 82.71px;
  /* height: 50.71px; */
  position: relative;
}

.pricwdropdowninputdiv {
  width: 236px;
  padding-top: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pricedropdownprice-input {
  width: 99px;
  height: 50px;
  padding: 5px;
  border-radius: 2px;
  overflow: hidden;
  border: 2px solid #039147;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.number-of-appointments-label {
  width: 160px;
  height: 32px;
  color: black;
  font-size: 10px;
  font-weight: 500;
  word-wrap: break-word;
}

.pricedropdownappointments-container {
  width: 202px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 4px;
  display: inline-flex;
}

.pricedropdownappointment-item {
  width: 26px;
  height: 20px;
  background: #d9d9d9;
  border-radius: 100px;
  border: 1px solid black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.pricedropdownresult-buttons-container {
  width: 234px;
  height: 44px;
  padding: 20px 10px 10px;
  justify-content: space-around;
  align-items: center;
  display: inline-flex;
}

.rangedropdownmaindiv {
  width: 275px;
  height: auto;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  /* border: 2px solid #039147; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  gap: 4px;
  display: inline-flex;
}
.rangedropdownprice-input {
  width: 99px;
  height: 50px;
  padding-left: 5px;
  border-radius: 2px;
  overflow: hidden;
  border: 2px solid #039147;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.rangedropdownresult-buttons-container {
  width: 234px;
  height: 83px;
  padding: 20px 10px 10px;
  justify-content: space-around;
  align-items: flex-end;
  display: inline-flex;
}
.rangeinkmlocation {
  width: 235px;
  height: 112px;
  border-radius: 6px;
  border: 1px solid #039147;
}
.frame-5 .text-wrapper24 {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 25px;
  line-height: normal;
  display: flex;
  position: relative;
  text-align: center;
  width: 150px;
}

.frame-5 .text-wrapper25 {
  color: #039147;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 25px;
  line-height: normal;
  display: flex;
  position: relative;
  text-align: center;
  width: 150px;
}
