.selectspecialityitem{
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 500;
}
 
.selectspecialitycontainer{
    display: flex;
    justify-content: center;
}
 
.selectspecialityitem2{
    width: 90%;
    height: 70px;
    padding: 0px 40px 0px 30px;
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    overflow: hidden;
}
.selectspecialitycard {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
   
}
 
.selectspecialityinput {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 20px;
}
 
.selectspecialityinput::placeholder{
    color: #7E7E7E;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
}
 
.selectspecialitybutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}
 
.selectspecialitycontainer2{
    display: grid;
    place-items: center;
    width: 100%;
}
 
.selectspecialitycontainer3{
    display: grid;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
 
.selectspecialityimg {
    width: 220px;
    height: 220px;
    border-radius: 100%;
    object-fit: cover;
    border: 3px solid transparent;
    transition: border-color 0.3s ease;
    display: flex;
    justify-self: center;
  }
 
.selectspecialitytext{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
 
.selectspecialityitem3{
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
}
 
.selectspecialityimg.selected {
    border-color: #39a84f;
}
 
.greenTick {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #39a84f;
    font-size: 30px;
    display: none;
}
 
.selectspecialitycontainer3.selected .greenTick {
    display: block;
}
 
@media screen and (max-width: 560px) {
    .selectspecialityitem{
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        display: grid;
        justify-content: center;
    }
 
    .selectspecialityitem3{
        justify-content: center;
    }
 
    .selectspecialityitem2{
        height: 50px;
    }
 
    .selectspecialityimg {
        width: 150px;
        height: 150px;
    }
 
    .selectspecialityinput {
        font-size: 18px;
    }
   
    .selectspecialityinput::placeholder{
        font-size: 18px;
    }
 
    .selectspecialityimg1{
        width: 30px;
        height: 30px;
    }
 
    .selectspecialitytext{
        font-size: 20px;
    }
}