/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;600&family=Poppins:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@200;300;400&display=swap'); */

.home-card-A{
    position: relative;
     display: flex;
      justify-content: center;
      align-items: center;
       padding: 0 16px;
    
  }
  .home-card{
    height: auto;
    width: 100%;
     max-width: 1352px;
      max-height: 592px;
      border-radius: 6px;
       overflow: hidden ;
  }
  .home-card-C{
    position: absolute;
    top: 35%;
    left: 20%;
     right: 60%;
     transform: translateY(-50%);
      color: black;
     
  }
  .home-card-imge1{
    height: 100%;
     width: 100%;
     object-fit: cover;
      border-radius: 6px ;
  }
  .home-car-D{
    font-size: 48px;
     font-weight: 600;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      /* font-family: Montserrat; */
  }
  .home-car-E{
    font-size: 48px;
     font-weight: 600;
     word-wrap: break-word;
     white-space: nowrap;
     display: block;
     /* font-family: Montserrat; */
  }
  @media screen and (max-width: 650px) {
    .home-car-D{
      font-size: 16px;
       font-weight: 500;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        /* font-family: Montserrat; */
       
    }
    .home-car-E{
      font-size: 16px;
       font-weight: 600;
       word-wrap: break-word;
       white-space: nowrap;
       display: block;
       /* font-family: Montserrat; */
    }
  }
  
  .slidetop{
      background-color: #f2f2f2;
  }
  .slidetopA{
      width: '100%';
       height: 100%;
        text-align: left;
        margin-bottom: 20px;
  }
  /*   text slider style.css */
  
  .specialitiesText {
      width: 100%;
      height: 100%;
      margin-left: 15%;
      margin-top: 60px;
    }
    
    .specialitiesText span {
      color: black;
      font-size: 40px;
      font-weight: 700;
      white-space: nowrap;
      display: block;
    }
    
    .specialitiesText span:last-child {
      color: #7e7e7e;
      font-size: 26px;
      font-weight: 700;
      white-space: nowrap;
      display: block;
    }
    
    /* Media query for screens smaller than 600px (mobile view) */
    @media screen and (max-width: 600px) {
     
      .specialitiesText {
          width: 100%;
          height: 1px;
          margin-left: 8%;
          margin-top: 55px;
          font-weight: 500;
        }
      .specialitiesText span {
          color: black;
          font-size: 20px;
          font-weight: 700;
        line-height: 19.5px;
        margin-top: 15px;
          white-space: nowrap;
          display: block;
          left: 25%;
        }
        
        .specialitiesText span:last-child {
          color: #7e7e7e;
          font-size: 10px;
          font-weight: 700px;
          line-height: 15px;
          display: flex;
         margin-top: 6px;
     
        }
      .sliderab{
          max-width: 1320px;
           margin: 0px;
           margin-bottom: 10px;
         
      }
      .specialitiesText span:last-child {
        font-size: 15px;
      }
    }
    .sliderab{
      max-width: 1320px;
           margin: 70px auto;
  }
  
  /* styles.css */
  
  /* slider button cards Default styles */
  .responsive-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 47px;
    margin-left: 11%;
    /* margin-top: 139px; */
  }
  
  .responsive-grid {
    width: 327px;
    height: 67px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .responsive-text {
    width: 305px;
    height: 36px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
  }
  
  /* Media query for screens less than 600px wide */
  @media (max-width: 650px) {
   .responsive-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 47px;  */
    margin-top: 80px;
    padding: 0px;
    margin-left: 2%;
    overflow-x: hidden;
  }
  
  .responsive-grid {
    width: 239px;
    height: 37px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .responsive-text {
    width: 305px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
  }
  
  }
  @media (max-width: 1193px) {
  
    .responsive-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      /* margin-bottom: 47px;  */
      margin-top: 30%;
      padding: 0px;
     
      margin-left: -5%;
     
    }
    
    .responsive-grid {
      width: 249px;
      height: 47px;
      padding: 10px;
      background: #039147;
      border-radius: 100px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
    }
    
    .responsive-text {
      width: 305px;
      height: 27px;
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word;
      text-transform: none;
    }
    
    }
  
  
  /* cards class style */
  /* .card-A{
    width: 335px;
    height: 390px;
    padding-top: 21;
    padding-bottom: 21;
    margin: 0 auto;
    background: 'white';
    box-shadow: '4px 4px 4px rgba(0, 145, 71, 0.28)';
    border-radius: 6;
    border: '2px #039147 solid';
    justify-content: 'center';
    align-items: 'center';
    display: 'flex';
     flex-wrap: 'wrap';
    gap: '10px';
    display: 'flex';
  } */
  
  /* styles.css */
  
  /* .slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .slick-dots li {
    margin: 0 5px;
  }
  
  .slick-dots li button {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #039147; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slick-dots li.slick-active button {
    background-color: white; 
  } */
  
  
  /* home button style */
  /* styles.css */
  
  .custom-button-container {
    width: 458px;
    height: 74px;
    background: #039147;
    border-radius: 6px;
    position: absolute;
    top: 70%;
    left: 19%;
    right: 60%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .custom-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #039147;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .custom-button-text {
    text-align: center;
    color: white;
    font-size: 32px;
    font-weight: 500;
    white-space: nowrap;
    text-transform: none;
    width: 100%;
  }
  
  /* Media query for screens less than 600px wide */
  @media (max-width: 650px) {
    .custom-button-container {
      width: 130px;
      height: 25px;
      left: 10%;
      right: 0;
      display: flex;
      justify-content: center;
    }
  
    .custom-button {
      /* Adjust padding for smaller screens */
      justify-content: center;
      align-items: center;
     
      display: inline-flex;
    }
  
    .custom-button-text {
      color: white;
      font-size: 11.5px;
    }
  }
  
  
  
  /* cards slider  */
  .card-main{
    max-width: 1275px;
     margin: auto;
  }
  .card-border{
    width: 100%;
     height: 100%;
      padding: 10px;
     
       align-items: center;
       display: inline-flex;
  }
  .card-border-A{
    width: 334px;
    height: 390px;
    padding-top: 21px;
    padding-bottom: 21px;
    /* margin: 0px auto; */
    background: white;
    box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
    border-radius: 6px;
    border: 2px #039147 solid;
    justify-content: center;
    align-items: center;
    display: flex;
     flex-wrap: wrap;
    gap: 10px;
    display: flex;
  }
  .card-border-B{
    width: 293px;            
     height: 221px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display:flex;
    gap: 36px;
    display: inline-flex;
  
  }
  .card-image{
    
    width: 292px;
    height: 205px;
    border-radius: 6px;
    border: 2px #039147 solid;
    cursor: pointer;
  }
  .card-title:hover{
    color: blue;
      }
  .card-title{
    width: 267px;
    height: 84px;
    font-family: Montserrat;
    /* height: 54px; */
    display: flex;
    align-items: center;
    justify-content:center;
    color: black;
    font-size: 25px;
    font-weight: 600;
    word-wrap: break-word;
    cursor: pointer;
   
  }
  .card-discription{
    width: 257px;
    height: 79px;
    /* height: 49px; */
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content:center;
    font-family:Poppins;
    color:#7E7E7E;
    margin-top: -30px;
    font-size: 15px;
    font-weight: 600px;
    word-wrap: break-word;
  }
  @media (max-width: 700px) {
    .card-main{
      max-width: 350px;
       margin: auto;
    }
    .card-border{
      width: 100%;
       height: 100%;
        padding: 8px;
         align-items: center;
         display: inline-flex;
      
    }
    .card-border-A{
      width: 267px;
      height: 290px;
      /* width: 334px;
      height: 340px; */
      /* padding-top: 25px; */
      padding-top: 21px;
      padding-bottom: 10px;
      padding-bottom: 0px;
      /* margin: 0px auto; */
      background: white;
      box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
      border-radius: 6px;
      border: 2px #039147 solid;
      justify-content: center;
      align-items: center;
      display: flex;
       flex-wrap: wrap;
      gap: 10px;
      display: flex;
     
    }
    .card-border-B{
      width: 180px;
      height: 150px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display:flex;
      gap: 20px;
      display: inline-flex;
    
    }
    .card-image{
      width: 245px;
      height: 150px;
      border-radius: 6px;
      border: 2px #039147 solid;
      cursor: pointer;
    }
    .card-title:hover{
  color: blue;
    }
    .card-title{
      width: 267px;
      height: 84px;
      text-align: center;
      color: black;
      /* font-size: 20px; */
      font-size: 22px;
      font-weight: 600;
      font-family: Montserrat;
      word-wrap: break-word;
      cursor: pointer;
    }
    .card-discription{
      width: 257px;
      height: 79px;
      text-align: center;
      color:#7E7E7E;
      font-size: 15px;
      font-weight: 600;
      word-wrap: break-word;
      font-family:Poppins;
    }
  }
  @media (max-width: 300px) {
  
  }
  
  
  /* text-slider-a */
  .text-slider-a{
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
    /* margin-top: 139px; */
  }
  .text-slider-B{
    width: 766px;
    height: 176px;
    text-align: left;
  }
  
  .text-slider-C{
    color: black;
    font-size: 40px; 
    font-weight: 700; 
    word-wrap: break-word;
    margin-left: 15%;
    white-space: nowrap;
   display: block;
  }
  .text-slider-D{
    color: #7E7E7E;
     font-size: 26px;
      font-weight: 700; 
       margin-left: 15%;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
  }
  @media (max-width: 650px) {
    .text-slider-a{
      display: flex;
      flex-direction: row;
      width: auto;
      height: auto;
      align-items: center;
      position: relative;
      justify-content: center;
      margin-top: 30px;
    }
    .text-slider-B{
      width: 262px;
      height: 40px;
      text-align: left;
      margin-bottom: 8%;
    }
    
    .text-slider-C{
      color: black;
      font-size: 20px; 
      font-weight: 700; 
      word-wrap: break-word;
      margin-left: -6%;
      /* font-family: 'Montserrat'; */
      line-height:19.5px;
      left: 10%;
      white-space: nowrap;
     display: block;
    }
    .text-slider-D{
      color: #7E7E7E;
       font-size: 13px;
        font-weight: 700; 
        margin-left: -6%;
        line-height: 15px;
        /* font-family: 'poppins'; */
          word-wrap: break-word;
          white-space: nowrap;
          display: block;
        
    }
  }
  .slick-dots li.slick-active button:before{
    color:#039147;
    width: 12px;
    height: 12px;
  }
  .slick-dots li button:before{
   
   font-size: 1rem !important;
   /* top: 1rem !important; */
  }
  /* .slick-slide >div{
  
    margin-left: 15px;
  } */
  .card-Border-button{
    background-color: #f2f2f2;
    justify-content: center;
     box-sizing: border-box;
      display: flex;
       gap: 20px;
   
  }
  .card-Border-button-A{
    width: 100%;
    height: 100%;
     padding: 10px;
      background-color: white;
       border: 1px solid #039147;
        border-radius: 6px;
        max-width: 1320px;
        justify-content: center;
         align-items: center;
         gap: 20px;
          display: flex;
           flex-wrap: wrap;
            margin: 15px auto;
          
  }
  .card-Border-button-AB{
    width: 250px;
    height:62px;
     margin: 10px;
  }
  .card-img1{
    width: 35px;
    height: 34px;
  }
  .card-Border-button-D{
    font-weight: 600px;
    word-wrap: break-word;
         font-size: 20px;
         text-transform: none;
         padding-top:1%;
    color: #7E7E7E;
    /* hover {
      background-color: transparent;
    } */
    &:hover {
      background-color: transparent;
    }
  
  }
  @media (max-width: 650px) {
    .card-Border-button{
      background-color: #f2f2f2;
      justify-content: center;
       box-sizing: border-box;
        display: flex;
         gap: 20px;
    }
    .card-Border-button-A{
      width: 80%;
      height: 100%;
       padding: 15px;
        background-color: white;
         border: 1px solid #039147;
          border-radius: 6px;
          max-width: 1300px;
          justify-content: center;
           align-items: center;
           gap: 20px;
            display: flex;
             flex-wrap: wrap;
              margin: 15px auto;
              margin-top: 15%;
  }
  
  }
  /* Mcare facilities */
  
  @media (max-width: 650px) {
    .M-care-A{
      display: none;
    }
  }
  @media (max-width: 770px) {
    .M-care-B{
      width:1100px;
       height:300px;
       background: white;
    }
  }
  .M-care-A-1{
    width: 100% ;
    height: 100%;
     border-radius: 8px;
      display:flex;
       justify-content:center;
  }
  .M-care-B{
    width:1352px;
     height:353px;
     background: white;
     
  }
  .M-care-C{
    display: flex;
     flex-direction: row-reverse;
      justify-content: space-around;
      
  }
  /* .M-care-D{
  
  } */