@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@500&display=swap");

.WhatUserSaycontainer {
  display: grid;

  place-items: center;
  text-align: center;
}

.WhatUserSayimg{
    width: 200px;
    height: 150px;
}

.WhatUserSayuser {
  text-align: center;
  color: black;
  font-size: 60px;
  font-family: Montserrat;
  font-weight: 500;
}
.WhatUserSaydes {
  color: #039147;
  font-size: 38px;
  font-family: Poppins;
  font-weight: 500;
}

.WhatUserSayVimdhayak {
  color: #7e7e7e;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 7px;
}
.WhatUserSaygggg {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .WhatUserSaycontainer {
    display: grid;

    place-items: center;
    text-align: center;
    gap: 10px;
  }
  .WhatUserSayVimdhayak {
    font-size: 16px;
  }
  .WhatUserSaydes {
    font-size: 16px;
    font-family: Poppins;
  }

  .WhatUserSayuser {
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 500;
  }
  .WhatUserSayimg {
    width: 76px;
    height: 76px;
  }
  .WhatUserSayggg {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 500;
  }
}
