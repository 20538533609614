@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.filtertypo{
  font-family: 'Montserrat', 'Quicksand', sans-serif;
  font-size: 20px;
}

.filtertypo1{
    font-family: 'Montserrat', 'Quicksand', sans-serif;
    font-size: 15px;
  }

.filterbutton{
    display: none;
}

.filterdoctorpopup{
    display: none;
}

@media (min-width: 1135px) and (max-width:1280px) {
    .filterdoctor {
      display: none;
    }

    .filterbutton{
        display: flex;
        gap: 75%;
        margin-left: -120px;
    }

    .filterdoctorpopup{
        display: inline;
    }
}

@media (min-width: 840px) and (max-width:1135px) {
    .filterdoctor {
      display: none;
    }

    .filterbutton{
        display: flex;
        gap: 70%;
        margin-left: -120px;
    }

    .filterdoctorpopup{
        display: inline;
    }
}

@media (min-width:450px) and (max-width: 840px) {
    .filterdoctor {
      display: none;
    }

    .filterbutton{
        display: flex;
        gap: 60%;
        margin-left: -120px;
    }

    .filterdoctorpopup{
        display: inline;
    }
}

@media (max-width:450px) {
    .filterdoctor {
      display: none;
    }

    .filterbutton{
        display: flex;
        gap: 20%;
        margin-left: -120px;
    }

    .filterdoctorpopup{
        display: inline;
    }
}