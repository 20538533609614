.specificlocationroot{
    display: grid;
    place-items: center;
    width: 100%;
    overflow-x: hidden;
}

.specificlocationcontainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.specificlocationitem{
    width: 20vw;
    height: 45px;
    padding-left: 3vw;
    padding-right: 1vw;
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.specificlocationitem2{
    width: 70%;
    height: 100%;
    padding: 0px 2vw 0px 2vw;
    background: white;
    border-radius: 6px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.specificlocationinput {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

.specificlocationinput::placeholder{
    color: #7E7E7E;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
}

.specificlocationbutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.specificlocationimg1{
    width: 30px;
    height: 30px;
}

.specificlocationlabel{
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
}

.selectyourloc{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
}

.specificlocationlabelitem2{
    width: 60vw;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    border-radius: 6px;
    border: 2px solid #039147;
    display: flex;
    justify-content: center;
    align-items: center;
}

.specificlocationlabelinput{
    width: 100%;
    height: 100%;
    background: white;
    color: black;
    border: none;
    outline: none;
    padding: 8px 20px;
    box-sizing: border-box;
    font-size: 18px;
}

.specificlocationlabelinput::placeholder{
    color: #7E7E7E;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
}

.specificlocationheading{
    font-weight: 500;
    font-family: Poppins;
}

.specificlocationlabelbutton{
    background: white;
    color: #0E0E0E;
    border: 2px solid #0E0E0E;
    border-radius: 100px;
    padding: 7px 40px;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.specificlocationlabelbutton:hover{
    background: #0E0E0E;
    color: white;
}

.specificlocationtext{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    color: #7E7E7E;
}

.specificlocationlabelbutton.selected {
    background: #0e0e0e;
    color: white;
}

.specificlocationspacebutton{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.specificlocationprevious{
    padding: .3rem 8vw;
    background: white;
    border-radius: 100px;
    border: 2px #039147 solid;
    place-items: center;
    display: grid;
    color: #039147;
    text-transform: none;
    font-family: inherit;
    font-size: 22px;
    cursor: pointer;
}

.specificlocationprevious:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}

.specificlocationnext{
    padding: .3rem 8.5vw;
    background: white;
    border-radius: 100px;
    border: 2px #039147 solid;
    place-items: center;
    display: grid;
    color: #039147;
    text-transform: none;
    font-family: inherit;
    font-size: 22px;
    cursor: pointer;
}

.specificlocationnext:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}

.mapcontainer{
    width: 100vw;
    height: 506px;
    border-radius: 6px;
    border: none; 
}

@media screen and (max-width: 700px) {
    .specificlocationspacebutton{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .specificlocationprevious{
        font-size: 18px;
    }

    .specificlocationitem2{
        z-index: 999;
        position: absolute;
        top: 250px;
        width: 60%;
        left: 5%;
        height: 40px;
        padding: 0px 40px 0px 10px;
        border: 3px solid #039147;
    }

    .specificlocationitem{
        z-index: 999;
        position: absolute;
        top: 210px;
        right: 5%;
        width: 28%;
        height: 40px;
        padding-left: 3px;
        border: 3px solid #039147;
    }

    .specificlocationcontainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .specificlocationinput {
        font-size: 12px;
    }
    
    .specificlocationinput::placeholder{
        font-size: 12px;
    }

    .specificlocationimg1{
        width: 20px;
        height: 20px;
    }

    .specificlocationlabelinput{
        font-size: 14px;
    }

    .specificlocationlabelinput::placeholder{
        font-size: 13px;
    }

    .specificlocationlabelbutton{
        padding: 7px 10px;
    }

    .specificlocationheading{
        font-size: 16px;
    }

    .mapcontainer{
        width: 100vw;
        height: 300px;
        border-radius: 6px;
        border: none; 
    }
}