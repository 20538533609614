 /* .card-main {
    height: 320px;
}

.card-text {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #039047;
    margin-top: 20px;
    
}

.card-process {
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 60px;
    text-decoration: solid;
}

.text-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    line-height: 30px;
    justify-content: space-between;
}

.registration-text {
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

@media screen and (max-width: 768px) {
    .card-main {
        height: auto; 
    }

    .card-process {
        flex-direction: column; 
        gap: 20px; 
    }

    .registration-text {
        width: auto; 
        margin-top: 10px; 
    }
}

@media screen and (max-width: 480px) {
    .card-text {
        font-size: 20px; 
        margin-top: 10px; 
    }

    .text-name {
        font-size: 16px; 
    }

    .registration-text {
        margin-top: 5px; 
    }
}  */


.card-main {
    height: 320px;
    padding: 20px;
}

.card-text {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #039047;
    margin-bottom: 20px;
}

.card-process {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 45px;
  
}

.text-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    color: #000000;

}

.registration-text {
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .card-main {
        height: auto;
    }
    .card-process{
        height: auto;
        display: inline;
       
    }
}

@media screen and (max-width: 480px) {
    .card-text {
        font-size: 20px;
    }
    .card-process{
        height: auto;
        display: inline;
       
    }

    .text-name {
        height: auto;
        font-size: 16px;
        word-spacing: 10px;
    }
    
}
