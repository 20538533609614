.skeleton {
    border-radius: 2px;
    display: inline-block;
    line-height: 100%;
    width: 100%;
    background-color: #fff;
    background-size: 1000px 1000px;
    background-image: linear-gradient(
      100deg,
      #e8e8e8 20%,
      #fafafa 50%,
      /* #e8e8e8 60% */
      #d9d9d9 90%
    );
    animation: placeholderShimmer 1.5s linear infinite forwards;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 500px 0;
    }
  }
 
  .skeleton.circle {
    border-radius: 50%;
  }