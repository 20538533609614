/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Quicksand:wght@450&display=swap');
*{
  font-family: 'Quicksand', sans-serif;
}
 
.navbar{
    height: 48px;
    background-color: #039147;
    padding: 0px 12px;
    font-size: 14px;
    display: flex;  
    justify-content: space-between;
}
 
.navbar.scrolling {
  display: none;
}
 #accordian-header{
  background-color: #bfbfbf ;
  padding: 2px;
  font-weight: bold;
  display: flex; /* Use flexbox to align the items in a row */
  align-items: center;
 

 }
 #accordian-header:hover{
  background-color: #039147 ;
  color: white;
 

 }
.navpart1{
    height: 48px;
    display: flex;    
}
 
.navpart2{
    height: 48px;
    display: flex;  
    margin-right: 200px;
}
 
.number{
    padding: 10px;
    border-left: 1px solid rgba(182, 182, 255, 0.177);
    border-right: 1px solid rgba(182, 182, 255, 0.177);
    font-family: sans-serif;
    font-size: 12px;
    margin-left: 110px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.number a,.mail a{
  color: white;
}
 
.mail{
    padding: 10px;
    border-right: 1px solid rgba(182, 182, 255, 0.177);
    font-family: sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.phoneicon,.mailicon{
    transform: scale(1.5);
}
 
.icon1{
  padding: 10px;
  border-left: 1px solid rgba(182, 182, 255, 0.177);
  border-right: 1px solid rgba(182, 182, 255, 0.177);
  font-family: sans-serif;
  font-size: 12px;
  margin-left: 110px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease-in-out;
}
 
.icon2,.icon3,.icon4{
  padding: 10px;
  border-right: 1px solid rgba(182, 182, 255, 0.177);
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease-in-out;
}
 
.icon1:hover{
    background-color: #039147;
    color: white;
}
 
.icon2:hover,
.icon3:hover,
.icon4:hover{
  background-color: #039147;
  color: white;
}
 
.navbar1{
    height: 90.53px;
    background-color: white;
    padding: 0px 12px;
    font-size: 14px;
    display: flex;  
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
}
 
.logo{
  position: absolute;
  left: 10px;
}
 
.headers{
    display: flex;
    margin-left: 100px;
}
 
.headername{
    margin-right: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}
 
.headername a{
  /* color: #878686; */
  color: #039147;
  font-weight: 100;
  padding-top: 30px;
  padding-bottom: 30px;
}
 
.headername a:hover{
    color: #039147;
}
 
a.selected {
    color: #039147;
  }

  .headername .dropdown-content-medical {
    position: absolute;
    display: none;
    z-index: 1;
    background-color: #f1f0f0;
    min-width: 160px;
    border: 1px solid #f1f0f0;
    border-radius: 5px;
    margin-top: 330px;
    font-size: 14px;
    left: 260px;
  }
 
  .headername:hover .dropdown-content-medical,
  .headername:hover .dropdown-content-surgical {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
 
  .headername:hover .dropdown-content-corporates {
    display: block;
    /* grid-template-columns: repeat(3, 1fr); */
    /* padding-right: 150px; */
  }
 
  .headername .dropdown-content-medical a,
  .headername .dropdown-content-surgical a,
  .headername .dropdown-content-corporates a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .headername .dropdown-content-medical a:hover,
  .headername .dropdown-content-surgical a:hover,
  .headername .dropdown-content-corporates a:hover {
    /* color: #2591eb; */
    background-color: #039147;
    color: white;
  }
 
  .headername .dropdown-content-surgical,
  .headername .dropdown-content-corporates {
    position: absolute;
    display: none;
    z-index: 1;
    /* background-color: #f1f0f0; */
    background-color: white;
    min-width: 160px;
    /* border: 1px solid #f1f0f0; */
    border: 1px solid white;
    /* border-radius: 5px; */
    /* margin-top: 15px; */
    margin-top: 30px;
    /* left: 260px; */
    /* font-size: 14px; */
    font-size: 16px;
  }

  .newword{
    border: 1px solid red;
    background-color: red;
    color: white;
    font-size: 10px;
    border-radius: 10px;  
    padding: 2px 5px 2px 5px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
  }

  .newword2{
    border: 1px solid red;
    background-color: red;
    color: white;
    font-size: 10px;
    border-radius: 10px;  
    padding: 2px 9px 2px 9px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
  }
  
  @media (max-width: 1280px) {
    .navbar {
      display: none;
    }

    .menuicon{
      transform: scale(1.3);
    }
 
    .dropdown {
        position: fixed;
        right: 4%;
        top: 2.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .popover__wrapper{
        position: relative;
        right: 20px;
        bottom: 6px;
      }

      .loginavatar{
        position: fixed;
        right: 4%;
        top: 2.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
     
      .dropdown-content {
        position: fixed;
        width: 95%;
        z-index: 1;
        display: block;
        left: 20px;
        margin-top: 580px;
        background-color: white;
        overflow-y: hidden;
        border-radius: 10px;
        overflow-y: scroll;
        margin-bottom: 20px;
        max-height: 500px;
        font-size: 15px;
      }

      .dropdownupaar-content {
        position: fixed;
        width: 95%;
        z-index: 1;
        display: block;
        left: 20px;
        margin-top: 10px;
        background-color: white;
        overflow-y: hidden;
        border-radius: 10px;
        overflow-y: scroll;
        margin-bottom: 20px;
        max-height: 500px;
      }

      .accordiandetails{
        max-height: 300px;
        margin-top: -10px;
        margin-bottom: -10px;
        /* overflow-y: scroll; */
      }
     
      .dropdown-content a, .dropdownupaar-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      .accordiansummary:hover{
        background-color: #039147;
        color: white;
      }

      .accordiansummary.expand {
        background-color: #039147;
        color: white;
      }

      .accordiansummary.expanded {
        background-color: #039147;
        color: white;
        margin-top: -15px;
      }
 
      .dropdown-content a:hover, .dropdownupaar-content a:hover {
        background-color: #039147;
        color: white;
      }
 
      .dropdown-content a.selected, .dropdownupaar-content a.selected {
        color: #039147;
      }
 
      .dropdown-content a.selected:hover, .dropdownupaar-content a.selected:hover {
        color: white;
      }
 
      .mdbutton{
        position: fixed;
        top: 3.5%;
      }
}