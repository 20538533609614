@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@500&display=swap');


.personclass{
    overflow-x: hidden;
}

.selectlabh2{
    width: 100vw;
    height: auto;
    font-family: Montserrat;
   
    font-size: 35px;
    font-weight: 600;
    line-height: 58px;
}
.select1{
    padding: 20px ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: #f2f4ea;
    overflow: hidden;
}
.selectcard {
    height: 242px;
    width: 597px;
    margin: 40px 0px 0px 15px;
    border-radius: 8px;
    padding: 30px;
}
.selecth3{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}
.selecth3 span{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7E7E;
}
.profileimg{
    height: 144px;
    width: 200px;
    object-fit: cover;
}
.selecth44{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.selecth44 span{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7E7E;
}
.grid38{
    display: flex;
    justify-content:space-around;
    /* align-items: center; */
}
.grid17{
    display: flex;
    justify-content:space-around;
    align-items: center;
}
.grid18{
    display: flex;
    justify-content: flex-start;
}
.button11   {
    font-family: Poppins;
}
.selecttextfield1{
    background-color: #ffffff;
    color: #767676;
    border-radius: 8px;
}
.select1 .searchlabtest1{
    width: 95%;
    height: 60px;
    padding: 0px 2vw 0px 2vw;
    background: white;
    
    border-radius: 6px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}
.searchlabtest1 .searchlabtestinput {
    width: 100%;
    height: 100%;
    background: white;
    margin-left: 25px; 
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 20px;
    
}

.searchlabtestinput::placeholder{
    color: #7E7E7E;
    font-size: 20px;
    
    font-family: Poppins;
    font-weight: 500;
}
.searchlabtestbutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.searchlabtestimg1{
    width: 30px;
    height: 30px;
}
.select1 .sortAndFilterContainer{
    display: none;
}
@media (max-width: 900px) {
    .selectlabh2{
        width: 100vw;
        height: auto;
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        justify-content: center;
    }
    .grid38{
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
    .grid17{
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .select1 .sortAndFilterContainer{
        display: none;
    }
}
@media (max-width: 600px) {
    .searchlabtestimg1{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .select1 .searchlabtest1{
        width: 95%;
        height: 50px;
        padding: 0px 0px 0px 0px;
        background: white;
        
        border-radius: 6px;
        border: 2px solid #039147;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }
    .selecttextfield1{
        background-color: #ffffff;
        color: #767676;
        border-radius: 8px;
    }
  
    .searchlabtest1 .searchlabtestinput {
        width: 100%;
        height: 100%;
        background: white;
        margin-left: 25px; 
        color: #7E7E7E;
        font-weight: 500;
        font-family: Poppins;
        border: none;
        outline: none;
        padding: 8px;
        margin-bottom: 8px;
        box-sizing: border-box;
        font-size: 20px;
        
    }
    
    .searchlabtestinput::placeholder{
        color: #7E7E7E;
        font-size: 24px;
        
        font-family: Poppins;
        font-weight: 500;
    }
   .select1 .sortAndFilterContainer{
        display: flex;
    }
    .selectlabh2{
        width: 100vw;
        height: auto;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        justify-content: center;
    }
    .selectcard {
        height: 143px;
        width: 307px;
        margin: 40px 0px 0px 15px;
        border-radius: 8px;
        padding: 30px;
    }
    .searchlabtestinput::placeholder{
        color: #7E7E7E;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 500;
    }
    .searchlabtest1{
        width: 90%;
        height: 56px;
        padding: 0px 2vw 0px 2vw;
        background: white;
        border-radius: 6px;
        border: 1px solid white;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }
    .grid33{
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .selecth3{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }
    .selecth3 span{
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        color: #7E7E7E;
    }
    .profileimg{
        height: 56px;
        width: 78px;
        object-fit: cover;
    }
    .grid38{
        display: flex;
        justify-content: flex-end;
        /* align-items: center; */
    }
    .grid17{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .selecth44{
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
    }
    .selecth44 span{
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        color: #7E7E7E;
    }
}
@media (max-width: 300px) {
    .selectcard {
        height: 242px;
        width: 597px;
        margin: 40px 0px 0px 15px;
        border-radius: 8px;
        padding: 30px;
    }

}