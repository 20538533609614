@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.slick-dots li.slick-active button:before {
    color: #039147;
  }
  .Main-HW1 {
    max-width: 1262px;
    height: 505px;
     margin: auto;
  }
  .medicine-border-1{
      width: 100%;
       height: 100%;
        padding: 10px;
       
         align-items: center;
         display: inline-flex;
    }
    .medicine-border-2{
      width: 334px;
      height: 410px;
      padding-top: 21px;
      padding-bottom: 21px;
      /* margin: 0px auto; */
      background: white;
      box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
      border-radius: 6px;
      border: 2px #039147 solid;
      justify-content: center;
      align-items: center;
      display: flex;
       flex-wrap: wrap;
      gap: 10px;
      display: flex;
    }
    .medicine-border-3{
      width: 293px;            
       height: 221px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display:flex;
      gap: 36px;
      display: inline-flex;
    
    }
    .medicine-image-4{
      
      width: 292px;
      height: 205px;
      border-radius: 6px;
      border: 2px #039147 solid;
    }
    .medicine-title-5{
      width: 293px;
      height: 55px; 
     
    }
    .medicine-title-5a{
      color: black;
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      margin-right: 60%;
      margin-top: -4%;
    }
    .medicine-title-5b{
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      color: #7E7E7E;
      margin-right: 75%;
      white-space: none;
    }
   .medicine-label {
    width: 303px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
   }
   .medicine-label-a {
    width: 152px;
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: #F2F4EA;
    border-radius: 100px;
    padding: 1%;
   }
   .medicine-label-a2 {
    width: 100px;
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: #F2F4EA;
    border-radius: 100px;
    padding: 1%;
   }
   .btnmedi-card-A1 {
    width:289px;
    height:36px;
    padding: 24px 0px, 0px, 0px;
    gap: 32px;
    display:flex;
     flex-direction:row;
   justify-content:'space-around';

          
   }
   .btnmedi-card-A2 {
    width:128px;
     height:29px;
     border-radius:2px;
   }
   .btnmedi-card-B1 {
    width:128px;
     height:29px;
      border-radius:2px;
   }
  
    @media (max-width: 700px) {
      .Main-HW1{
        max-width: 350px;
         margin: auto;
      }
      .medicine-border-1{
        width: 100%;
     height: 100%;
      padding: 8px;
       align-items: center;
       display: inline-flex;
        
      }
      .medicine-border-2{
        width: 267px;
        height: 290px;
        /* width: 334px;
        height: 340px; */
        /* padding-top: 25px; */
        padding-top: 21px;
        padding-bottom: 10px;
        padding-bottom: 0px;
        /* margin: 0px auto; */
        background: white;
        box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
        border-radius: 6px;
        border: 2px #039147 solid;
        justify-content: center;
        align-items: center;
        display: flex;
         flex-wrap: wrap;
        gap: 10px;
        display: flex;
       
      }
      .medicine-border-3{
        width: 180px;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display:flex;
    gap: 20px;
    display: inline-flex;
      
      }
      .medicine-image-4{
        width: 245px;
        height: 150px;
        border-radius: 6px;
        border: 2px #039147 solid;
      }
      .medicine-title-5{
        width: 230px;
      height: 35px; 
      margin-top: -8%;
      }
      .medicine-title-5a{
        color: black;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 24px;
        margin-right: 70%;
      margin-top: -2%;
        font-family: Poppins;
      }
      .medicine-title-5b{
        font-size: 10px;
        font-weight: 600;
        line-height: 18px;
        font-family: Poppins;
        color: #7E7E7E;
        margin-right: 80%;
        white-space: nowrap;
      }
     .medicine-label {
      width: 250px;
      height: 2px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-right: 4%;
      
     }
   
       .medicine-label-a {
        width: 150px;
        height: 24px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        background-color: #F2F4EA;
        border-radius: 100px;
        margin-left: 2%;
        margin-top: -10%;
       }
       .medicine-label-a2 {
        width: 90px;
        height: 24px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        background-color: #F2F4EA;
        border-radius: 100px;
        padding: 1%;
        margin-top: -10%;
       }
       .btnmedi-card-A1 {
        width:250px;
        height:30px;
       padding: 24px 0px, 0px, 0px;
        margin-right: 1%;
        margin-top: -10%;
       
       }
       .btnmedi-card-A2 {
        width:95px;
         height:25px;
         border-radius:2px;
       }
       .btnmedi-card-B1 {
        width:95px;
         height:25px;
          border-radius:2px;
       }
   
    }
    .Main-D-02 {
      display: flex;
       flex-direction: row;
       justify-content:space-around; 
       margin-right: 9%;
    }
    /* .Main-D-2 {
      width: 766px;
       height: 176px;
    }
     .Main-D-3 {
      font-size:40px;
       font-weight:700;
        line-height:48.76px;
        margin-right:14%;
         margin-top:10%;
         white-space: nowrap;
         display: block;
     }                                           button css */
  
  
    .medibtn-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 47px;
    margin-left: 11%;
    margin-top: 20%;
    
  }
  
  .medibtn-grid {
    width: 327px;
    height: 67px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .medibtn-text {
    width: 305px;
    height: 36px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
  }
  /* Media query for screens less than 600px wide */
  @media (max-width: 1193px) {
   
    .medibtn-container {
      height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   /* margin-bottom: 47px;  */
   margin-top: 30%;
   padding: 0px;
  
   margin-left: -5%;
     
    }
    
    .medibtn-grid  {
      width: 249px;
      height: 47px;
      padding: 10px;
      background: #039147;
      border-radius: 100px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
    }
    
    .medibtn-text  {
      width: 305px;
      height: 27px;
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word;
      text-transform: none;
    }
    
    }
    @media (max-width: 1180px) {
      .medibtn-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        /* margin-bottom: 47px;  */
      
        padding: 0px;
        margin-left: 2%;
       margin-top: 40%;
     }
     
     .medibtn-grid{
      width: 239px;
      height: 37px;
      padding: 10px;
      background: #039147;
      border-radius: 100px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
     }
     
     .medibtn-text  {
      width: 305px;
      height: 26px;
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word;
      text-transform: none;
      font-family: Montserrat;
     }
     
     }
     @media (max-width: 1000px) {
      .medibtn-container {
        height: 60%;
        width: 60%;
        display: flex;
        justify-content: center;
        /* margin-bottom: 47px;  */
     
        padding: 0px;
        margin-left: 18%;
       margin-top: 5%;
     }
     
     .medibtn-grid{
      width: 239px;
      height: 37px;
      padding: 10px;
      background: #039147;
      border-radius: 100px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
     }
     
     .medibtn-text  {
      width: 305px;
      height: 26px;
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 500;
      word-wrap: break-word;
      text-transform: none;
      font-family: Montserrat;
     }
     
     }
  @media (max-width: 650px) {
    .medibtn-container {
      height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
   
    padding: 0px;
    margin-left: 2%;
   margin-top: -20%;
   }
   
   .medibtn-grid  {
    width: 239px;
    height: 37px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
   }
   
   .medibtn-text  {
    width: 305px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    font-family: Montserrat;
   }
   
   }
  


                                            /* text */
 

.text-slider-0a{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 5%;
  font-family: Montserrat;
}
.text-slider-0B{
  width: 766px;
  height: 176px;
  text-align: left;
}

.text-slider-0C{
  color: black;
  font-size: 40px; 
  font-weight: 700; 
  word-wrap: break-word;
  margin-left: 16%;
  font-family: Montserrat;
  white-space: nowrap;
  margin-top: 5%;
 display: block;
}

@media (max-width: 920px) {
  .text-slider-0a{
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
   
  }
  .text-slider-0B{
    width: 262px;
    height: 40px;
    text-align: left;
    margin-bottom: 8%;

  }
  
  .text-slider-0C{
    color: black;
    font-size: 24px; 
    font-weight: 700; 
    word-wrap: break-word;
    font-family: Montserrat;
    margin-left: -70%;
    /* font-family: 'Montserrat'; */
    line-height:19.5px;
    left: 10%;
    white-space: nowrap;
   display: block;
 
  }
}
@media (max-width: 730px) {
  .text-slider-0a{
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .text-slider-0B{
    width: 262px;
    height: 40px;
    text-align: left;
    margin-bottom: 8%;
  }
  
  .text-slider-0C{
    color: black;
    font-size: 20px; 
    font-weight: 700; 
    word-wrap: break-word;
    font-family: Montserrat;
    margin-left: -6%;
    /* font-family: 'Montserrat'; */
    line-height:19.5px;
    left: 10%;
    white-space: nowrap;
   display: block;
  }
 
}                                           