@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.numberupdatescontainer{
    justify-content: center;
    align-items: center;
}

.numberupdatesitem{
    display: flex;
    border: 3px solid #039147;
    border-radius: 6px;
    padding: 40px;
    background: white;
    gap: 20px;
}

.numberupdatesinneritem{
    border: 3px solid #039147;
    padding: 10px 10px;
    border-radius: 6px;
    display: grid;
    place-items: center;
    width: 220px;
}

.imagestyle{
    border: 1px solid #d8d9d8;
    padding: 5px;
    border-radius: 100%;
    background: #039147;
    display: grid;
    place-items: center;
}

.numberimagesize{
    width: 50px;
    height: 50px;
}

.numbertext{
    margin-top: 20px;
    font-weight: 500;
    font-family: Poppins;
}

.meditationlogospan{
    display: none;
}

.meditationlogo{
    display: none;
}

@media (max-width: 1000px) {
    .numberupdatescontainer2{
        background-color: white;
    }

    .numberupdatesitem{
        display: grid;
        place-items: center;
        border: none;
        gap: 0px;
        line-height: 10px;
    }

    .numberupdatesinneritem{
        border: none;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;
        padding: 10px 0px;
        width: 210px;
    }

    .numbertext{
        font-weight: 500;
        font-size: 20px;
        font-family: Poppins;
        margin-bottom: 15px;
    }

    .numberimagesize{
        width: 30px;
        height: 30px;
    }

    .meditationlogo{
        display: grid;
        justify-content: end;
        width: 212px;
    }

    .meditationlogospan{
        display: flex;
        justify-content: end;
    }
}