@import url("https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");

.Choosespecialitymaindiv {
  display: flex;
  width: 100vw;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: #f2f4ea;
  margin-bottom: 30px;
  margin-top: -50px;
}
.Choosespecialitydiv1 {
  min-width: 450px;
  height: auto;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  display: flex;
  max-width: 100vw;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.Choosespecialitydiv2 {
  max-width: 450px;
  height: auto;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.Choosespecialitydiv3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.Choosespecialityheding1 {
  width: 222px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Poppins;
  word-wrap: break-word;
  margin: 20px 0px 10px 0px;
}

.Choosespecialityspan {
  width: 210px;
  height: 129px;
  font-size: 10px;
  font-weight: 400;
  font-family: Poppins;
  line-height: 15px;
  word-wrap: break-word;
  margin-top: 0px;
}

.Choosespecialitydiv4 {
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Choosespecialitydiv5 {
  width: 222px;
  height: 180px;
}
.imageChoosespecialitydiv4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Choosespecialityimg1 {
  margin-top: -12px;
}

.Choosespecialityimg2 {
  margin-top: 40px;
}

.Choosespecialityimg {
  margin-top: -75px;
}
@media (max-width: 340px) {
  .Choosespecialitydiv5 {
    width: 210px;
    height: 205px;
  }
  .Choosespecialityheding1 {
    width: 200px;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: Poppins;
    word-wrap: break-word;
    margin: 20px 0px 10px 0px;
  }

  .Choosespecialityspan {
    width: 200px;
    height: 129px;
    font-size: 10px;
    font-weight: 400;
    font-family: Poppins;
    line-height: 15px;
    word-wrap: break-word;
    margin-top: 0px;
  }

  .Choosespecialitydiv3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
}
@media (max-width: 300px) {
  .Choosespecialitydiv5 {
    width: 190px;
    height: 209px;
  }
  .Choosespecialityheding1 {
    width: 180px;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: Poppins;
    word-wrap: break-word;
    margin: 20px 0px 10px 0px;
  }

  .Choosespecialityspan {
    width: 180px;
    height: 129px;
    font-size: 10px;
    font-weight: 400;
    font-family: Poppins;
    line-height: 15px;
    word-wrap: break-word;
    margin-top: 0px;
  }

  .Choosespecialitydiv3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0px;
  }
}
