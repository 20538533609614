
:root {
  --switches-bg-color1: #D9D9D9;
  --switches-label-color: #ffffff;
  --switch-bg-color: #039147;
  --switch-text-color: #ffffff;
}
.cardLogin {
 display: flex;
 
  padding: 20px;
  width: 310px; 
 height:min-content;
  text-align: center;

}


.switches-containerLogin {

  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color1);

  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 100%;
  line-height: 2.5rem; 
  
}


.switches-containerLogin input {
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%; 
  height: 100%; 
  text-align: center;
   color: white;
    font-size: 18px; 
    font-family: Poppins;
     font-weight: 500;
      line-height: 29px; 
      word-wrap: break-word
}


.switches-containerLogin label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: black;
}
.labellogin{
  font-size: 18px;
    font-weight: 500;
    line-height: 50px;
    font-family: Poppins;
}

.switch-wrapperLogin {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switchLogin {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
}
.switchLogin  div{
  width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color);
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}
/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.loginbuttonswitch {
  width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color) ;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}


/* slide the switch box from right to left */
.switches-containerLogin input:nth-of-type(1):checked~.switch-wrapperLogin {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-containerLogin input:nth-of-type(2):checked~.switch-wrapperLogin {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-containerLogin input:nth-of-type(1):checked~.switch-wrapperLogin .switchLogin div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-containerLogin input:nth-of-type(2):checked~.switch-wrapperLogin .switchLogin div:nth-of-type(2) {
  opacity: 1;
}


