
@media (max-width: 650px) {
  .M-care-A{
    display: none;
  }
}

.M-care-A-1{
    width: 100%;
    height: 100%;
     border-radius: 8px;
      display:flex;
       justify-content:center;
}
.M-care-B{
    width:1352px;
    height:353px;
    background: white;
}
.M-care-C {
    display: flex;
     flex-direction: row-reverse;
      justify-content: space-around;
}
.M-care-D{
  /* width:605px; */
   height:38px;
   text-align: right;
    color: black;
     font-size: 48px;
      font-family: Montserrat;
       font-weight: 600;
       line-height:38px;
        word-wrap: break-word;
        margin-top:5%;
         margin-left:20%;
}
.M-care-E{
  width: 806px;
  text-align: center;
  color: #7E7E7E;
   font-size: 24px;
   
     font-weight: 600;
      line-height: 38px;
       word-wrap: break-word;
        margin-left: 13%;
}
.M-care-F {
 display: flex;
 flex-direction: column;
}

.M-care-G{
  display: flex;
 flex-direction: row;
  justify-content: space-between;
  margin-left: 9%;
   margin-bottom: 20px;
}
.M-care-H {
  display: flex;
   flex-direction: row;
    margin-right: 8%;
     justify-content:space-between;
}
.M-care-I{
  width:50px;
   height:51px;
}
.M-care-J {
  color: black;
   font-size: 20px;
    font-family: Poppins;
     font-weight: 500px;
      line-height: 27px;
       word-wrap: break-word;
        white-space: nowrap;
        margin-left: 10%;
        margin-top: 5%;
    display: block;
}
.M-care-K {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 9%;
}
.M-care-L {
  display: flex;
  flex-direction: row;
    margin-right: 10%;
}
.M-care-M {
  color: black;
  font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
    line-height: 27px;
     word-wrap: break-word;
      white-space: nowrap;
      margin-left: 10%;
      margin-top: 5%;
    display: block;
}
.M-care-Y {
  margin-top: 40px;
}
.M-care-Z{
  width:379px;
  height:267px;
}


@media screen and (min-width: 651px) and (max-width: 1350px) {
  .M-care-B{
    width:900px;
    height:300px;
    background: white;
}
.M-care-C {
    display: flex;
     flex-direction: row-reverse;
      justify-content: space-around;
}
.M-care-D{
  width:400px;
   height:28px;
   text-align: right;
    color: black;
     font-size: 30px;
      font-family: Montserrat;
       font-weight: 500;
       line-height:28px;
        word-wrap: break-word;
        margin-top:5%;
         margin-left:20%;
}
.M-care-E{
  width: 650px;
  text-align: center;
  color: #7E7E7E;
   font-size: 16px;
   
     font-weight: 600;
      line-height: 38px;
       word-wrap: break-word;
        margin-left: 7%;
}
.M-care-H {
  display: flex;
   flex-direction: row;
    margin-right: 15%;
     justify-content:space-between;
}
.M-care-I{
  width:30px;
   height:30px;
}
.M-care-J {
  color: black;
   font-size: 16px;
    font-family: Poppins;
     font-weight: 500px;
      line-height: 27px;
       word-wrap: break-word;
        white-space: nowrap;
        margin-left: 10%;
        margin-top: 3%;
    display: block;
}
.M-care-K {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 9%;
}
.M-care-L {
  display: flex;
  flex-direction: row;
    margin-right: 15%;
}
.M-care-M {
  color: black;
  font-size: 16px;
   font-family: Poppins;
   font-weight: 500;
    line-height: 27px;
     word-wrap: break-word;
      white-space: nowrap;
      margin-left: 10%;
      margin-top: 5%;
    display: block;
}
.M-care-Y {
  margin-top: 40px;
  margin-left: 12%;
}
.M-care-Z{
  width:239px;
  height:200px;
}
.M-care-F {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
 }
}