.resources-main-1{
    display: flex;
    flex-direction: row;
}
.add-resources{
    display: flex;
    justify-content: flex-end;
}

 .resources-btn{
    cursor: pointer;
    width: 188px;
     height: 45px;
      color: #ffffff;
      background-color: #039147;
       /* font-family: Poppins; */
        font-size: 16px;
         line-height: 24px;
         text-align: center;
          text-transform: none;
          border: 2px solid white;
          border-radius: 4px;
          margin-bottom: 2%;
 }
.resources-img{
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 8px;
}
.resource-description{
    height: 40px;
    width: 220px;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 20px;

    font-weight: 500;
    
}