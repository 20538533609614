.health-1 {
    width: 100%;
     height: 100%;
     display: flex;
      justify-content: center;
}
.health-2 {
    width: 1151px;
     height: 227px;
      border-radius: 6px;
       background-color: white;
       display: flex;
        justify-content: center;
        align-items: center;
}
.health-3 {
    width: 1071px;
     height: 177px;
      display: flex;
      flex-direction: row-reverse; 
}
.health-4 {
    font-size:34px;
     font-weight: 600; 
     color: #000000;
      line-height:34px;
       white-space:nowrap; 
       margin-left:14%;
}
.health-5 {
    font-size:20px;
     line-height: 38px;
      font-weight: 600;
      color: #7E7E7E;
      white-space:nowrap;
}
.health-6 {
    display: flex;
     flex-direction:row;
    margin-top: 5%;
     justify-content: space-between;
}
.health-7 {
    width: 202px;
     height: 57px;
      display: flex;
       flex-direction: row;
       
}
.health-8 {
    width: 28px;
     height: 31px;
}
.health-9 {
    width: 161px;
     height: 34px;
     font-size: 14px;
     color: #039147;
     font-weight: 500;
     line-height: 17px;

   
}
.health-10 {
    width: 202px;
     height: 57px;
     display: flex;
      flex-direction: row; 
      margin-right: 3%;
}
.health-11 {
    width: 28px;
    height: 31px;
}
.health-12 {
    width: 161px;
     height:34px;
     font-size:14px;
      color:#039147;
       font-weight:500;
       line-height:17px;
       
}
.health-13 {
    width: 202px;
     height: 57px;
     display: flex;
      flex-direction: row;
      margin-right:5%
}
.health-14 {
    width: 28px;
     height: 31px;
}
.health-15 {
    width: 161px;
     height: 34px;
     font-size: 14px;
      color: #039147;
       font-weight: 500;
        line-height: 17px;
}
.health-16 {
    margin-right:15%;
}
.health-17 {
    width: 295px;
     height: 172px;
      object-fit: cover;
}
/* Update the existing CSS with media queries */
@media screen and (max-width: 650px) {
    .health-1 {
        width: 100%;
         height: 100%;
         display: flex;
          justify-content: center;
    }
    .health-2 {
        width: 360px;
         height: 444px;
          border-radius: 6px;
           background-color: white;
           display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5%;
    }
    .health-3 {
        display: flex;
        flex-direction: column;
        margin-bottom: 60%;
       
      }
      .health-4 {
        font-size: 14px;
         font-weight: 700; 
         color: #000000;
          line-height: 20px;
           white-space:nowrap; 
           margin-left:1%;
           
    }
    .health-5 {
        font-size: 10px;
         line-height: 20px;
          font-weight: 600;
          color: #7E7E7E;
          white-space:nowrap;
          margin-right: 3%;
    }
    
      .health-6 {
        display: flex;
        flex-direction: column-reverse;
       margin-top: 5%;
         margin-left: 12%;
      }
    
  
   .health-17 {
    margin-left: 50%;
    width: 202px;
    height: 121px;
   }
    /* .health-13 {
 margin-bottom: 3%;
 margin-top: 2%;
   
    } */
    
  }
  @media screen and (min-width: 651px) and (max-width: 1156px) {
    .health-2 {
      width: 100%; 
      max-width: 900px; 
      height: auto; 
      padding: 0 20px; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .health-3 {
      width: 100%; 
      max-width: 900px; 
      height: auto; 
      display: flex;
      flex-direction: column;
      align-items: center; 
    }
  
    .health-6 {
      width: 100%; 
      max-width: 900px;
      display: flex;
      flex-direction: column; 
      justify-content: flex-start;
     
      margin-top: 20px; 
    }
  
    .health-7,
    .health-10,
    .health-13 {
      width: 100%; 
      max-width: 900px; 
      display: flex;
      flex-direction: column; 
      align-items: center; 
      margin-top: 20px; 
    }
  
    .health-16 {
      width: 100%; 
      max-width: 900px; 
      margin-right: 0; 
    }
  
    .health-17 {
        width: 495px;
        height: auto;
      object-fit: cover;
    }
  }
  