

.btn{
    width:70px;
    height: 35px;
    border: 1px solid #1A73E8;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    background-color: transparent;
    color: #1A73E8;
    font-size: 18px;
    font-weight: normal;
    margin-top: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.btn:hover{
    
    background-color: #1A73E8;
    color: white;
   

}
.btn:active {

    width:84px;
    height: 35px;
    display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: red;
  border: none;
  border-radius: 10px;
  box-shadow: 0 9px #999;
 
 }
.btnselectAll{
    width:84px;
    height: 35px;
    display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 10px;
  box-shadow: 0 9px #999;

}
.btnselectAll:active{
    background-color: red;
    color: white;
}

