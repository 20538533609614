.avialabledoctorsroot{
    display: grid;
    justify-content: start;
    align-items: center;
}

.avialabledoctorsitem{
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 500;
}

.avialabledoctorsitemsearch{
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    display: grid;
    place-items: center;
    text-align: center;
}

.avialabledoctorscontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.avialablecenter{
    display: grid;    
    place-items: center;
    text-align: center;
    overflow-x: hidden;
}

.avialabledoctorsitemmob{
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 500;
}

.avialabledoctorsitem2{
    width: 700px;
    height: 52px;
    padding: 0px 40px 0px 30px;
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    overflow: hidden;
}

.avialabledoctorsinput {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 18px;
}

.avialabledoctorsinput::placeholder{
    color: #7E7E7E;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
}

.avialabledoctorsbutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.avialabledoctorsimg1{
    width: 30px;
    height: 30px;
}

.avialabledoctorsitem3{
    width: 190px;
    height: 52px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.avialabledoctorsinput2{
    width: 190px;
    height: 52px;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 15px;
}

.autocompletesdiv1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 2px; */
    /* border-bottom: 1px solid #ccc; */
}

.autocompletesdiv2{
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 20px;
}

.autocompletespan{
    display: flex;
    align-items: center;
}

.filtergriditems{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 580) {
    .avialabledoctorsitem3{
        display: grid;
        place-items: center;
        text-align: center;
    }

    .avialabledoctorsitem2{
        width: auto;
    }
}