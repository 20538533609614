@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&family=Poppins:wght@400&family=Quicksand:wght@300&display=swap");

.section1item {
  display: flex;
  justify-content: start;
  align-items: center;
}

.section1text {
  background-color: #f2f4ea;
  border: none;
  font-size: 11.3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-family: Poppins;
}

.section1service.medicines-service {
  margin-left: 22px;
}

.section1service {
  font-weight: 600;
  margin-left: 10px;
  font-family: Poppins;
}

.categorynavitem2 {
  display: grid;
  justify-content: start;
  align-items: center;
}

.categorynavitem2text:hover {
  color: #039147;
  transform: scale(1.05);
}

.categorynavitem2texttype2:hover {
  color: #039147;
  transform: scale(1.05);
}

.categorynavitem2text {
  background-color: #f2f4ea;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  font-family: Poppins;
}

.categorynavitem2texttype2 {
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  font-family: Poppins;
  display: flex;
  align-items: center;
}

.boxcontainer {
  width: 100%;
  height: 100%;
  background-color: #f2f4ea;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.boxcontainer::-webkit-scrollbar {
  width: 0px;
}

.boxcontainer::-webkit-scrollbar-track {
  background-color: #f2f4ea;
}

.boxcontainer::-webkit-scrollbar-thumb {
  background-color: #f2f4ea;
}

.onclosenav {
  background-color: #f2f4ea;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
  margin-top: 5px;
}

.selected {
  color: #039147;
}

.selected .section1img {
  filter: invert(31%) sepia(77%) saturate(1647%) hue-rotate(128deg)
    brightness(94%) contrast(98%);
}
