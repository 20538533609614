@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.Medhrwaprovider-card-A{
  position: relative;
   display: flex;
    justify-content: center;
    align-items: center;
     padding: 0 16px;
  
}

.Medhrwaprovider-card-B {
  max-width: 1579px;
  max-height: 592px;
  position: relative;
  z-index: 1;
}
.Medhrwaprovider-card-B::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: 100%; 
  height: 200%;
  background: linear-gradient(0deg, #BCD63A, #BCD63A);
  z-index: 0;
  opacity: .5;
}

.Medhrwaprovider-card-imge{
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
}
    /* Card Style */
 .Main-Card-Container{
    position: absolute;
     left: 60%;
      top: 28%;
 }                  

 .provedercard-1{
    width: 435px;
    height: 380px;
   padding:  45px, 24px, 26px, 24px;
   border-radius: 8px;
   background-color: #FFFFFF;
   border: 2px solid #0E0E0E;
   transform: translateZ(-50%);
   position: relative;
   z-index: 2;
   
 }                  
.provedercard-2 {
    width: 371px;
    height: 36px;
    margin-top: 5%;
    margin-left: 8%;
 
}
.provedercard-3{
    display: flex;
    justify-content: flex-start;
    font-size: 36px;
    color: #000000;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 43.88px;
}
.provedercard-4 {
    width: 371px;
    height: 18px;
    margin-top: 1%;
    margin-left: 8%;
  
}
.provedercard-5{
    display: flex;
    justify-content: flex-start;
    color: #000000;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 18px;
}
.provedercard-6 {
    width: 371px;
    height: 144px;
    margin-top: 5%;
    margin-left: 8%;
  
}
.provedercard-7{
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600px;
    line-height: 24px;
}

.provedercard-button-container {
    width: 229px;
    height: 57px;
    background: #BCD63A;
    border-radius: 6px;
    position: absolute;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    top: 70%;
    left: 24%;
    right: 60%;
    white-space: nowrap;
    margin-top: 10px;
  }
  
  .provedercard-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .provedercard-button-text {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    width: 229px;
    height: auto;
  }
  .Mobile-view-card-a {
    width: 294px;
    height: auto;
      position: absolute;
      text-align: right;
       z-index: 3;
       margin-left: 15%;
       margin-bottom: 14%;
  }
  .Mobile-view-card-b {
    font-family: 600;
    font-size: 20px;
    line-height: 22px;
    font-family: Poppins;
    color: #FFFFFF;
    display: block; 
    
  }
     /* medharwa provider mobile button */
  .medharwa-mob-button-container {
    width: 137px;
    height: 26px;
    background: #039147;
   
    position: absolute;
    top: 70%;
    left: 48%;
 
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    z-index: 4;
  }
  
  .medharwa-mob-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #039147;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .medharwa-mob-button-text {
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-transform: none;
    font-family: Poppins;
    width: 124px;
    height: auto;
  }

  @media screen and (max-width: 1360px) {
    .Main-Card-Container{
        position: absolute;
         left: 54%;
          top: 28%;
     } 
    
}
  @media screen and (max-width: 1273px) {
    .Main-Card-Container{
        position: absolute;
         left: 54%;
          top: 28%;
     } 
    
}
@media screen and (max-width: 1150px) {
    .Main-Card-Container{
        position: absolute;
         left: 50%;
          top: 28%;
     } 
    
}
@media screen and (max-width: 1100px) {
    .Main-Card-Container{
        position: absolute;
         left: 52%;
          top: 28%;
     } 
    .provedercard-1 {
        width: 400px;
        height: 320px;
    }
    .provedercard-2 {
        width: 315px;
        height: 30px;
        margin-top: 5%;
        margin-left: 8%;
     
    }
    .provedercard-3{
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
        color: #000000;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 43.88px;
    }
    .provedercard-4 {
        width: 315px;
        height: 15px;
        margin-left: 8%;
      
    }
    .provedercard-5{
        display: flex;
        justify-content: flex-start;
        color: #000000;
        font-size: 10px;
        font-family: Poppins;
        font-weight: 400;
        line-height: 18px;
    }
    .provedercard-6 {
        width: 315px;
        height: 120px;
        margin-top: 5%;
        margin-left: 8%;
      
    }
    .provedercard-7{
        text-align: left;
        color: #000000;
        font-size: 12px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 20px;
    }
    
}
@media screen and (min-width: 601px) and (max-width: 920px) {
    .Main-Card-Container {
        position: absolute;
        left: 45%;
        top: 28%;
    }

    .provedercard-1 {
        width: 300px; /* Adjust width as needed */
        height: 200px; /* Adjust height as needed */
    }

    .provedercard-2 {
        width: 255px; /* Adjust width as needed */
        height: 25px; /* Adjust height as needed */
        margin-top: 5%;
        margin-left: 8%;
    }

    .provedercard-3 {
        display: flex;
        justify-content: flex-start;
        font-size: 20px; /* Adjust font size as needed */
        color: #000000;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 36px; /* Adjust line height as needed */
    }

    .provedercard-4 {
        width: 255px; /* Adjust width as needed */
        height: 12px; /* Adjust height as needed */
        margin-left: 8%;
    }

    .provedercard-5 {
        display: flex;
        justify-content: flex-start;
        color: #000000;
        font-size: 8px; /* Adjust font size as needed */
        font-family: Poppins;
        font-weight: 400;
        line-height: 14px; /* Adjust line height as needed */
    }

    .provedercard-6 {
        width: 255px; /* Adjust width as needed */
        height: 90px; /* Adjust height as needed */
        margin-top: 5%;
        margin-left: 8%;
    }

    .provedercard-7 {
        text-align: left;
        color: #000000;
        font-size: 10px; /* Adjust font size as needed */
        font-family: Poppins;
        font-weight: 600;
        line-height: 16px; /* Adjust line height as needed */
    }
    .provedercard-button-container {
        width: 190px;
        height: 40px;
        background: #BCD63A;
 
        position: absolute;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
        top: 65%;
        left: 18%;
      
      }
      
      .provedercard-button {
        width: 100%;
        height: 100%;
        padding: 10px;
        
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
      }
      
      .provedercard-button-text {
        text-align: center;
        color: white;
        font-size: 16px;
        font-weight: 500;
        word-wrap: break-word;
        text-transform: none;
        width: 190px;
        height: auto;
      }
}
@media screen and (min-width: 577px) and (max-width: 600px) {
    .Main-Card-Container {
        position: absolute;
        left: 45%;
        top: 28%;
    }

    .provedercard-1 {
        width: 300px; /* Adjust width as needed */
        height: 200px; /* Adjust height as needed */
    }

    .provedercard-2 {
        width: 255px; /* Adjust width as needed */
        height: 25px; /* Adjust height as needed */
        margin-top: 5%;
        margin-left: 8%;
    }

    .provedercard-3 {
        display: flex;
        justify-content: flex-start;
        font-size: 20px; /* Adjust font size as needed */
        color: #000000;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 36px; /* Adjust line height as needed */
    }

    .provedercard-4 {
        width: 255px; /* Adjust width as needed */
        height: 12px; /* Adjust height as needed */
        margin-left: 8%;
    }

    .provedercard-5 {
        display: flex;
        justify-content: flex-start;
        color: #000000;
        font-size: 8px; /* Adjust font size as needed */
        font-family: Poppins;
        font-weight: 400;
        line-height: 14px; /* Adjust line height as needed */
    }

    .provedercard-6 {
        width: 255px; /* Adjust width as needed */
        height: 90px; /* Adjust height as needed */
        margin-top: 5%;
        margin-left: 8%;
    }

    .provedercard-7 {
        text-align: left;
        color: #000000;
        font-size: 10px; /* Adjust font size as needed */
        font-family: Poppins;
        font-weight: 600;
        line-height: 16px; /* Adjust line height as needed */
    }
    .provedercard-button-container {
        width: 190px;
        height: 40px;
        background: #BCD63A;
 
        position: absolute;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
        top: 65%;
        left: 18%;
      
      }
      
      .provedercard-button {
        width: 100%;
        height: 100%;
        padding: 10px;
        
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: inline-flex;
      }
      
      .provedercard-button-text {
        text-align: center;
        color: white;
        font-size: 16px;
        font-weight: 500;
        word-wrap: break-word;
        text-transform: none;
        width: 190px;
        height: auto;
      }
}
/* @media screen and (max-width: 390px) {
  
  .Mobile-view-card-a {
    width: 260px;
    height: auto;
      position: absolute;
      text-align: right;
       z-index: 3;
       margin-left: 8%;
       margin-bottom: 14%;
  }

} */