.rootnavbar {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 999;
  background-color: #f3f5ea;
}

.navbarcontainer1 {
  width: 100%;
  height: 100%;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  background: #039147;
  align-items: center;
  justify-content: center;
}

.navbaritem1 {
  color: #f2f4ea;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins;
}

.navbarcontainer2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section2img:hover {
  cursor: pointer;
}

.section2img {
  width: 62px;
  height: 62px;
}

.section2item1 {
  color: #039147;
  font-size: 28px;
  font-weight: 500;
  font-family: Poppins;
}
.section2item1 a {
  text-decoration: none;
  color: #039147;
  font-size: 28px;
  font-weight: 500;
  font-family: Poppins;
}

.section2item2 {
  width: 50%;
  height: 100%;
  padding: 2px 40px 2px 30px;
  background: white;
  border-radius: 6px;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.section2button1 {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.section2img1 {
  width: 35px;
  height: 35px;
}

.section2button2 {
  padding: 8px 20px;
  background: #039147;
  border-radius: 100px;
  color: white;
  font-family: Poppins;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.section2button3 {
  padding: 6px 20px;
  background: #f2f4ea;
  border-radius: 100px;
  border: 2px #039147 solid;
  color: #039147;
  font-size: 16px;
  font-family: Poppins;
  cursor: pointer;
}

.section2input {
  width: 100%;
  height: 100%;
  background: white;
  color: #7e7e7e;
  font-weight: 500;
  font-family: Poppins;
  border: none;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  font-size: 20px;
}

.section2input::placeholder {
  color: #7e7e7e;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
}

.navbarcontainer3 {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.navbarseperator {
  height: 1px;
  background-color: #1f4d36;
  border: none;
}

.section2item3 {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.section2item4 {
  color: #039147;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
  text-decoration: none;
}

.indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  border-radius: 6px;
  background-color: #039147;
  transition: left 0.3s, width 0.3s;
}

@media screen and (max-width: 1285px) {
  .section2item4 {
    color: #039147;
    font-size: 1.5vw;
    font-weight: 500;
    font-family: Poppins;
    text-decoration: none;
  }

  .section2button2 {
    padding: 8px 20px;
    background: #039147;
    border-radius: 100px;
    color: white;
    font-family: Poppins;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .section2button3 {
    padding: 6px 20px;
    background: #f2f4ea;
    border-radius: 100px;
    border: 2px #039147 solid;
    color: #039147;
    font-size: 14px;
    font-family: Poppins;
    cursor: pointer;
  }

  .section2input::placeholder {
    color: #7e7e7e;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
  }

  .section2item2 {
    width: 50%;
    height: 100%;
    padding: 2px 10px 2px 10px;
    background: white;
    border-radius: 6px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
  }
}
