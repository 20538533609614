.input-block {
    margin-bottom: 20px;
    text-align: center; /* Center the content within .input-block */
      
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
    text-align: left; /* Align the text to the left within the label */
  
   
  }
  
  input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #D9D9D9;
    border-radius: 5px;
    border:none
    
  }
  
  .btn-login {
    color: white;
    font-size: 20px;
    min-height: 40px;
    min-width: 117px;
    font-family: Poppins;
    font-weight: 500;
    border: none;
    padding: 5px 30px;
    background: #039147;
    border-radius: 8px;
    cursor: pointer;
    display: flex; /* Added display: flex */
    justify-content: center; /* Added justify-content: center */
    align-items: center; /* Added align-items: center */
    position: relative; /* Added position: relative */
    margin: auto; 
}


  
  
  .forgot-password {
    color: #7E7E7E;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    /* font-weight: bold; */
    text-align: right; 
  }
  .forgot-password:hover {
    color: #039147; /* Change the color on hover */
  }
  
  .loginwith{
    width: 100%; 
    text-align: center; 
    color: #7E7E7E; 
    font-size: 14px; 
    font-family: Poppins; 
    font-weight: 500; 
    line-height: 29px;
     word-wrap: break-word
  }
  .logoimagecontainer{
    display: flex;
    justify-content: center;
  
  }
  .loginimageitem {
    margin: 8px;
    width: 45px;
    height: 46px;
  border: 1px solid #45a049;
  border-radius: 100%;
  padding: 10px;
  }
  
  