.rootcontainer{
    justify-content: center;
}

.gridcontainer{
    display: grid;
    /* align-items: center; */
    /* justify-content: space-around; */
}

.griditem{
    display: flex;
    align-items: center; 
    justify-content: space-between;
}

.griditem2{
    display: flex;
    align-items: center;   
}

.griditem3{
    display: flex;
    align-items: start;   
}

.imagesize{
    width: 120px;
    border-radius: 100px;
}

.name{
    font-size: 36px;
    font-weight: 500;
}

.about{
    color: #7E7E7E;
    font-size: 18px;
    font-weight: 600;
}

.specialization{
    padding: 5px 10px 5px 10px;
    background: #EEEEEE;
    border-radius: 100px;
    border: 1px black solid;
    display: inline-flex;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    margin-right: 10px;
}

.imagesize2{
    width: 20px;
}

.gridcontainer2{
    display: flex;
    justify-content: space-between;
}

.gridcontainer3{
    display: grid;
    align-items: end;
    justify-content: space-between;
    gap: 25px;
}

.textdetail{
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 1000px) {
    .parentcontainer{
        display: grid;
        justify-content: center;
        gap: 10px;
    }   

    .gridcontainer{
        display: grid;
        align-items: center;
        justify-content: center;
    }

    .griditem{
        display: flex;
        align-items: center; 
        justify-content: center;
    }

    .imagesize{
        width: 180px;
        border-radius: 100px;
    }

    .gridcontainer2{
        display: grid;
        place-items: center;
        gap: 20px;
    }

    .specialization{
        padding: 5px 10px 5px 10px;
        background: #EEEEEE;
        border-radius: 100px;
        border: 1px black solid;
        display: grid;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        margin-top: 10px;
        margin-right: 10px;
        white-space: nowrap;
    }

    
}

@media (max-width: 500px) {
    .about{
        color: #7E7E7E;
        font-size: 18px;
        font-weight: 600;
        display: grid;
        place-items: center;
    }

    .griditem3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .specialization{
        border: 1px #EEEEEE solid;
    }
}
