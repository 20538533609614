.onlinecontainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.appointmentbutton{
    padding: 10px 50px;
    background: #FDBA13;
    border-radius: 100px;
    border: 2px #0E0E0E solid;
    place-items: center;
    display: grid;
    color: black;
    text-transform: none;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
}

.appointmenttext{
    text-align: center;
    font-size: 55px;
    font-weight: 600;
}

.logoimage {
    width: 300px;
    height: 200px; 
  
  }
  
.onlinecontainer2{
    display: grid;
    place-items: center;
   
}

  


.surgerytext{
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.surgerycaption{
    text-align: center;
    font-size: 18px;
    font-weight: 300;   
}

.readmore{
    font-size: 16px;
    color: #039147;
    border: 1px solid #f3f5ea;
    background: #f3f5ea;
    cursor: pointer;
}

.pagebutton{
    border: none;
    cursor: pointer;
    margin-left: 8px;
    font-size: 15px;
}



.pagebutton.active {
  
    color: #4caf50;;
  }


  @media screen and (max-width: 600px) {
    .onlinecontainer {
        display: none;
    }
    
    .onlinecontainer2 {
        display: none;
    }
}
