@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

.selectlabroot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.selectlabroot2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectlabelrootsm {
  display: flex;
  justify-content: space-between;
}

.selectlabitem {
  font-family: Montserrat;
  font-size: 35px;
  font-weight: 600;
}

.selectlabdaysitem {
  display: flex;
  justify-content: space-evenly;
}

.selectlabdaysgrid {
  padding: 5px 35px 5px 35px;
  background: white;
  border-radius: 100px;
  border: 1px solid white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  text-transform: none;
  color: black;
  line-height: 15px;
  cursor: pointer;
  gap: 5px;
}

.selectlabdays-container {
  display: flex;
  overflow-x: scroll;
  gap: 8px;
}

.selectlabdays-container::-webkit-scrollbar {
  width: 0px;
}

.selectlabdays-container::-webkit-scrollbar-thumb {
  background-color: #f3f5ea;
  border-radius: 6px;
}

.selectlabdays-container::-webkit-scrollbar-track {
  background-color: #f3f5ea;
}

.selectlabselected22 {
  background-color: #039147;
  color: white;
}

.selectlabdatename {
  text-align: center;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
}

.selectlabdate {
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 500;
}

.selectlabtestselect {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectlabtestselect1 {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.selectlabtestselect1 .selectlabtest1 {
  width: 100%;
  height: 50px;
  padding: 10px;
  background: white;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.selectlabtest1 .selectlabinput {
  width: 100%;
  height: 100%;
  background: white;
  color: #6d6d6d;
  font-weight: 500;
  font-family: Montserrat;
  border: none;
  outline: none;
  /* padding: 8px; */
  box-sizing: border-box;
  font-size: 20px;
}

.pharmaciestimings:disabled{
  opacity: 80%;
  cursor: not-allowed;
}

.selectlabinput::placeholder {
  color: #b1b1b1;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.selectlabimg1 {
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.selectlabtestselect2 {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #6d6d6d;
  cursor: pointer;
}

.selectlabtestselect2:hover {
  color: #039147;
}

.selectlabimg2 {
  width: 35px;
  height: 35px;
  pointer-events: none;
}

.selectlabimg2:hover{
  filter: brightness(0) saturate(100%) invert(33%) sepia(39%) saturate(6829%) hue-rotate(139deg) brightness(93%) contrast(98%);
}

.selectlabtestselect2:hover .selectlabimg2 {
  filter: brightness(0) saturate(100%) invert(33%) sepia(39%) saturate(6829%) hue-rotate(139deg) brightness(93%) contrast(98%);
}

.selectlabarrow {
  border: 1px solid #f3f5ea;
  background: #f3f5ea;
  cursor: pointer;
}

.selectlabarrow2 {
  border: 1px solid #f3f5ea;
  background: #f3f5ea;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  display: grid;
  place-items: center;
  color: #212B36;
  margin-right: 8px;
}

.selectlabimgsize1 {
  width: 30px;
  pointer-events: none;
}

.selectlabcontainer {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.selectlabpharmacy {
  height: 600px;
  overflow-y: scroll;
  overflow-x: scroll;
  direction: rtl;
}

.selectlabpharmacy::-webkit-scrollbar {
  width: 12px;
}

.selectlabpharmacy::-webkit-scrollbar-thumb {
  background-color: #039147;
  border-radius: 100px;
}

.selectlabpharmacy::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

.selectlabpharmacy::-webkit-scrollbar-thumb:horizontal {
  background-color: #f3f5ea;
}

.selectlabpharmacy::-webkit-scrollbar-track:horizontal {
  background-color: #f3f5ea;
}

.selectlabselected22 {
  background-color: #039147;
  color: white;
}

.selectlabpharmacy1 {
  direction: ltr;
  /* overflow: hidden; */
}

@media screen and (max-width: 985px) {
  .selectlabpharmacy {
    height: 600px;
    overflow: scroll;
    direction: ltr;
  }

  .selectlabpharmacy::-webkit-scrollbar {
    width: 0px;
  }

  .selectlabpharmacy::-webkit-scrollbar-thumb:horizontal {
    background-color: #f3f5ea;
  }

  .selectlabpharmacy::-webkit-scrollbar-track:horizontal {
    background-color: #f3f5ea;
  }
}
