@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");

.personclass {
  height: auto;
  width: 100vw;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
.Mui-checked .MuiSvgIcon-root {
  color: green;
  font-size: xx-large;
}
.selecth22 {
  display: grid;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}
.selecth22 span {
  display: grid;

  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #7e7e7e;
}
.select12 {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #f2f4ea;
  gap: 20px;
}
.selectcard1 {
  height: 189px;
  width: 1093px;
  margin: 0px 0px 0px 15px;
  border-radius: 8px;
  padding: 30px;
}
.grid12 {
  display: flex;
  justify-content: flex-end;
}
.profileimg22 {
  height: 47px;
  width: 47px;
  border-radius: 50%;
  object-fit: cover;
}
.selectheadinh3 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.button00 {
  font-family: Poppins;
}
.selectheadinh3 span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}
.radiogrid {
  display: flex;
  justify-content: flex-end;
}
.spacebutton {
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 100%;
}
.grid13 {
  display: flex;
  justify-content: flex-end;
}
.grid15 {
  display: flex;
  justify-content: center;
}
.grid55 {
  display: flex;
  justify-content: center;
}
.previous {
  padding: 0.3rem 8vw;
  background: white;
  border-radius: 100px;
  border: 2px #039147 solid;
  place-items: center;
  display: grid;
  color: #039147;
  text-transform: none;
  font-family: inherit;
  font-size: 22px;
  cursor: pointer;
}
.previous:hover {
  color: white;
  border: 1px solid #039147;
  background: #039147;
}
.next {
  padding: 0.3rem 8.5vw;
  background: #039147;
  border-radius: 100px;
  border: 2px #039147 solid;
  place-items: center;
  display: grid;
  color: white;
  text-transform: none;
  font-family: inherit;
  font-size: 22px;
  cursor: pointer;
}
.next:hover {
  color: #039147;
  border: 1px solid #039147;
  background: white;
}
@media (max-width: 1050px) {
  .selectcard1 {
    height: 189px;
    width: 893px;
    margin: 40px 0px 0px 15px;
    border-radius: 8px;
    padding: 30px;
  }
  .grid12 {
    display: flex;
    justify-content: flex-end;
  }
  .selectheadinh3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .button00 {
    font-family: Poppins;
  }
  .selectheadinh3 span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
  }
}
@media (max-width: 900px) {
  .selectheadinh3 {
    margin-left: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .button00 {
    font-family: Poppins;
  }
  .selectheadinh3 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
  }
  .selectcard1 {
    height: auto;
    width: 100vw;
    margin: 20px 0px 0px 15px;
    border-radius: 8px;
  }
  .selecth22 {
    font-family: Poppins;
    margin: 0px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
  .selecth22 span {
    display: none;
  }

  .grid11 {
    display: flex;
    justify-content: flex-start;
  }

  .grid12 {
    display: flex;
    justify-content: flex-end;
  }
  .grid13 {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .grid14 {
    display: flex;
    justify-content: flex-start;
  }
  .grid15 {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }

  .spacebutton {
    display: none;
  }
}
@media (max-width: 380px) {
  .selectheadinh3 {
    margin-left: 20px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .selectheadinh3 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
  }
  .selectcard1 {
    /* display: flex; */
    height: auto;
    width: 100vw;
    justify-content: center;
    margin: 10px 0px 0px 10px;

    border-radius: 8px;
  }
  .selecth22 {
    font-family: Poppins;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }

  .select12 {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 380px) {
  .selectheadinh3 {
    margin-left: 30px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .selectheadinh3 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
  }
}
