@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.intractive-a {
  width: 100%;  
  height: 100%;  
  display: flex;
  justify-content: center;
}
.intractive-b {
/* width: 69%; */
width: 1164px;
height: 527px;
/* border: 2px solid red; */
}
.intractive-c {
width: 651px;
height: 38px;
margin-top: 5%;
margin-left: 5%;
/* border: 2px solid red; */
}
.intractive-d {
    font-family: Poppins;
    font-weight: 600;
   
    line-height: 38px;
    font-size: 40px;
    text-align: left;
}
.intractive-d1{
    display: flex;
     flex-direction: row;
      position: relative;
}
.intractive-e {
    width: 651px;
    height: 224px;
    margin: 5%;
    /* border: 2px solid red; */
}
.intractive-f {
    font-size: 24px;
    font-family: Poppins;
    color: #000000;
    font-weight: 500;
    word-wrap: inherit;
   
    line-height: 38px;
    text-align: left;
  
}
.intractive-g {
    font-size: 16px;
     color: #000000;
}
.intractive-h {
    position: absolute;
    right: -5%;
     top: -20%;
}
.intractive-i {
    width: 454px;
     height: 431px;
}
.intractive-j {
    width: 229px;
     height: 57px;
      background-color: #D9D9D9;
      margin-left: 20%;
}
.intractive-k {
    cursor: pointer;
    width: 229px;
     height: 57px;
      color: #0E0E0E;
       font-family: Poppins;
        font-size: 20px;
         line-height: 30px;
         text-align: center;
          text-transform: none;
          border: 2px solid #000000;
          border-radius: 8px;
}

@media screen and (max-width: 1250px) { 
    .intractive-c {
        width: 400px;
        height: 30px;
        margin-top: 5%;
        margin-left: 15%;
        /* border: 2px solid red; */
        }
        .intractive-d {
            font-family: Poppins;
            font-weight: 600;
           
            line-height: 38px;
            font-size: 30px;
            text-align: left;
        }
        .intractive-d1{
            display: flex;
             flex-direction: row;
              position: relative;
        }
        .intractive-e {
            width: 400px;
            height: 224px;
            margin-top: 2%;
        margin-left: 15%;
            /* border: 2px solid red; */
        }
        .intractive-f {
            font-size: 20px;
            font-family: Poppins;
            color: #000000;
            font-weight: 500;
            word-wrap: inherit;
           
            line-height: 38px;
            text-align: left;
          
        }
        .intractive-g {
            font-size: 16px;
             color: #000000;
        }
        .intractive-h {
            position: absolute;
            right: 12%;
             top: -20%;
        }
        .intractive-i {
            width: 350px;
             height: 300px;
        }
        .intractive-j {
            width: 229px;
             height: 57px;
             /* border-radius: 8px;
             border: 2px solid #000000; */
              background-color: #D9D9D9;
              margin-left: 20%;
        }
        .intractive-k {
            cursor: pointer;
            width: 229px;
             height: 57px;
              color: #0E0E0E;
               font-family: Poppins;
                font-size: 20px;
                 line-height: 30px;
                 text-align: center;
                  text-transform: none;
                  border: 2px solid #000000;
                  border-radius: 8px;
        }
  }
  @media screen and (max-width: 1000px) { 
    .intractive-c {
        width: 400px;
        height: 30px;
        margin-top: 5%;
        margin-left: 15%;
        /* border: 2px solid red; */
        }
        .intractive-d {
            font-family: Poppins;
            font-weight: 600;
           
            line-height: 38px;
            font-size: 24px;
            text-align: left;
        }
        .intractive-d1{
            display: flex;
             flex-direction: row;
              position: relative;
        }
        .intractive-e {
            width: 400px;
            height: 190px;
            margin-top: 2%;
        margin-left: 15%;
            /* border: 2px solid red; */
        }
        .intractive-f {
            font-size: 20px;
            font-family: Poppins;
            color: #000000;
            font-weight: 500;
            word-wrap: inherit;
           
            line-height: 38px;
            text-align: left;
          
        }
        .intractive-g {
            font-size: 16px;
             color: #000000;
        }
        .intractive-h {
            position: absolute;
            right: 5%;
             top: -10%;
        }
        .intractive-i {
            width: 300px;
             height: 250px;
        }
        .intractive-j {
            width: 229px;
             height: 57px;
             /* border-radius: 8px;
             border: 2px solid #000000; */
              background-color: #D9D9D9;
              margin-left: 20%;
              margin-top: 10%;
        }
        .intractive-k {
            cursor: pointer;
            width: 229px;
             height: 57px;
              color: #0E0E0E;
               font-family: Poppins;
                font-size: 20px;
                 line-height: 30px;
                 text-align: center;
                  text-transform: none;
                  border: 2px solid #000000;
                  border-radius: 8px;
        }
  }
  
@media screen and (max-width: 750px) {
    .intractive-b {
        width: auto;
        height: auto;
      }
      
      .intractive-c {
        width: 305px;
        height: 38px;
        margin-top: 5%;
        margin-left: 5%;
        /* border: 2px solid red; */
        }
      .intractive-d {
        font-family: Poppins;
        font-weight: 600;
       margin-right: 10%;
        line-height: 38px;
        font-size: 24px;
        text-align: center;
    }
    .intractive-d1 {
      
        flex-direction: column;
    }
    .intractive-e {
        order: 3;
        width: 305px;
        height: 224px;
        margin-top: 80%;
        margin-left: 1%;
      }
  
    .intractive-f {
      order: 1;
      font-size: 16px;
      font-family: Poppins;
      color: #000000;
      font-weight: 500;
      word-wrap: inherit;
     
      line-height: 24px;
      text-align: center;
    }
    .intractive-h {
        order: 2;
        position: absolute;
        right: 25%;
        top: 15%;
    }
   
  
   
    .intractive-i {
        width: 151px;
         height: 156px;
    }
    .intractive-j {
    width: 188px;
    height: 45px;
        margin-left: 17%;
      }
      .intractive-k {
        cursor: pointer;
        width: 188px;
         height: 45px;
          color: #0E0E0E;
           font-family: Poppins;
            font-size: 16px;
             line-height: 24px;
             text-align: center;
              text-transform: none;
              border: 2px solid #000000;
              border-radius: 8px;
    }
  }
  