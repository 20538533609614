.medicinecategoryitem{
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 500;
}

.medicinecategorycontainer{
    display: flex;
    justify-content: center;
}

.medicinecategoryitem2{
    width: 90%;
    height: 70px;
    padding: 0px 40px 0px 30px;
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    overflow: hidden;
}

.medicinecategoryinput {
    width: 100%;
    height: 100%;
    background: white;
    color: #7E7E7E;
    font-weight: 500;
    font-family: Poppins;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 20px;
}

.medicinecategoryinput::placeholder{
    color: #7E7E7E;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
}

.medicinecategorybutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.medicinecategorycontainer2{
    display: grid;
    place-items: center;
    width: 100%;
}

.medicinecategorycontainer3{
    display: grid;
    place-items: center;
    cursor: pointer;
    position: relative; 
}

.medicinecategoryimg {
    width: 220px;
    height: 220px;
    border-radius: 100%;
    object-fit: cover;
    border: 3px solid transparent; 
    transition: border-color 0.3s ease; 
  }

.medicinecategorytext{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.medicinecategoryitem3{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.medicinecategoryimg.selected {
    border-color: #39a84f; 
}
  
.greenTick {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #39a84f;
    font-size: 30px;
    display: none; 
}
  
.medicinecategorycontainer3.selected .greenTick {
    display: block; 
}

@media screen and (max-width: 560px) {
    .medicinecategoryitem{
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        display: grid;
        justify-content: center;
    }

    .medicinecategoryitem3{
        justify-content: center;
    }

    .medicinecategoryitem2{
        height: 50px;
    }

    .medicinecategoryimg {
        width: 180px;
        height: 180px;
    }

    .medicinecategoryinput {
        font-size: 18px;
    }
    
    .medicinecategoryinput::placeholder{
        font-size: 18px;
    }

    .medicinecategoryimg1{
        width: 30px;
        height: 30px;
    }

    .medicinecategorytext{
        font-size: 20px;
    }

    .medicinecategoryitem2{
        border: 2px solid #39a84f;
        padding: 0px 10px 0px 30px;
    }
}