@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');


.provider-container {
  width: 100%;
  height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 80px;
    border-radius: 8px;
    background-color: #f2f4ea;
  }
  .container-a{
    width: 100%;
  height: auto;
    border: 2px solid #039147;
    padding: 30px 30px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items:flex-start;
  }

  .provider-heading {
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    line-height: 41px;
    font-weight: 600;
    margin: 0px;
 
 
  }
 
.card-a {
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: 33px;
    background-color: white;
  }  
 
  .heading {
    text-align: center;
    margin-bottom: 10px;
  }
  .image-container-1 {
    /* height: 80px;
    width: 89px; */
    display: flex;
  	position: relative;
	width: 78.75px;
	height: 75px;
	background: #BCD63A;
	clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
    justify-content: center;
    align-items: center;

  } 
  .image-container-1c {
    /* height: 88px;
    width: 152px;
    display: flex;
    border-radius: 50%;
    background-color: #039147;
    justify-content: center;
    align-items: center; */
    width: 152px;
    height: 80px;
    border-radius: 0 90px;
    background-color: #FCB831;
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
    display: flex;
  }  
  .image-container-1a {
    height: 80px;
    width: 89px;
    display: flex;
    border-radius: 40%;
    background-color:  #336BB7;
    justify-content: center;
    align-items: center;

  }  
  .image-container-1b {
    height: 80px;
    width: 89px;
    display: flex;
    justify-content: center;
    align-items: center;

  } 
  .image-a1 {
    max-width: 55px;
    max-height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-a2 {
    max-width: 55px;
    max-height: 39px;
 position: absolute;
    display: flex;
  
  }
  .description-a1 {
    text-align: center;
    margin-top: 20px;
    
    font-family: Poppins;
    font-size: 24px;
    line-height: 38px;
    font-weight: 500px;
   
  }
 
  .providerimage{
    height: 39px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  @media (max-width: 650px) {
   
    .provider-heading {
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      line-height: 38px;
      font-weight: 600;
      
    }
   
   
    .description-a1 {
      width: 200px;
      height: 38px;
      text-align: center;
      margin-top: -2px;
      color: #000000;
      font-family: Poppins;
      /* white-space: nowrap; */
   
 /* margin-left: -42%; */
      font-size: 16px;
      line-height: 38px;
      font-weight: 500px;
      /* border: 2px solid red; */
      margin-right: 12%;
      text-align: center;
     
    }
 }
 @media (max-width: 385px) {
 
  .provider-heading {
    
    text-align: center;
    font-family: Montserrat;
    font-size: 22px;
    line-height: 38px;
    font-weight: 600;
    
  }

 
  .description-a1 {
    width: 251px;
    height: 38px;
    text-align: center;
    margin-top: -2px;
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
    text-align: center;
   
  }
}

@media (max-width: 360px) {
 
  .provider-heading {
    
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 38px;
    font-weight: 600;
    
  }

 
  .description-a1 {
    width: auto;
    height: auto;
    text-align: center;
    margin-top: -2px;
    color: #000000;
    font-family: Poppins;
    font-size: 10px;
    line-height: 38px;
    font-weight: 500;
    text-align: center;
   
  }
}



.hexagon {
  /* Specify the desired width and height */
  width: 100px;
  height: 51px;/* Maintain the aspect ratio (width:height = 1.732:1) */

  /* Rest of your styles remain the same */
  background: #43A363;
  position: relative;
  transition: background-color .3s linear, opacity .3s linear;


  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #43A363;
 
  }

  &:before {
    transform: rotate(60deg);
  }
  &:after {
    transform: rotate(-60deg);
  }
}

