.videoCallvideo {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  
  .videoCallvideo2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .videoCallvideo3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .videoCallinfo {
    display: flex;
    align-items: center;
    color: #7b809a;
    font-size: 14px;
    font-weight: bold;
  }
  
  .videoCallid {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .videoCallrec {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .videoContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative; 
  }
  
  .videoItem {
    flex: 1;
    max-width: 100%;
    border-radius: 10px;
  }

  .videoItemLanding {
    border-radius: 10px;
    width: 100%;
  }
  
  .nameOverlay {
    position: relative;
    bottom: 35px; 
    left: 15px;   
    color: white; 
    padding: 5px 10px;
    font-size: 16px; 
    font-weight: bold; 
  }  
  
  .videoCallicon {
    transform: scale(1.5);
  }
  
  .videoCallicon2 {
    transform: scale(2);
  }
  
  .chatSidebar {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    background: #ffffff;
    border-left: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    box-shadow: -4px 0 6px rgba(0, 0, 0, 0.1);
  }
  
  .chatHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .chatMessages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  .chatMessage {
    margin-bottom: 8px;
  }
  
  .chatInput {
    display: flex;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #ddd;
  }
  
  .chatInput input {
    flex: 1;
    margin-right: 8px;
  }

  .copied {
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .copied.show {
    opacity: 1;
  }
  
  @media (max-width: 561px) {
    .videoCallvideo3 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .videoCallinfo {
      display: flex;
      align-items: center;
      color: #7b809a;
      font-size: 10px;
      font-weight: bold;
    }
  }