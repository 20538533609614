@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');
@import url(https://db.onlinewebfonts.com/c/b719b6475a23d77fe0e7ef0f93486e3a?family=Post+No+Bills+Jaffna+Bold);
@import url(https://db.onlinewebfonts.com/c/f56cafe2fac8a4944cbe94d250fd52dc?family=Post+No+Bills+Jaffna+Medium);
 
.sellers-medharwa-singup-lap-a {
    display:flex;
    justify-content:center;
}
.sellers-medharwa-singup-lap-b {
    width:518px;
    min-height:400px;
    margin: 30px 0px 30px 0px;
    padding: 0px;
     background-color:#FFFFFF;
     border-radius: 8px;
     /* overflow:auto; */
     border: 2px solid #039147;
}
.sellers-medharwa-singup-lap-c {
    display:flex;
    justify-content:center;
}
.sellers-medharwa-singup-lap-d {
    width: 262px;
    height: 100px;
    flex-direction: row;
    /* border: 2px solid red; */
    margin-top: 8%;
}

.sellers-heading-a-m {
font-family: Poppins;
font-size: 30px;
line-height: 47.84px;
font-weight: 600;
color: black;
}
.sellers-heading-a-mn {
    font-family:"Post No Bills Jaffna Medium";
    font-size: 32px;
    line-height: 47.84px;
    font-weight: 400;
    color: #3FC08A;
}
.sellers-heading-a-mno {
font-size: 32px;
line-height: 42px;
font-weight: 700;
color: #3FC08A;
font-family: "Post No Bills Jaffna Bold";

}




.SignupAgeantdiv2 {
    width: 100%;
    height: 67px;
   margin-left: 5%;
}
 
 
 

 
 
 
/* -- MAIN CONTAINER -- */
.select-wrapper {
 
    border-radius: 0.5em;
    min-width: 17em;
    color: #fff;
 
}
 
.select-wrapper .select-btn,
.select-wrapper .options li {
    display: flex;
    align-items: center;
    height: 3em;
    cursor: pointer;
}
 
.select-wrapper .select-btn {
    /* -- Select button -- */
    max-width: 87%;
    margin-left: 2%;
    height: 67px;
    background-color: #039147;
    justify-content: space-between;
    padding: 0 1em;
    border-radius: 0.5em;
    font-size: 1em;
}
 
.select-wrapper .select-btn:hover {
    background-color: #039147;
}
 
.select-wrapper .select-btn ion-icon {
    font-size: 1.35em;
    color: #FFFFFF;
    transition: transform 0.2s linear;
}
 
.select-wrapper.active .select-btn {
    background-color: #039147;
}
 
.select-wrapper.active .select-btn ion-icon {
    transform: rotate(-180deg);
    transform-origin: center;
    transition: transform 0.2s linear;
}
 
.select-wrapper.active .content {
    display: block;
}
 
.select-wrapper .content {
   
    background-color: #FFFFFF;
    max-width: 88%;
    margin-left: 2%;
    display: none;
    margin-top: 0.5em;
    padding: 0em;
    border-radius: 0.5em;
    position: relative;
}
 
.select-wrapper .content .options {
 
    max-height: 12em;
    color: #000000;
    overflow-y: auto;
    padding-right: 0.5em;
}
 
.select-wrapper .content .options::-webkit-scrollbar {
    width: 7px;
}
 
.select-wrapper .content .options::-webkit-scrollbar-track {
    background-color: #b3b3b3;
    border-radius: 25px;
}
 
.select-wrapper .content .options::-webkit-scrollbar-thumb {
    background-color: #8a8a8a;
    border-radius: 25px;
}
 
.select-wrapper .content .options li {
    font-size: 1em;
    height: 67px;
    padding-left: 1em;
    border-radius: 0.5em;
}
 
.select-wrapper .content .options li.selected {
    background-color: #2c2c2c;
}
 
.SignupAgeantdiv2 .select-wrapper .content .options li:hover {
    background-color: #039147;
    color: #FFFFFF;
   
}

