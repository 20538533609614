@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.pharmaciesspaceitem{
    background: white;
    border-radius: 10px;
    padding: 10px;
    /* width: 749px; */
    width: 850px;
    /* height: 295px; */
}

.pharmaciesspaceitem2{
    display: flex;
}

.pharmaciesstartitem{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.pharmaciesRadio {
    -webkit-appearance: none;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    outline: none;
    border: 3.5px solid green;
    cursor: pointer;
    background-color: white;
}
  
.pharmaciesRadio:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    /* margin: 20% auto; */
    border-radius: 50%;
}
  
.pharmaciesRadio:checked:before {
    background: green;
}
  
.pharmaciesRadio:checked {
    border-color: green;
}

.pharmaciesnumber{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
}

.pharmaciesdiscountedprice{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
}

.pharmaciesprice{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    color: #7E7E7E;
}

.pharmaciesdiscount{
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    color: #BCD63A;
}

.pharmaciesslotsheading{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pharmaciesslotsheading:hover{
    color: #039147;
}

.pharmacieslotitem{
    overflow-y: scroll;
    /* height: 150px; */
}

.pharmacieslotitem::-webkit-scrollbar {
    width: 0px;
}

.pharmaciestimings{
    padding: 7px 20px 7px 20px;
    background: #F2F4EA;
    border-radius: 100px;
    border: 1px solid #F2F4EA;
    display: inline-flex;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-right: 10px;
    font-weight: 500;
    text-transform: none;
    color: black;
    cursor: pointer;
}

.selected-timing{
    background-color: #039147;
    color: white;
}

.pharmaciesspaceitemnoslot{
    background: white;
    border-radius: 10px;
    padding: 10px;
    width: 749px;
    height: 295px;
    display: grid;
    place-items: center;
}

.pharmacyitemprice{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pharmacyitemprice2{
    display: flex;
    align-items: center;
}