.appointmentcontainer{
    align-items: flex-start;
    display: grid;
}

.appointmentlabel{
    font-size: 24px;
    font-weight: 500;
}

.appointmentgrid{
    justify-content: end;
    align-items: center;
    display: flex;
}

.appointmentitem{
    column-gap: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.imgsize1{
    width: 30px;
}

.monthYearString{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.arrow{
    border: 1px solid #f3f5ea;
    background: #f3f5ea;
    cursor: pointer;
}

.daysgrid{
    padding: 5px 25px 5px 25px;
    background: white;
    border-radius: 100px;
    border: 1px solid white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    text-transform: none;
    color: black;
    line-height: 15px;
    cursor: pointer;
}

.datename{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.date{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.daysitem{
    display: flex;
    justify-content: space-evenly;    
}

.morning{
    font-size: 20px;
    font-weight: 600;
}

.startitem{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.spaceitem{
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 10px;
    padding: 10px;
}

.spaceitem2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    box-sizing: border-box;  
    padding-right: 20px;  
}


.buttonstyle1{
    padding: 2px 30px 2px 30px;
    background: #FDBA13;
    border-radius: 100px;
    border: 2px #0E0E0E solid;
    place-items: center;
    display: grid;
    color: #0E0E0E;
    text-transform: none;
    font-family: inherit;
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;
}

.buttonhover:hover {
    background: inherit;
}

.imgsize3{
    width: 22px;
}

.timings{
    padding: 7px 20px 7px 20px;
    background: #F2F4EA;
    border-radius: 100px;
    border: 1px solid #F2F4EA;
    display: inline-flex;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-right: 10px;
    font-weight: 500;
    text-transform: none;
    color: black;
    cursor: pointer;
}

.days-container {
    display: flex;
    overflow-x: scroll;
}


.days-container::-webkit-scrollbar {
    width: 0px; 
}

.days-container::-webkit-scrollbar-thumb {
    background-color: #F2F4EA; 
    border-radius: 6px;
}

.days-container::-webkit-scrollbar-track {
    background-color: #F3F5EA; 
}
.selected22 {
    background-color: #039147;
    color: white;
  }
  .timings.disabled {
    background-color: #d3d3d3; /* disabled background */
    color: #999; /* disabled text color */
    cursor: not-allowed;
  }