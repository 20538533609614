.card-main {
    height: 320px;
}

.card-text {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #039147;
    /* margin-top: 20px; */
}

.card-process-1 {
    /* width: 436px; */
    height: 29.6px;
    top: 94.5px;
    left: 75px;
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 60px;
    line-height: 20px;
}

.card-process-2 {
    height: 29.6px;
    top: 94.5px;
    left: 75px;
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 10px;
    line-height: 20px;
}

.card-process-3 {
    height: 29.6px;
    top: 94.5px;
    left: 75px;
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 60px;
    text-decoration: solid;
    line-height: 20px;
}



.text-name {
    font-size: 18px;
    color: #000000;
  
    justify-content: space-between;
}

.registration-text {
    width: 150px;
    margin-top: 7%;
    font-size: 14px;
    margin-top: 15px;
}


@media screen and (max-width: 768px) {
    .card-main {
        height: auto; 
    }
    .card-process-1 {
        flex-direction: column; 
        gap: 20px; 
        display: inline;
    }
    .card-process-2 {
        flex-direction: column; 
        gap: 20px; 
        display: inline;
    }

    .card-process-3 {
        flex-direction: column; 
        gap: 20px; 
        display: inline;
    }
    .text-name{
        font-size: 16px; 
        display: inline;
    }

    .registration-text {
        width: auto; 
        margin-top: 10px; 
    }
}


@media screen and (max-width: 480px) {
    .card-text {
        font-size: 20px; 
        margin-top: 10px; 
    }

    .text-name {
        font-size: 16px; 
        display: inline;
        
    }

    .registration-text {
        margin-top: 5px; 
    }
}
