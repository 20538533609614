@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&family=Poppins:wght@500&family=Quicksand:wght@300&display=swap');

.profilenavbarimg{
    width: 60px;
}

.profilenavbarimg:hover{
    filter: invert(31%) sepia(77%) saturate(1647%) hue-rotate(128deg) brightness(94%) contrast(98%);
}

.profilenavbaritem:hover .profilenavbarimg {
    filter: invert(31%) sepia(77%) saturate(1647%) hue-rotate(128deg) brightness(94%) contrast(98%);
}

.profilenavbaritem:hover{
    cursor: pointer;
    color: #039147;
    transform: scale(1.01);
    font-weight: 600;
}

.profilenavbaritem{
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    gap: 10px;
}

.profilenavbaritem2{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #7E7E7E;
    font-family: Poppins;
}
.authText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }