/* 
.footer{
    background-color: #318CE7;
    margin-bottom: -50px;
}
.sb_footer{
    display: flex;
    flex-direction: column;
}

.sb_footer-links{
    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.sb_footer-links_div{
    width:150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}


.center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
a{
    color:rgb(175,175,179);
    text-decoration: none;
}
.socialmedia{
    display: flex;
    flex-direction: row;
    margin: auto;
}
.socialmedia img{
    width:100%;
}
.sb_footer-links_div h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;


}
.sb_footer-links_div p{
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}
.sb__footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.sb__footer-below-links{
    display: flex;
    flex-direction: row;
}
.sb__footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color:white;
    font-weight: 600;
}
hr{
    color: black !important;
    width: 100%;
}
.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color:rgb(255,255,255);
    font-weight: 600;
}
@media screen and(max-width:850px){
    sb_footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    } 
}
@media screen and(max-width:550px){
    sb_footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    } 
    .sb_footer-links div{
        margin: 1rem 0;
    }
    .sb footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb__footer-below{
        flex-direction: column;
        justify-content: left;
    }
    .sb__footer-below-links{
        flex-direction: column;
    }
   .sb__footer-below-links p{
    margin-left: 0rem;
    margin-top: 1rem;
   }
   @media screen and (max-width:400px){
    .sb__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
    
   }
}
  
    
     /* body {
        margin: 0;
        padding: 0;
        height: 1000px;
    }
    .sb_footer{
        display: flex;
        flex-direction: column;
    }
   
    
    .footer {
        background-color: #013243;
        border-top: 2px solid black;
        position: fixed;
        width: 100%;
        bottom: 0;
        color: white;
        font-size: 25px;
    }
     */ 

.footer{
    background-color: #18447a;
    font-size: 16px;
    font-family: 'Montserrat', 'Quicksand', sans-serif;
}

.part1{
    color: white;
    display: grid;
}

.part2{
    color: white;
    display: grid;
}

.part2 a{
    color: white;
}

.part2 a:hover,
.part3 a:hover{
    color: #2591eb;
    transition: all .5s ease-in-out;
}

.part3{
    color: white;
    display: grid;
}

.mdicon{
    display: grid;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    place-items: center;
    transition: all .5s ease-in-out;
}

.mdicon:hover{
    background-color: #2591eb;
    color: white;
}

.telicon a,.emailicon a{
    color: white;
    margin-bottom: 10px;
}

.telicon,.emailicon{
    margin-bottom: 30px;
}

.phoneenabledicon{
    transform: scale(1.5);
}

.locationicon{
    transform: scale(1.5);
    margin-right: 10px;
    margin-top: 30px;
}

.copyright{
    color: white;
    display: grid;
    justify-items: center;
}

.footer2{
    background-color: #14447a;
    border-top: 2px solid rgba(255, 255, 255, 0.1) ;
    font-size: 16px;
    font-family: 'Montserrat', 'Quicksand', sans-serif;
}

.location{
    display: flex;
}