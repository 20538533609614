@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');

.selectlabh2{
    width: 100vw;
    height: auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 58px;
}
.select4{
    padding: 20px ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: #f2f4ea;

}
.selectcard {
    height: 260px;
    width: 597px;
    margin: 40px 0px 0px 15px;
    border-radius: 8px;
    padding: 30px;
}
.selecth3{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.selecth3 span{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7E7E;
}
.spantext{
    text-decoration: line-through;
    text-decoration-color: black;
}
.profileimg{
    height: 144px;
    width: 200px;
    object-fit: cover;
}
.profileimg223{
    height: 15px;
    width: 28px;
    
    /* object-fit: cover; */
}
.grid388{
    height: 31px;
    width: 31px;
    
    object-fit: cover;
    display: flex;
    justify-content:space-around;
    align-items: center;
}
.selecth4{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.selecth4 span{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7E7E;
}
.spandiscount{
    color: #BCD63A;
}
.selecth44{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}
.grid38{
    display: flex;
    justify-content:space-around;
    /* align-items: center; */
}
.grid17{
    display: flex;
    justify-content:space-around;
    align-items: center;
}
.grid18{
    display: flex;
    justify-content: flex-start;
}
.button11   {
    font-family: 'Poppins', sans-serif;
}
.selecttextfield1{
    background-color: #ffffff;
    color: #767676;
    border-radius: 8px;
}
.select4 .searchlabtest1{
    width: 95%;
    height: 60px;
    padding: 0px 2vw 0px 2vw;
    background: white;
    border-radius: 6px;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}
.select4 .searchlabtestinput {
    width: 100%;
    height: 100%;
    background: white;
    margin-left: 25px; 
    color: #7E7E7E;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 20px;
}
.select4 .searchlabtestinput::placeholder{
    color: #7E7E7E;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.select4 .searchlabtestbutton1{
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.searchlabtestimg1{
    width: 30px;
    height: 30px;
}
.select4 .sortAndFilterContainer{
    display: none;
}
@media (max-width: 900px) {
    .selectlabh2{
        width: 100vw;
        height: auto;
        font-family: 'Montserrat', sans-serif;
        font-size: 35px;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    .grid38{
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
    .grid17{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .selecth4{
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .selecth4 span{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #7E7E7E;
    }
    .select4 .sortAndFilterContainer{
        display: none;
    }
}
@media (max-width: 600px) {
    .selectlabh2{
        width: 100vw;
        height: auto;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    .spandiscount{
        font-size: 10px;
        color: #4E9FCC;
    }
    .selectcard {
        height: 143px;
        width: 307px;
        margin: 40px 0px 0px 15px;
        border-radius: 8px;
        padding: 10px;
    }
    .select4 .searchlabtestinput {
        width: 100%;
        height: 100%;
        background: white;
        margin-left: 25px; 
        color: #7E7E7E;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        border: none;
        outline: none;
        padding: 8px;
        box-sizing: border-box;
        font-size: 20px;
    }
    .searchlabtestinput::placeholder{
        color: #7E7E7E;
        font-size: 24px;
        font-family: Poppins;
        font-weight: 500;
    }
    .searchlabtest1{
        width: 90%;
        height: 56px;
        padding: 0px 2vw 0px 2vw;
        background: white;
        border-radius: 6px;
        border: 1px solid white;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }
    .select4 .searchlabtest1{
        width: 95%;
        height: 50px;
        padding: 0px 0vw 0px 0vw;
        background: white;
        border-radius: 6px;
        border: 2px solid #039147;
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }
    .grid33{
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .selecth3{
        font-family: 'Poppins', sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
    }
    .selecth3 span{
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        color: #7E7E7E;
    }
    .profileimg{
        height: 56px;
        width: 78px;
        object-fit: cover;
    }
    .grid38{
        display: flex;
        justify-content: flex-end;
        /* align-items: center; */
    }
    .grid17{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .selecth4{
        width: 50px;
        text-align: center;
        margin-top: 20px;
        font-size: 11px;
        font-weight: 500;
        line-height: 15px;
    }
    .selecth4 span{
        
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        color: #7E7E7E;
    }
    .selecth44{
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
    }
    .grid18{
        display: flex;
        justify-content: flex-start;
        
    }
    /* .spandiscount11{
        margin-bottom: 90px;
    } */
    
    .select4 .sortAndFilterContainer{
        display: flex;
    }
}
@media (max-width: 300px) {
    .selectcard {
        height: auto;
        width: auto;
        margin: 40px 0px 0px 15px;
        border-radius: 8px;
        padding: 10px;
    }

}