
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');
@import url(https://db.onlinewebfonts.com/c/b719b6475a23d77fe0e7ef0f93486e3a?family=Post+No+Bills+Jaffna+Bold);
@import url(https://db.onlinewebfonts.com/c/f56cafe2fac8a4944cbe94d250fd52dc?family=Post+No+Bills+Jaffna+Medium);
.error-message-pharmacy{
    width: 84%;
    color: red;
   text-align: left;
 margin-left: 8%;
 font-size: small;
  /* border: 2px solid red; */
  }
  
.label-registraion-phramyicist22 {
    width: 90%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 5%;
   
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
  .input-text-area-phramyicist-logo22 {
    /* width: 433px; */
    width: 90%;
    height: 67px;
    padding-left: 20px;

   background-color: #D9D9D9;
   border: none;
   margin-left: 5%;

   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
   margin-right: 3%;
}
  .label-registraion-phramyicist112 {
    width: 84%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 5%;
   
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
.input-text-area-phramyicist-about11 {
    /* width: 433px; */
    width: 90%;
    
 padding: 15px;

    height: 133px;
   background-color: #D9D9D9;
   border: none;
   margin-left: 5%;

   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
}
.agent-medharwa-singup-lap-a {
    display:flex;
    justify-content:center;
}
.agent-medharwa-singup-lap-b {
    width:518px;
    min-height:780px;
    margin: 0px 0px 30px 0px;
    padding: 10px;
     background-color:#FFFFFF;
     border-radius: 8px;
     /* overflow:auto; */
     /* border: 2px solid #039147; */
}
.agent-medharwa-singup-lap-c {
    display:flex;
    justify-content:center;
}
.agent-medharwa-singup-lap-d {
    width: 262px;
    height: 100px;
    flex-direction: row;
    /* border: 2px solid red; */
    margin-top: 8%;
}
.agent-error{
    color: red;
    margin-left: 30px;
    font-size: small;
}
.agent-heading-a-m {
font-family: Poppins;
font-size: 30px;
line-height: 47.84px;
font-weight: 600;
color: black;
}
.ageant-agreePolicy{
    display: flex;
    /* width: 428px;
    height: 42px; */
    /* background-color: #039147; */
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    line-height: 12px;
}
.agent-heading-a-mn {
    font-family:"Post No Bills Jaffna Medium";
    font-size: 32px;
    line-height: 47.84px;
    font-weight: 400;
    color: #3FC08A;
}
.agent-heading-a-mno {
font-size: 32px;
line-height: 42px;
font-weight: 700;
color: #3FC08A;
font-family: "Post No Bills Jaffna Bold";

}


.agent-label-registraion {
    width: 90%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 5%;
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
.agent-input-text-area {
    /* width: 433px; */
    width: 90%;
    height: 67px;
    padding-left: 20px;
   background-color: #D9D9D9;
   border: none;
   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
    margin-left: 5%;
}
.agent-singup-aply-btn-doctor {


   padding: 10px 30px 10px 30px;
    color: white;
    background-color: #3FC08A;
   margin-top: 10%;
    cursor: pointer;
    font-size: 24px;
    text-align: Center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 36px;
    border: none;
    border-radius: 8px;
}
.agent-singup-aply-btn-doctor:hover{

    color: white;
    background-color: #039147;
 
}
.input-logo33 {
    position: absolute;
    top: 50%;
    left: 82%; /* Adjust as needed */
    transform: translateY(-50%);
    height: 38px;
    width: 38px;
   
  }

.label-registraion-phramyicist {
    width: 90%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 5%;
   
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
.input-text-area-phramyicist-Upload-documents {
	display: block;
	/* width: 60vw; */
    height: 67px;
width: 90%;
	margin: 0 auto;
	background-color: #D9D9D9;
    border-radius: 8px;
	font-size: 1em;
	line-height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
    font-family: Poppins;
}

.input-text-area-phramyicist-Upload-documents:hover {
	background-color: cornflowerblue;
}
.input-text-area-phramyicist-Upload-documents:active {
	background-color: mediumaquamarine;
}
.input-text-area-phramyicist-Upload-documents1 {
	border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px; 
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
