.medharvacarecentrescontainer {
  display: grid;
  place-items: center;
}

.medharvacarecentresitem {
  width: 70%;
  height: 100%;
  padding: 5px 2vw 5px 2vw;
  background: white;
  border-radius: 100px;
  border: 2px solid #039147;
  justify-content: center;
  align-items: center;
  display: flex;
}

.medharvacarecentresinput {
  width: 100%;
  height: 100%;
  background: white;
  color: #7e7e7e;
  font-weight: 500;
  font-family: Poppins;
  border: none;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  font-size: 18px;
}

.medharvacarecentresinput::placeholder {
  color: #7e7e7e;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
}

.medharvacarecentresbutton {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.medharvacarecentresimg1 {
  width: 25px;
  height: 25px;
}

.medharvacarecentresbutton2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 2px solid #039147;
  border-radius: 6px;
  padding: 5px 30px;
  white-space: nowrap;
}

.medharvacarecentresbutton2:hover {
  background-color: #039147;
  color: white;
}

.medharvacarecentresitem2 {
  display: flex;
  width: 1124px;
  overflow-x: scroll;
}

.medharvacarecentresitem2::-webkit-scrollbar {
  width: 0px;
}

.medharvacarecentresitem2::-webkit-scrollbar-thumb {
  background-color: #f3f5ea;
  border-radius: 6px;
}

.medharvacarecentresitem2::-webkit-scrollbar-track {
  background-color: #f3f5ea;
}

.medharvacarecentresimg2{
    width: 100%;
    height: 138px;
    border-radius: 6px;
}

.medharvacarecentrescontainer2{
    display: flex;
    justify-content: center;
    align-items: start;
}
.medharvacarecentrescontainer2main{
  display: flex;
  justify-content:center;

}
.medharvacarecentrescontainer3{
    display: grid;
    min-width: 445px;
padding: 15px;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 2px solid #039147;
    border-radius: 6px;
}

.medharvacarecentreslocation{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: start;
}

.medharvacarecentresrating{
  width: 111px;
  height: 24px;
    font-family: Poppins;
    border-radius: 100px;
    padding-left: 10px;
    background-color: #F2F4EA;
    display: flex;
    font-size: 14px;
    font-weight: 500;
}
.medharvacarecentresratingimg{

margin-left: 3%;
}
.medharvacarecentresratingimgdiv{
width: 100px;
  margin-left: 8%;
  }

.medharvacarecentrescontainer4{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medharvacarecentresicons{
  height: 25px;
  width: 25px;
    color: white;
    background-color: #039147;
    border-radius: 100%;
    padding: 5px ;
}

.medharvacarecentrescontainer5{
    display: flex;
}

.medharvacarecentresgriditem4{
    display: flex;
    align-items: start; 
    justify-content: space-around;
}

.medharvacarecentresbutton1{
  width: 202px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    color: #039147;
    border: 1px solid #039147;
    background: #FFFFFF;
    text-align: center;
    padding: 4px 0px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
}

.medharvacarecentresbutton1:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.medharvacarecentresbutton101{
  width: 183px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    border: 1px solid #039147;
    background: #FFFFFF;
    text-align: center;
    padding: 4px 0px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
}

.medharvacarecentresbutton101:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.medharvacarecentresbutton2{
  width: 202px;
  text-align: center;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  border: 1px solid #039147;
  background: #039147;
  padding: 4px 0px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}

.medharvacarecentresbutton2:hover{
  color: #039147;
  border: 1px solid #039147;
  background: #FFFFFF;
}

@media (max-width: 780px) {
  .medharvacarecentrescontainer2main{
    display: flex;
    justify-content:flex-end;
  
  }
 
}
@media (max-width: 1180px) {
 
  .medharvacarecentresitem2 {
    display: flex;
    width:90%;
    overflow-x: scroll;
  }
}
@media (max-width: 800px) {
  .medharvacarecentresitem2 {
    display: none;
   
  }
  .medharvacarecentrescontainer2{
    display: none;
}
.medharvacarecentrescontainer2main{
  display: none;

}
 
}