
@import url('https://db.onlinewebfonts.com/c/a58bc51e0ea93133282ec2e2e2b30f0c?family=Retroica');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');


.premiumcantainer {
    width: 100vw;
    height: auto;
    background-color: #f2f4ea;
}

.premiumcantainer .premiumcantainer1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.premiumcantainer .premiumcard {
    width: 90%;
    height: 418px;
    background-color: #BA90FF;
    
    border-radius: 8px;
}
.premiumcantainer .premiumcolumn1{
    padding: 5px;
}
.premiumcantainer .inline-imageM{
    padding: 0px 0px 18px 0px;
    height: 32px;
    width: 32px;


}
.premiumcantainer .inline-imageT{
   
    height: 35px;
    width: 35px;


}
.premiumcantainer .left-side-image {
    display: flex;
    width: 63px;
    height: 63px;
    /* padding-left: 54px; */
    margin-left: 45px;
}

.premiumcantainer .inline-image {
    width: 25px;
    height: 25px;
    
}

.premiumcantainer .h2medhervapremium {
    padding-left: 54px;
    font-size: 40px;
    font-weight: 500;
    line-height: 38px;
    color: #5700C8;
    font-family: Poppins;
}
.premiumcantainer .h2medhervapremium1 {
    padding-left: 54px;
    font-size: 40px;
    font-weight: 500;
    line-height: 38px;
    color: #5700C8;
    font-family: Poppins;
}

.premiumcantainer .p1medhervapremium {
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    margin: 0px;
    color: #FFFFFF;
    font-family: Poppins;
}


.premiumcantainer .p2medhervapremium-container {
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 7px;
    color: #FFFFFF;
    font-family: Montserrat;
    text-align: center;
    /* padding-right: 15%; */
    margin-left: 5%;
}


.premiumcantainer .knowmorebutton {
    background-color: #5700C8;
    color: #FFFFFF;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    padding: 10px 55px;
    /* margin-bottom: 10px; */
    text-transform: none;
    font-family: Poppins;
}
.premiumcantainer .knowmorebutton:hover{
    color: white;
    border: 1px solid #039147;
    background: #039147;
}
.premiumcantainer .image2 {
    width: 439px;
    height: 439px;
}

.premiumcantainer .corner-images-container {
    padding: 0px 0px 60px 0px;
}

.premiumcantainer .benefit-item-container1 {
    padding: 5px 0px 0px 40px;
    margin-top: -15px;
}
.premiumcantainer .benefit-item-container2 {
    padding: 5px 0px 0px 40px;
    margin-top: -15px;
}
.premiumcantainer .benefit-item-container3 {
    padding: 5px 0px 0px 40px;
    margin-top: -15px;
}

.premiumcantainer .inline-image-container {
    padding: 5px 0px 0px 40px;
}
.premiumcantainer .inline-image-container1 {
    padding-right: 30%;
    margin-top: -20px;
}

.premiumcantainer .button-container {
    padding: 0px 0px 50px 0px;
}

.premiumcantainer .corner-image {
    width: 134px;
    height: 134px;
}
@media (max-width: 1050px) {
 

   
   
    .premiumcantainer .image2 {
    
        width: 310px;
        height: 310px;
        margin: -30px;
    }
   
  
  
  
    .premiumcantainer .button-container {
        padding: 0px 0px 20px 0px;
    }
    .premiumcantainer .knowmorebutton {
        background-color: #5700C8;
        color: #FFFFFF;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }
  
}
@media (max-width: 880px) {
    .premiumcantainer .premiumcard {
        width: 80%;
        height: auto;
        background-color: #BA90FF;
        
        border-radius: 8px;
    }
}
@media (max-width: 600px) {
    .premiumcantainer .premiumcard {
        width: 88%;
        height: auto;
        background-color: #BA90FF;
        
        border-radius: 8px;
    }
}
@media (max-width: 300px) {
    .premiumcantainer .premiumcard {
        width: 95%;
padding: 5px;
        height: auto;
        background-color: #BA90FF;
        
        border-radius: 8px;
    }
}

@media (max-width: 880px) {
    
    .premiumcantainer .knowmorebutton {
        background-color: #5700C8;
        color: #FFFFFF;
        width: 223px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding: 5px 25px;
        text-transform: none;
        font-family: Poppins;
    }
  
    .premiumcantainer .corner-images-container {
        display: none;
    }
    .premiumcantainer .p2medhervapremium-container {
        display: none;
    }
    .premiumcantainer .left-side-image {
        display: flex;
        width: 63px;
        height: 63px;
        margin: 0px;
        padding-left: 0px;
    }
    .premiumcantainer .image2 {
    
        width: 310px;
        height: 310px;
        margin: -15px;
    }
    .premiumcantainer .h2medhervapremium {
        padding-left: 0px;
        font-size: 40px;
        font-weight: 400;
        line-height: 38px;
        color: #5700C8;
        font-family: Retroica;
       
    }
    .premiumcantainer .h2medhervapremium1 {
        padding-left: 0px;
        margin-left: -67px;
        font-size: 40px;
        font-weight: 400;
        line-height: 38px;
        color: #5700C8;
        font-family: Retroica;
        
    }
    .premiumcantainer .benefit-item-container1 {
        padding: 0px;
        margin-top: 0px;
        margin-left: -45px;
        text-align: left;
    }
    .premiumcantainer .benefit-item-container2 {
        padding: 0px;
        margin-top: 0px;
        text-align: left;
    }
    .premiumcantainer .benefit-item-container3 {
        padding: 0px;
        margin-top: 0px;
        margin-left: -75px;
        text-align: left;
    }
    .premiumcantainer .inline-image-container {
        padding: 5px 0px 0px 0px;
        padding-left: 10%;
        margin-top: 10px;
    }
    .premiumcantainer .inline-image-container1 {
        padding-right: 10%;
        padding-left: 25%;
        margin-top: 0px;
    }
    .premiumcantainer .premiumcolumn2{
        padding: 0px;
    }
    .premiumcantainer .inline-imageM{
        padding: 0px 0px 18px 18px;
        height: 32px;
        width: 32px;
    
    
    }
    
}