.userinformationroot{
    display: grid;
    place-items: center;
    padding: 5px;
    width: 100%;
}

.userinformationitem1{
    display: flex;
    align-items: center;
    gap: 30px;
}

.userinformationimg{
    width: 15vw;
    height: 15vw;
    border-radius: 999px;
}

.userinformationbrowse{
    font-size: 24px;
    font-family: Poppins;
    font-weight: 500;
    border: none;
    background: inherit;
    cursor: pointer;
}

.userinformationaddphoto{
    color: #039147;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    border: none;
    background: inherit;
    cursor: pointer;
}

.userinformationitem{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userinformationnext{
    padding: .4rem 4vw;
    background: white;
    border-radius: 100px;
    border: 2px #039147 solid;
    place-items: center;
    display: grid;
    color: #039147;
    text-transform: none;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
}

.userinformationheader{
    font-size: 24px;
    font-family: Poppins;
    font-weight: 500;
}

.userinformationlabel{
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
}

.userinformationlabelitem2{
    width: 25vw;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    border-radius: 6px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userinformationlabelinput{
    width: 100%;
    height: 100%;
    background: white;
    color: black;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 18px;
}

.userinformationtitle{
    display: none;
}

.userinformationnext2{
    display: none;
}

@media screen and (max-width: 800px) {
    .userinformationroot{
        display: grid;
        place-items: center;
        padding: 10px;
        width: 100%;
    }

    .userinformationimg{
        width: 25vw;
        height: 25vw;
        border-radius: 999px;
    }

    .userinformationbrowse{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 500;
        border: none;
        background: inherit;
        cursor: pointer;
    }

    .userinformationaddphoto{
        color: #039147;
        font-size: 12px;
        font-family: Poppins;
        font-weight: 400;
        border: none;
        background: inherit;
        cursor: pointer;
    }

    .userinformationroot2{
        display: grid;
        place-items: end;
        width: 95%;
        margin-bottom: 40px;
    }

    .userinformationlabel{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
    }

    .userinformationitem2{
        display: grid;
        justify-content: center;
    }

    .userinformationnext{
        display: none;
    }

    .userinformationlabelitem2{
        width: 80vw;
        height: 45px;
        padding-left: 10px;
        padding-right: 10px;
        background: white;
        border-radius: 6px;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .userinformationnext2{
        padding: .4rem 4vw;
        background: white;
        border-radius: 100px;
        border: 2px #039147 solid;
        place-items: center;
        display: grid;
        color: #039147;
        text-transform: none;
        font-family: inherit;
        font-size: 16px;
        cursor: pointer;
    }

    .userinformationtitle{
        display: inline;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .userinformationheader{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 500;
    }

    .userinformationlabel{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 400;
    }
}

@media screen and (max-width: 1088px) {
    .userinformationlabel{
        font-size: 15px;
        font-family: Poppins;
        font-weight: 400;
    }
}