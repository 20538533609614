.prescriptionMedicinesroot {
  overflow-x: hidden;
  height: 100%;
}

.prescriptionMedicines {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 500;
}

.prescriptionContainerparent {
  background-color: white;
  border-radius: 8px;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-around;
}

.prescriptionMedicinesitem {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.prescriptionMedicinesimg {
  width: 33vw;
  height: 22vw;
  border-radius: 8px;
}

.prescriptionContainer {
  display: grid;
}

.prescriptionMedicinesRadio {
  -webkit-appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  outline: none;
  border: 3.5px solid #039147;
  cursor: pointer;
  background-color: white;
}

.prescriptionMedicinesRadio:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.prescriptionMedicinesRadio:checked:before {
  background: #039147;
}

.prescriptionMedicinesRadio:checked {
  border-color: #039147;
}

.navprescriptionMedicines {
  display: flex;
  justify-content: center;
}

.mentionSpecificMedicinesInput {
  width: 100%;
  border: 3px solid #039147;
  font-size: 16px;
  padding: 10px;
  font-weight: 500;
}

.mentionSpecificMedicinesInput::placeholder {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .prescriptionMedicines {
    display: grid;
    place-items: center;
    font-size: 30px;
  }

  .prescriptionMedicinesimgitem,
  .prescriptionContainerparent,
  .prescriptionContainer {
    display: grid;
    place-items: center;
    gap: 30px;
  }

  .prescriptionMedicinesitem {
    font-size: 15px;
  }

  .prescriptionMedicinesRadio {
    width: 28px;
    height: 28px;
  }

  .mentionSpecificMedicinesInput {
    font-size: 14px;
  }
  
  .mentionSpecificMedicinesInput::placeholder {
    font-size: 14px;
  }

  .prescriptionMedicinesimg {
    width: 35vw;
    height: 25vw;
  }
}
