@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap");
.slick-dots li.slick-active button:before {
  color: #039147;
}
.Main-HW {
  max-width: 1262px;
  height: 505px;
  margin: auto;
}
.card-border-1 {
  width: 100%;
  height: 100%;
  padding: 10px;

  align-items: center;
  display: inline-flex;
}
.card-border-2 {
  width: 334px;
  height: 430px;
  padding-top: 21px;
  padding-bottom: 21px;
  /* margin: 0px auto; */
  background: white;
  box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
  border-radius: 6px;
  border: 2px #039147 solid;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}
.card-border-3 {
  width: 293px;
  height: 221px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 36px;
  display: inline-flex;
}
.card-image-4 {
  width: 292px;
  height: 205px;
  border-radius: 6px;
  border: 2px #039147 solid;
}
.card-title-5 {
  width: 293px;
  height: 55px;
}
.card-title-5a {
  color: black;
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
  margin-right: 27%;
  font-family: Poppins;
}
.card-title-5b {
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  color: #7e7e7e;
  margin-right: 55%;
  white-space: none;
  font-family: Poppins;
}
.card-label {
  width: 303px;
  height: 29px;
}
.card-label-a {
  width: 152px;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: #f2f4ea;
  border-radius: 100px;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-card-A1 {
  width: 289px;
  height: 36px;
  padding: 24px 0px, 0px, 0px;
  gap: 32px;
  display: flex;
  flex-direction: row;
  justify-content: "space-around";
}
.btn-card-A2 {
  width: 128px;
  height: 29px;
  border-radius: 2px;
}
.btn-card-B1 {
  width: 128px;
  height: 29px;
  border-radius: 2px;
}

@media (max-width: 700px) {
  .Main-HW {
    width: 350px;
    margin: auto;
  }
  .card-border-1 {
    width: 100%;
    height: 100%;
    padding: 8px;
    align-items: center;
    display: inline-flex;
  }
  .card-border-2 {
    width: 267px;
    height: 340px;
    /* width: 334px;
        height: 340px; */
    /* padding-top: 25px; */
    padding-top: 21px;
    padding-bottom: 10px;

    /* margin: 0px auto; */
    background: white;
    box-shadow: 4px 4px 4px rgba(0, 145, 71, 0.28);
    border-radius: 6px;
    border: 2px #039147 solid;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
  }
  .card-border-3 {
    width: 180px;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
    display: inline-flex;
  }
  .card-image-4 {
    width: 245px;
    height: 150px;
    border-radius: 6px;
    border: 2px #039147 solid;
  }
  .card-title-5 {
    width: 270px;
    height: 35px;
    margin-bottom: 5%;
  }
  .card-title-5a {
    color: black;
    font-size: 16px;
    font-weight: 600;
    line-height: 33px;
    margin-right: 27%;
    font-family: Poppins;
  }
  .card-title-5b {
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    color: #7e7e7e;
    margin-right: 45%;
    white-space: none;
    font-family: Poppins;
  }
  .card-label {
    width: 303px;
    height: 29px;
  }

  .card-label-a {
    width: 152px;
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: #f2f4ea;
    border-radius: 100px;
    margin-left: 1%;
    padding: 1%;
    margin-top: -5%;
  }
  .btn-card-A1 {
    width: 250px;
    height: 30px;
    padding: 24px 0px, 0px, 0px;
    margin-right: 1%;
    margin-top: -8%;
  }
  .btn-card-A2 {
    width: 95px;
    height: 25px;
    border-radius: 2px;
  }
  .btn-card-B1 {
    width: 95px;
    height: 25px;
    border-radius: 2px;
  }
}

/* @media (max-width: 599px) {
    .btn-card-A1 {
      width:140px;
      height:30px;
     padding: 24px 0px, 0px, 0px;
    margin-right: 31%;
    margin-bottom: 2%;
     display: flex;
     justify-content: space-between;
     }
     .btn-card-A2 {
      width:15px;
       height:25px;
       border-radius:2px;
     
     }
     .btn-card-B1 {
      width:15px;
       height:25px;
        border-radius:2px;
       
     }
  } */

/* //// */
.Main-D-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 9%;
}
/* .Main-D-2 {
    width: 766px;
     height: 176px;
  }
   .Main-D-3 {
    font-size:40px;
     font-weight:700;
      line-height:48.76px;
      margin-right:14%;
       margin-top:10%;
       white-space: nowrap;
       display: block;
   }                                           button css */

.btn-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 47px;
  margin-left: 11%;
  margin-top: 110px;
}

.btn-grid {
  width: 327px;
  height: 67px;
  padding: 10px;
  background: #039147;
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.btn-text {
  width: 305px;
  height: 36px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: Montserrat;
  word-wrap: break-word;
  text-transform: none;
}

@media (max-width: 1180px) {
  .btn-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 47px;  */

    padding: 0px;
    margin-left: 2%;
    margin-top: 40%;
  }

  .btn-grid {
    width: 239px;
    height: 37px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }

  .btn-text {
    width: 305px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    font-family: Montserrat;
  }
}
@media (max-width: 1000px) {
  .btn-container {
    height: 60%;
    width: 60%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 47px;  */

    padding: 0px;
    margin-left: 18%;
    margin-top: 5%;
  }

  .btn-grid {
    width: 239px;
    height: 37px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }

  .btn-text {
    width: 305px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    font-family: Montserrat;
  }
}
@media (max-width: 650px) {
  .btn-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 47px;  */

    padding: 0px;
    margin-left: 2%;
    margin-top: -20%;
  }

  .btn-grid {
    width: 239px;
    height: 37px;
    padding: 10px;
    background: #039147;
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }

  .btn-text {
    width: 305px;
    height: 26px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    text-transform: none;
    font-family: Montserrat;
  }
}

/* text----- */

.text-a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 13%;
}
.text-B {
  width: 766px;
  text-align: left;
}

.text-C {
  color: black;
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
  font-family: Montserrat;
  margin-left: 15%;
  white-space: nowrap;
  display: block;
  margin-top: 2%;
}
@media (max-width: 920px) {
  .text-a {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .text-B {
    width: 262px;
    height: 40px;
    text-align: left;
    margin-bottom: 8%;
  }

  .text-C {
    color: black;
    font-size: 24px;
    font-weight: 700;
    word-wrap: break-word;
    font-family: Montserrat;
    margin-left: -70%;
    /* font-family: 'Montserrat'; */
    line-height: 19.5px;
    left: 10%;
    white-space: nowrap;
    display: block;
  }
}
@media (max-width: 730px) {
  .text-a {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .text-B {
    width: 262px;
    height: 40px;
    text-align: left;
    margin-bottom: 8%;
  }

  .text-C {
    color: black;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
    font-family: Montserrat;
    margin-left: -6%;
    /* font-family: 'Montserrat'; */
    line-height: 19.5px;
    left: 10%;
    white-space: nowrap;
    display: block;
  }
}
@media (max-width: 331px) {
  .text-a {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .text-B {
    width: 262px;
    height: 40px;
    text-align: left;
    margin-bottom: 3%;
    margin-left: 8%;
  }

  .text-C {
    color: black;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
    font-family: Montserrat;
    margin-left: -6%;
    /* font-family: 'Montserrat'; */
    line-height: 19.5px;
    left: 50%;
    white-space: nowrap;
    display: block;
  }
}
