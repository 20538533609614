@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.medharwa-singup-phramyicist-lap-a {
    display:flex;
    justify-content:center;
}
.medharwa-singup-phramyicist-lap-b {
    width:518px;
    height:1000px;
     background-color:#FFFFFF; 
     /* border: 2px solid #039147; */
}



.label-registraion-phramyicist {
    width: 84%;
    height: 29px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500px;
    color: #000000;
    margin-left: 8%;
   
    margin-bottom: 2%;
    text-align: left;
    /* background-color: red; */
}
.input-text-area-phramyicist {
    /* width: 433px; */
    width: 84%;
    padding-left: 20px;
    height: 67px;
    margin-left: 8%;
   background-color: #D9D9D9;
   border: none;
   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;


}
.input-text-area-phramyicist-logo {
    /* width: 433px; */
    width: 84%;
    height: 67px;
    padding-left: 20px;

   background-color: #D9D9D9;
   border: none;
   margin-left: 8%;

   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
   margin-right: 3%;
}
.input-text-area-phramyicist-about {
    /* width: 433px; */
    width: 84%;
    
 padding: 15px;

    height: 133px;
   background-color: #D9D9D9;
   border: none;
   margin-left: 8%;

   border-radius: 8px;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 500;
}


.singup-aply-btn-phramyicist {
    padding: 10px 30px 10px 30px;
    color: white;
    background-color: #3FC08A;
   margin-top: 10%;
    cursor: pointer;
    font-size: 24px;
    text-align: Center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 36px;
    border: none;
    border-radius: 8px;
}
.singup-aply-btn-phramyicist:hover{
    color: white;
    background-color: #039147;
}
.input-with-logo {
    position: relative;
  }
  
  .input-logo {
    position: absolute;
    top: 50%;
    left: 80%; /* Adjust as needed */
    transform: translateY(-50%);
    height: 38px;
    width: 38px;
   
  }
  .pahrmycist-box-a-btn {
    border:none;
    background:none;
    cursor:pointer;
  }
  .error-message-pharmacy{
    width: 84%;
    color: red;
   text-align: left;
 margin-left: 8%;
 font-size: small;
  /* border: 2px solid red; */
  }

  .input-text-area-phramyicist-Upload-documents-11 {
	display: block;
	/* width: 60vw; */
    height: 67px;
width: 84%;
	margin: 0 auto;
	background-color: #D9D9D9;
    border-radius: 8px;
	font-size: 1em;
	line-height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
    font-family: Poppins;
}

.input-text-area-phramyicist-Upload-documents-11:hover {
	background-color: cornflowerblue;
}
.input-text-area-phramyicist-Upload-documents-11:active {
	background-color: mediumaquamarine;
}
.input-text-area-phramyicist-Upload-documents1-11 {
	border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px; 
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
