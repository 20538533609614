.workprocessatmedharvaroot{
    display: grid;
    place-items: center;
}

.workprocessatmedharvacontainer{
    border: 3px solid #039147;
    background-color: white;
    display: grid;
    place-items: center;
    border-radius: 15px;
    padding: 0px 50px 10px 50px;
}

.workprocessatmedharvaitem{
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 600;
    display: grid;
    place-items: center;
}

.workprocessatmedharvaimg{
    width: 70px;
    height: 70px;
}

.workprocessatmedharvacontainer2{
    display: flex;
    justify-content: space-evenly;
}

.workprocessatmedharvacontainer2inner{
    display: flex;
    align-items: center;
}

.workprocessatmedharvacontainer3{
    display: grid;
    align-items: center;
    text-align: center;
}

.workprocessatmedharvaitem2{
    background-color: #039147;
    padding: 20px;
    border-radius: 100%;
    display: grid;
    place-items: center;
}

.workprocessatmedharvaitem3{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    text-align: center;
}

.workprocessatmedharvaarrow {
    transform: scale(2.5);
    color: #039147;
}  