@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@500&display=swap');
.medharwa-care-card-A{
    position: relative;
     display: flex;
      justify-content: center;
      align-items: center;
       padding: 0 16px;
    
  }
  /* .medharwa-care-card{
    height: auto;
    width: 100%;
    object-fit: cover;
    
      border-radius: 6px;
       overflow: hidden ;
  } */
  .medharwa-care-card-C{
    position: absolute;
    top: 35%;
    left: 10%;
     right: 60%;
     transform: translateY(-50%);
      color: black;
     
  }
  .medharwa-care-card-B {
    max-width: 1352px;
    max-height: 592px;
    position: relative;
  }
  .medharwa-care-card-B::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  
    width: 80%; /* Adjust the width as needed */
    height: 101%;
    background: linear-gradient(to right, rgba(252, 249, 249, 0.87), rgba(255, 255, 255, 0));
  }
  .medharwa-care-card-imge{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
   
  }
  .medharwa-care-car-D{
    font-size: 48px;
     font-weight: 600px;
     font-family: Montserrat;
      word-wrap: break-word;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      margin-left: 25%;
  
      /* font-family: Montserrat; */
  }
  .medharwa-care-car-E{
    font-size: 48px;
     font-weight: 600px;
     font-family: Montserrat;
     word-wrap: break-word;
     white-space: nowrap;
     display: flex;
     justify-content: flex-start;
     margin-left: 25%;
  
     /* font-family: Montserrat; */
  }
  @media screen and (max-width: 688px) {
    .medharwa-care-car-D{
      font-size: 16px;
       font-weight: 500;
        word-wrap: break-word;
        white-space: nowrap;
        font-family: Montserrat;
        display: block;
        /* font-family: Montserrat; */
       
    }
    .medharwa-care-car-E{
      font-size: 16px;
       font-weight: 500;
       font-family: Montserrat;
       word-wrap: break-word;
       white-space: nowrap;
       display: block;
       /* font-family: Montserrat; */
    }
  }
  
  
  
  .medharwa-care-button-container {
    width: 458px;
    height: 74px;
    background: #039147;
    border-radius: 6px;
    position: absolute;
    top: 70%;
    left: 19%;
    right: 60%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .medharwa-care-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #039147;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .medharwa-care-button-text {
    text-align: center;
    color: white;
    font-size: 32px;
    font-family: Poppins;
    font-weight: 500px;
    word-wrap: break-word;
    white-space: nowrap;
    text-transform: none;
    width: 458px;
    height: auto;
  }
  
  /* Media query for screens less than 600px wide */
  @media (max-width: 688px) {
    .medharwa-care-button-container {
      width: 130px;
      height: 25px;
      left: 16%;
      right: 0;
      display: flex;
      justify-content: center;
    }
  
    .medharwa-care-button {
      /* Adjust padding for smaller screens */
      justify-content: center;
      align-items: center;
     
      display: inline-flex;
    }
  
    .medharwa-care-button-text {
      font-size: 12px; /* Adjust font size for smaller screens */
      width: 120px;
    }
  }
  