.profile-card {
    border-radius: 20px;
    max-width: 600px;
    width: 100%;
    height: auto;
    max-height: 600px; /* Set a maximum height */
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  
  /* ... Other styles remain the same ... */
  
  @media (max-width: 768px) {
    .profile-card {
      width: 80%;
    }
  }
  
  
  .profile-card header {
    border-bottom: 1px solid #039147;
    padding-bottom: 15px;
    text-align: center; /* Centered text */
  }
  
  .profile-card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto;
    margin-top: 20px;
    border: 8px solid transparent;
    transition: 0.4s;
  }
  
  .profile-card img:hover {
    border: 8px solid #039147;
  }
  
  .profile-card h1 {
    font-family: 'Raleway', Arial;
    font-size: 22px;
    color: #e57373;
    text-align: center;
    margin-top: 10px; /* Adjusted margin-top */
  }
  
  .profile-card h2 {
    font-family: Arial;
    font-size: 16px;
    text-align: center;
  }
  
  .profile-bio {
   
   
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .profile-bio p {
    color: black;
    font-family: Arial;
    font-size: 15px;
    text-align: center;
    margin: 20px; /* Adjusted margin */
  }
  

 
  
  
 