.aboutcontainer {
    padding: 20px 10px 20px 10px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 145, 71, 0.32);
    border-radius: 8px; 
    border: 2px #039147 solid;
    justify-content: center;
    align-items: flex-start;
}

.imgsize{
    width: 20px;
}

.aboutitem{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.aboutitem2{
    font-size: 12px;
    font-weight: 400;
}

@media (max-width: 1000px) {
    .aboutitem{
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }

    .imgsize{
        width: 28px;
    }
}