/* FormStyles.css */

.input-container {
    margin-bottom: 1rem;
  }
  
  .input-field {
    width: 100%;
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .dropdown-container {
    margin-bottom: 1rem;
    position: relative;
  }
  
  .dropdown-select {
    width: 100%;
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
  }
  
  .submit-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  